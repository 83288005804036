function msgParams() {
  return [{name: 'name', label: '會員姓名'}];
}

function msgContent(paramValues = {}) {
  const {name = '[name]'} = paramValues;

  return `
      <h2>親愛的${name}律師您好：</h2>
      <h3>台北律師公會祝您生日快樂！！ </h3>
      <br/>
      <p>台北律師公會福利委員會 敬上</p>
  `;
}

export {msgParams, msgContent};
