import React from 'react';
import styled from 'styled-components';
import {getNewOutlet, getOutlet, useOutlet} from 'reconnect.js';
import * as Ant from 'antd';
import * as AppActions from '../AppActions';
import {ModalCustomStyle} from '../AppContext/customStyledCss';

getNewOutlet('admin-import-excel-modal', null, {autoDelete: false});

export function showAdminImportExcelModal(data) {
  getOutlet('admin-import-excel-modal').update(data);
}

export default function DownloadModal(props) {
  const [data, setData] = useOutlet('admin-import-excel-modal');
  return (
    <Ant.Modal
      title={null}
      footer={null}
      bodyStyle={{padding: 0}}
      width={480}
      visible={!!data}
      onOk={() => {
        setData(null);
      }}
      onCancel={() => {
        setData(null);
      }}
      destroyOnClose={true}>
      <ModalContent {...data} />
    </Ant.Modal>
  );
}

function ModalContent(props) {
  const {title = '匯入', sheetName = '列表', importRecords} = props;
  const inputRef = React.useRef();

  function importExcel(file) {
    return new Promise((resolve, reject) => {
      try {
        const reader = new FileReader();
        reader.onload = (e) => {
          try {
            const data = new Uint8Array(e.target.result);
            const wb = window.XLSX.read(data, {
              bookType: 'xlsx',
              bookSST: false,
              type: 'array',
            });

            const ws = wb.Sheets[wb.SheetNames[0]];
            const result = window.XLSX.utils.sheet_to_json(ws, {
              raw: false, // force to parse as string format to avoid date mis-parsing
            });

            resolve(result);
          } catch (ex) {
            console.warn('readXslFile', ex);
            reject(ex);
          }
        };
        reader.readAsArrayBuffer(file);
      } catch (ex) {
        console.warn(ex);
        resolve(null);
      }
    });
  }

  return (
    <Wrapper>
      <h2>{title}</h2>
      <input
        type="file"
        ref={inputRef}
        onChange={() => {
          const selectedFile = inputRef.current.files[0];
        }}
      />
      <Ant.Button
        onClick={async () => {
          AppActions.setLoading(true);
          try {
            const selectedFile = inputRef.current.files[0];
            if (selectedFile) {
              const resp = await importExcel(selectedFile);
              if (typeof importRecords === 'function') {
                await importRecords(resp);
              }
            }
          } catch (ex) {
            console.warn(ex);
          }
          AppActions.setLoading(false);
        }}>
        確認
      </Ant.Button>
    </Wrapper>
  );
}

const Wrapper = styled(ModalCustomStyle)`
  padding: 20px;

  & > .store-fields {
    padding: 15px;
    border-radius: 15px;
    background-color: #eee;

    & label {
      margin-right: 8px;
    }

    & > .search-store {
      display: flex;
      align-items: center;
      & > input {
        width: 180px;
        padding: 3px 10px;
      }
    }

    & > .select-store {
      margin: 10px 0;
    }
  }

  & > .field {
    & > label {
      margin-right: 8px;
    }
    margin: 10px 0;
  }

  & > .result {
    display: flex;
    align-items: center;
    margin-top: 30px;
    border-radius: 8px;
    padding: 10px;
    background-color: #eee;
    box-shadow: rgb(0 0 0 / 16%) 0px 3px 6px, rgb(0 0 0 / 23%) 0px 3px 6px;
  }
`;
