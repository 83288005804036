import React from 'react';
import styled from 'styled-components';
import {Button, Modal, Popconfirm, message} from 'antd';
import {getNewOutlet, getOutlet, useOutlet} from 'reconnect.js';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import GenericForm from 'rev.sdk.js/Generic/Form';
import * as AppActions from '../AppActions';
import {ModalCustomStyle} from '../AppContext/customStyledCss';
import {WORK_AROUND_PRODUCTS} from '../Utils/ProductUtil';
import {
  generateExportRecords,
  generateExportRecordsToAoa,
  generateExportWorkSheet,
  generateExcelFile,
  generateAttendMembersData,
} from '../Utils/LiveStreamExportUtil';

getNewOutlet('admin-live-stream-calc-modal', null, {autoDelete: false});

export function showAdminLiveStreamCalcModal(data) {
  getOutlet('admin-live-stream-calc-modal').update(data);
}

function AdminLiveStreamCalcModal(props) {
  const [data, setData] = useOutlet('admin-live-stream-calc-modal');

  return (
    <Modal
      title="試算線上出席"
      footer={null}
      width={500}
      style={{padding: 10}}
      visible={!!data}
      onCancel={() => setData(null)}
      onOk={() => setData(null)}
      destroyOnClose={true}>
      <ModalContent {...data} setData={setData} />
    </Modal>
  );
}

function ModalContent(props) {
  const {
    records,
    signRecords,
    viewtimeRecords,
    product,
    signFormId,
    setData,
  } = props;
  const [downloadUrl, setDownloadUrl] = React.useState(null);

  return (
    <ModalContentWrapper>
      <GenericForm
        instance={null}
        schema={{
          title: '',
          type: 'object',
          required: ['calcViewTimes', 'calcConnectTimes', 'calcSignCounts'],
          properties: {
            calcViewTimes: {type: 'integer', title: '設定觀看時長'},
            calcConnectTimes: {type: 'integer', title: '設定連線時長'},
            calcSignCounts: {type: 'integer', title: '設定點名次數'},
            selectedRecordIds: {
              type: 'array',
              title: '選擇連線紀錄',
              uniqueItems: true,
              minItems: 1,
              items: {
                type: 'string',
                enum: [...records.map((r) => r.id)],
                enumNames: [
                  ...records.map(
                    (r) =>
                      `開始：${r.start_time_display}${' '}結束：${
                        r.end_time_display
                      }`,
                  ),
                ],
              },
            },
            selectedSignRecordIds: {
              type: 'array',
              title: '選擇點名紀錄',
              uniqueItems: true,
              minItems: 1,
              items: {
                type: 'string',
                enum: [...signRecords.map((r) => r.id)],
                enumNames: [
                  ...signRecords.map(
                    (r) =>
                      `開始：${r.start_time_display}${' '}結束：${
                        r.end_time_display
                      }`,
                  ),
                ],
              },
            },
          },
        }}
        uiSchema={{
          calcViewTimes: {
            'ui:help': '分鐘為單位，不得為負值',
          },
          calcConnectTimes: {
            'ui:help': '分鐘為單位，不得為負值',
          },
          calcSignCounts: {
            'ui:help': `最大值 ${signRecords.length}，不得為負值`,
          },
        }}
        renderCustomSubmitButton={(params) => {
          const {values} = params;
          const {
            calcViewTimes,
            calcConnectTimes,
            calcSignCounts,
            selectedRecordIds,
            selectedSignRecordIds,
          } = values;

          const _records = (records || []).filter((r) =>
            (selectedRecordIds || []).includes(r.id),
          );
          const _signRecords = (signRecords || []).filter((r) =>
            (selectedSignRecordIds || []).includes(r.id),
          );

          const nextRecords = generateExportRecords({
            records: _records,
            signRecords: _signRecords,
            viewtimeRecords: viewtimeRecords,
          });

          return (
            <div style={{marginTop: 20}}>
              {downloadUrl && WORK_AROUND_PRODUCTS.includes(product.id) && (
                <div style={{marginBottom: 10}}>
                  提醒您：本課程為 {product.name}，將匯入簽到表「實體」名單。
                </div>
              )}

              <Button
                onClick={async () => {
                  try {
                    AppActions.setLoading(true);
                    setDownloadUrl(null);

                    const nextRecordsToAoa = generateExportRecordsToAoa({
                      product,
                      nextRecords,
                      recordIds: _records.map((r) => r.id),
                      recordTitles: _records.map(
                        (r) =>
                          `連線\n${r.start_time_display}\n${r.end_time_display}`,
                      ),
                      signRecordIds: _signRecords.map((r) => r.id),
                      signRecordTitles: _signRecords.map(
                        (sr) =>
                          `點名\n${sr.start_time_display}\n${sr.end_time_display}`,
                      ),
                      viewtimeRecordTitles: ['觀看開始', '觀看時長'],
                      calcViewTimes,
                      calcConnectTimes,
                      calcSignCounts,
                    });

                    const link = await generateExcelFile(
                      nextRecordsToAoa,
                      generateExportWorkSheet,
                    );

                    if (link) {
                      setDownloadUrl(link);
                    }
                  } catch (err) {
                    console.warn(err);
                    message.error('發生錯誤');
                  } finally {
                    AppActions.setLoading(false);
                  }
                }}>
                產生試算報表
              </Button>

              {downloadUrl && (
                <Button
                  type="link"
                  href={downloadUrl}
                  target="_blank"
                  download={`${
                    product.name
                  }直播紀錄試算_${new Date()
                    .toLocaleDateString('sv')
                    .replace(/-/g, '')}.xlsx`}>
                  下載報表
                </Button>
              )}

              {downloadUrl && (
                <Popconfirm
                  title="確認匯入簽到表？"
                  okText="確認"
                  cancelText="取消"
                  onCancel={() => 0}
                  onConfirm={async () => {
                    AppActions.setLoading(true);

                    try {
                      const instance = await JStorage.fetchOneDocument(
                        'sign_form',
                        {id: signFormId},
                      );

                      let attendOnlineMembers = generateAttendMembersData({
                        nextRecords,
                        records: _records,
                        signRecords: _signRecords,
                        recordIds: _records.map((r) => r.id),
                        signRecordIds: _signRecords.map((r) => r.id),
                        calcViewTimes: calcViewTimes,
                        calcConnectTimes: calcConnectTimes,
                        calcSignCounts: calcSignCounts,
                      });

                      if (WORK_AROUND_PRODUCTS.includes(product.id)) {
                        const nextMembers = [...instance.members];
                        const attendMembers = [...attendOnlineMembers];

                        for (let member of attendMembers) {
                          const index = nextMembers.findIndex(
                            (m) => m.owner === member.owner,
                          );

                          if (index >= 0) {
                            nextMembers[index] = {
                              ...nextMembers[index],
                              is_attend: true,
                            };
                          } else {
                            nextMembers.push({
                              ...member,
                              vegetarians: [],
                              insurances: [],
                              is_points_discount: false,
                              points: 0,
                              pay_time: 0,
                              tba_seat: 0,
                              total: 0,
                              is_auto_created: true,
                            });
                          }
                        }

                        await JStorage.updateDocument(
                          'sign_form',
                          {
                            id: signFormId,
                          },
                          {...instance, members: nextMembers},
                        );

                        message.success('匯入成功');

                        AppActions.setLoading(false);
                        setData(null);

                        AppActions.navigate(
                          `/admin/sign_forms/?action=detail&id=${signFormId}`,
                        );
                      }

                      if (
                        !WORK_AROUND_PRODUCTS.includes(product.id) &&
                        Array.isArray(instance.online_members) &&
                        instance.online_members.length > 0
                      ) {
                        const nextOnlineMembers = [...instance.online_members];

                        for (let member of attendOnlineMembers) {
                          const index = nextOnlineMembers.findIndex(
                            (m) => m.owner === member.owner,
                          );

                          if (index >= 0) {
                            nextOnlineMembers[index] = {
                              ...nextOnlineMembers[index],
                              is_attend: true,
                            };
                          } else {
                            nextOnlineMembers.push({
                              ...member,
                              vegetarians: [],
                              insurances: [],
                              is_points_discount: false,
                              points: 0,
                              pay_time: 0,
                              tba_seat: 0,
                              total: 0,
                              is_auto_created: true,
                            });
                          }
                        }

                        await JStorage.updateDocument(
                          'sign_form',
                          {
                            id: signFormId,
                          },
                          {...instance, online_members: nextOnlineMembers},
                        );

                        message.success('匯入成功');

                        AppActions.setLoading(false);
                        setData(null);

                        AppActions.navigate(
                          `/admin/sign_forms/?action=detail&id=${signFormId}`,
                        );
                      }
                    } catch (err) {
                      console.warn(err);
                      message.error('發生錯誤');
                    }
                  }}>
                  <Button type="primary" style={{marginLeft: 15}}>
                    匯入簽到表
                  </Button>
                </Popconfirm>
              )}
            </div>
          );
        }}
      />
    </ModalContentWrapper>
  );
}

const ModalContentWrapper = styled(ModalCustomStyle)``;

export default AdminLiveStreamCalcModal;
