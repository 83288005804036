import React from 'react';
import styled from 'styled-components';
import {Modal, message} from 'antd';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import {getNewOutlet, getOutlet, useOutlet} from 'reconnect.js';
import GenericForm from 'rev.sdk.js/Generic/Form';
import * as AppActions from '../../AppActions';
import AdminSelectStaffWidget from './AdminSelectStaffWidget';
import {ModalCustomStyle} from '../../AppContext/customStyledCss';

getNewOutlet('AdminFormHistoryEditModal', null, {autoDelete: false});

export function showAdminFormHistoryEditModal(data) {
  getOutlet('AdminFormHistoryEditModal').update(data);
}

export default function AdminFormHistoryEditModal(props) {
  const [data, setData] = useOutlet('AdminFormHistoryEditModal');
  const [user] = useOutlet('user');

  return (
    <Modal
      title={
        data
          ? (() => {
              if (data.type === 'history') {
                return '簽核紀錄';
              } else if (data.type === 'history_execution') {
                return '執行紀錄';
              } else if (data.type === 'history_staff_list') {
                return '簽核紀錄名單';
              } else if (data.type === 'history_execution_staff_list') {
                return '執行紀錄名單';
              }

              return '';
            })()
          : null
      }
      footer={null}
      bodyStyle={{padding: 0}}
      width={640}
      visible={!!data}
      onOk={() => {
        setData(null);
      }}
      onCancel={() => {
        setData(null);
      }}
      destroyOnClose={true}>
      <ModalContent data={data} setData={setData} user={user} />
    </Modal>
  );
}

function ModalContent(props) {
  const {data, setData} = props;
  const {historyRecord, instance, type, index} = data;
  const [staffs] = useOutlet('staffs');

  const rjsfProps = {
    widgets: {
      'admin-select-staff-widget': AdminSelectStaffWidget,
    },
  };

  return (
    <Wrapper>
      {type === 'history_staff_list' && (
        <div className="container">
          <GenericForm
            rjsfProps={rjsfProps}
            instance={instance}
            schema={{
              title: '',
              type: 'object',
              properties: {
                history: {
                  type: 'array',
                  title: '',
                  items: {
                    type: 'object',
                    title: '',
                    properties: {
                      user_id: {
                        type: 'string',
                        title: '工作人員',
                      },
                    },
                  },
                },
              },
            }}
            uiSchema={{
              history: {
                items: {
                  user_id: {
                    'ui:widget': 'admin-select-staff-widget',
                  },
                },
              },
            }}
            onSubmit={async (formData, extValues) => {
              const {history} = formData;
              AppActions.setLoading(true);

              try {
                const nextHistory = history.map((record) => {
                  const staff = staffs.find((s) => s.id === record.user_id);

                  const staffData = staff
                    ? {
                        group: staff.group,
                        role: staff.role,
                        created: record.created
                          ? record.created
                          : new Date().getTime(),
                      }
                    : {};

                  return {...record, ...staffData};
                });

                await JStorage.updateDocument(
                  'form',
                  {id: instance.id},
                  {history: nextHistory, updated: new Date().getTime()},
                );

                message.success('修改成功，請重新整理');
                setData(null);
              } catch (err) {
                console.warn(err);
                message.error('發生錯誤');
              } finally {
                AppActions.setLoading(false);
              }
            }}
          />
        </div>
      )}

      {type === 'history_execution_staff_list' && (
        <div className="container">
          <GenericForm
            rjsfProps={rjsfProps}
            instance={instance}
            schema={{
              title: '',
              type: 'object',
              properties: {
                history_execution: {
                  type: 'array',
                  title: '',
                  items: {
                    type: 'object',
                    title: '',
                    properties: {
                      staff: {
                        type: 'string',
                        title: '工作人員',
                      },
                    },
                  },
                },
              },
            }}
            uiSchema={{
              history_execution: {
                items: {
                  staff: {
                    'ui:widget': 'admin-select-staff-widget',
                  },
                },
              },
            }}
            onSubmit={async (formData, extValues) => {
              const {history_execution} = formData;
              AppActions.setLoading(true);

              try {
                const nextHistoryExecution = history_execution.map((record) => {
                  const staff = staffs.find((s) => s.id === record.staff);

                  const staffData = staff
                    ? {
                        group: staff.group,
                        role: staff.role,
                        created: record.created
                          ? record.created
                          : new Date().getTime(),
                      }
                    : {};

                  return {...record, ...staffData};
                });

                await JStorage.updateDocument(
                  'form',
                  {id: instance.id},
                  {
                    history_execution: nextHistoryExecution,
                    updated: new Date().getTime(),
                  },
                );

                message.success('修改成功，請重新整理');
                setData(null);
              } catch (err) {
                console.warn(err);
                message.error('發生錯誤');
              } finally {
                AppActions.setLoading(false);
              }
            }}
          />
        </div>
      )}

      {type === 'history' && (
        <div className="container">
          <GenericForm
            rjsfProps={rjsfProps}
            instance={historyRecord}
            schema={{
              title: '',
              type: 'object',
              required: ['user_id', 'status', 'note'],
              properties: {
                user_id: {
                  type: 'string',
                  title: '工作人員',
                  readOnly: true,
                },
                status: {
                  type: 'string',
                  title: '狀態',
                  default: 'pending',
                  enum: ['pending', 'success', 'abort'],
                  enumNames: ['待簽核', '成功', '退簽'],
                  pattern: '^(success|abort)$',
                },
                note: {
                  type: 'string',
                  title: '簽核內容',
                },
              },
            }}
            uiSchema={{
              user_id: {
                'ui:widget': 'admin-select-staff-widget',
              },
              status: {
                'ui:widget': 'radio',
              },
              note: {
                'ui:widget': 'textarea',
              },
            }}
            onSubmit={async (formData, extValues) => {
              AppActions.setLoading(true);

              try {
                let nextHistory = [...instance.history];
                nextHistory[index] = {
                  ...formData,
                };

                await JStorage.updateDocument(
                  'form',
                  {id: instance.id},
                  {history: nextHistory, updated: new Date().getTime()},
                );

                message.success('編輯成功，請重新整理');
                setData(null);
              } catch (err) {
                console.warn(err);
                message.error('發生錯誤');
              } finally {
                AppActions.setLoading(false);
              }
            }}
          />
        </div>
      )}

      {type === 'history_execution' && (
        <div className="container">
          <GenericForm
            rjsfProps={rjsfProps}
            instance={historyRecord}
            schema={{
              title: '',
              type: 'object',
              required: ['staff', 'status', 'note'],
              properties: {
                staff: {
                  type: 'string',
                  title: '工作人員',
                  readOnly: true,
                },
                status: {
                  type: 'string',
                  title: '執行狀態',
                  default: 'pending',
                  enum: ['pending', 'processed', 'cancel'],
                  enumNames: ['待處理', '已處理', '已取消'],
                },
                note: {
                  type: 'string',
                  title: '備註',
                },
              },
            }}
            uiSchema={{
              staff: {
                'ui:widget': 'admin-select-staff-widget',
              },
              note: {
                'ui:widget': 'textarea',
              },
            }}
            onSubmit={async (formData, extValues) => {
              AppActions.setLoading(true);

              try {
                let nextHistoryExecution = [...instance.history_execution];
                nextHistoryExecution[index] = {
                  ...formData,
                };

                await JStorage.updateDocument(
                  'form',
                  {id: instance.id},
                  {
                    history_execution: nextHistoryExecution,
                    updated: new Date().getTime(),
                  },
                );

                message.success('編輯成功，請重新整理');
                setData(null);
              } catch (err) {
                console.warn(err);
                message.error('發生錯誤');
              } finally {
                AppActions.setLoading(false);
              }
            }}
          />
        </div>
      )}
    </Wrapper>
  );
}

const Wrapper = styled(ModalCustomStyle)`
  padding: 20px;

  & > .container {
    margin-top: 10px;
    margin-bottom: 20px;
  }
`;
