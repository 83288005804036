import React from 'react';
import styled from 'styled-components';
import {Modal, Button} from 'antd';
import {getNewOutlet, useOutlet} from 'reconnect.js';
import GenericForm from 'rev.sdk.js/Generic/Form';
import * as AppActions from '../AppActions';

getNewOutlet('MoraleQueryModal', null, {autoDelete: false});

export default function MoraleQueryModal(props) {
  const [modalData, setModalData] = useOutlet('MoraleQueryModal');

  return (
    <Modal
      title={null}
      footer={null}
      bodyStyle={{padding: 0}}
      width={640}
      visible={modalData}
      onOk={() => setModalData(null)}
      onCancel={() => setModalData(null)}>
      {modalData && (
        <ModalContent modalData={modalData} setModalData={setModalData} />
      )}
    </Modal>
  );
}

function ModalContent(props) {
  const {modalData, setModalData} = props;
  const {setQuery} = modalData;
  const [moraleCategories] = useOutlet('moraleCategories');

  return (
    <Wrapper>
      <h2>律師倫理案件 精準搜尋</h2>
      <div className="container">
        <GenericForm
          schema={{
            title: '',
            type: 'object',
            properties: {
              case_number: {
                type: ['integer'],
                title: '案號',
              },
              labels: {
                type: 'array',
                title: '分類',
                uniqueItems: true,
                items: {
                  enum: [...moraleCategories.map((category) => category.name)],
                },
              },
              resolution_result_tba: {
                type: 'string',
                title: '本會決定結果',
              },
              resolution_law: {
                type: 'string',
                title: '所涉法規',
              },
              resolution_gist_external: {
                type: 'string',
                title: '決定要旨',
              },
            },
          }}
          uiSchema={{}}
          onSubmit={async (formData, extValue) => {
            AppActions.setLoading(true);
            try {
              const nextQuery = {};

              function addFieldToNextQuery(field, {regex = false} = {}) {
                if (formData[field]) {
                  if (regex) {
                    nextQuery[field] = {$regex: formData[field]};
                  } else {
                    nextQuery[field] = formData[field];
                  }
                }
              }

              addFieldToNextQuery('case_number', {regex: false});
              addFieldToNextQuery('labels', {regex: false});
              addFieldToNextQuery('resolution_result_tba', {regex: true});
              addFieldToNextQuery('resolution_law', {regex: true});
              addFieldToNextQuery('resolution_gist_external', {regex: true});

              console.warn('DBG nextQuery', nextQuery);

              setQuery(nextQuery);
              setModalData(null);
              AppActions.setLoading(false);
            } catch (err) {
              console.warn(err);
              AppActions.setLoading(false);
            }
          }}
        />
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 20px;

  & > .container {
  }
`;
