import * as en from 'rev.sdk.js/Locale/en';

const labels = {
  ...en.labels,
  intro: 'Introduction',
  spec: 'Specification',
  remark: 'Remark',
  share_link: 'Share Link',
  confirm: 'Confirm',
  customer_support: 'Contact',
  customer_support_title: 'About what',
  customer_support_content: 'Detailed description',
  name: 'Name',
  phone: 'Phone',
  email: 'Email',
  updated: 'Updated time',
  next_article: 'Next Article',
  prev_article: 'Previous Article',
  cart: '報名清單',
  cart_add: 'Add To Cart',
  cart_add_error_message_items_limit: '超過購物車購買上限，請分批購買en',
  cart_add_error_message: '發生錯誤，請重新嘗試，或與公會人員聯繫en',
  cart_clear: 'Clear Cart',
  cart_clear_success: '清除購物車成功en',
  cart_clear_failure: '清除購物車失敗，請與客服人員聯繫en',
  cart_empty_content: '購物車尚無商品！歡迎選購加入購物車！en',
  buy_now: '立即購買en',
  checkout_info: '寄送資訊en',
  shopping: '馬上報名en',
  cart_empty_title: '尚未報名en',
  cart_empty: 'Empty en',
  payment_logistics_section_title: '付款及運送方式en',
  receiver_info: '收件人資訊en',
  buyer_info: '購買人資訊en',
  cart_items: '報名清單en',
  order: '報名en',
  order_title: '報名紀錄en',
  product: '課程/活動en',
  sale_start_time: '供應開始時間en',
  sale_end_time: '供應結束時間en',
  back_products: '返回全部課程/活動en',
  return: '取消報名en',
  check_return_status: '查看取消報名狀態en',
  apply_return: '申請取消報名en',
  return_form: '取消報名表單en',
  return_reason:
    '取消報名理由及原始刷卡卡號前6碼 + 後4碼 (課程/活動當天不可取消)en',
  return_status: '取消報名狀態en',
  edit_return_info_forbidden: '未開放修改取消報名資訊en',
  edit_return_info: '修改取消報名資訊en',
  return_response_title: '工作人員回覆en',
  order_id: '繳費編號en',
  order_created: '繳費建立日期en',
  order_content: '活動內容en',
  order_info: '繳費資訊en',
  order_status: '繳費狀態en',
  order_note: '繳費備註en',
  order_total: '繳費總額en',
  duplicate_product: '您已報名本課程/活動en',
  fee: '加價en',
  about: 'About',
  member: 'Member',
  law: 'Law Service',
  public: 'Public',
};

export {labels};
