import React from 'react';
import styled from 'styled-components';
import {Select, Input} from 'antd';
import {zip3} from 'rev.sdk.js/Constants/taiwan';

const _zip3 = zip3.map((zip) => {
  if (zip.city === '臺北市') {
    return {
      ...zip,
      city: '台北市',
    };
  } else if (zip.city === '臺中市') {
    return {
      ...zip,
      city: '台中市',
    };
  } else if (zip.city === '臺南市') {
    return {
      ...zip,
      city: '台南市',
    };
  } else if (zip.city === '臺東縣') {
    return {
      ...zip,
      city: '台東縣',
    };
  }
  return zip;
});

function getCityOptions() {
  const cityOptions = [];

  for (let i = 0; i < _zip3.length; i++) {
    const hasOption = cityOptions.findIndex((o) => o === _zip3[i].city) > -1;

    if (!hasOption) {
      cityOptions.push(_zip3[i].city);
    }
  }

  return cityOptions;
}

function getDistrictOptions(selectedCity) {
  const districtOptions = [];

  for (let i = 0; i < _zip3.length; i++) {
    const hasOption =
      districtOptions.findIndex((o) => o === _zip3[i].district) > -1;

    if (!hasOption) {
      if (!selectedCity) {
        districtOptions.push(_zip3[i].district);
      } else {
        const isIncludedCityDistrict = _zip3[i].city === selectedCity;
        if (isIncludedCityDistrict) {
          districtOptions.push(_zip3[i].district);
        }
      }
    }
  }

  return districtOptions;
}

function getZipCode(selectedCity, selectedDistrict) {
  const hasZipCodIndex = _zip3.findIndex(
    (zip) => zip.city === selectedCity && zip.district === selectedDistrict,
  );
  return hasZipCodIndex > -1 ? _zip3[hasZipCodIndex].zip : '';
}

function AdminAddressCityWidget(props) {
  const [selectedCity, setSelectedCity] = React.useState(
    props.value?.split('-')[0] || undefined,
  );
  const [selectedDistrict, setSelectedDistrict] = React.useState(
    props.value?.split('-')[1] || undefined,
  );

  React.useEffect(() => {
    if (props.value) {
      setSelectedCity(props.value.split('-')[0]);
      setSelectedDistrict(props.value.split('-')[1]);
    }
  }, [props.value]);

  return (
    <CityWrapper>
      <Select
        allowClear
        className={`item ${props.id}-selectedCity`}
        placeholder="縣市"
        value={selectedCity}
        onChange={(value) => {
          setSelectedCity(value);
          setSelectedDistrict(undefined);

          props.onChange(value);
          const codeElement = document.getElementById(
            `${props.id.slice(0, -5)}_zip_code`,
          );

          if (codeElement) {
            codeElement.value = '';
          }
        }}>
        {getCityOptions().map((o, idx) => (
          <Select.Option key={`${o}${idx}`} value={o}>
            {o}
          </Select.Option>
        ))}
      </Select>
      <Select
        allowClear
        className={`item ${props.id}-selectedDistrict`}
        placeholder="鄉鎮市區"
        value={selectedDistrict}
        onChange={(value) => {
          setSelectedDistrict(value);

          props.onChange(`${selectedCity}-${value}`);

          const elemId = `${props.id.slice(0, -5)}_zip_code`;
          if (window._revAddressWidgetFix[elemId]) {
            setTimeout(() => {
              window._revAddressWidgetFix[elemId]?.onChange(
                getZipCode(selectedCity, value),
              );
            }, 300);
          }
        }}>
        {getDistrictOptions(selectedCity).map((o, idx) => (
          <Select.Option key={`${o}${idx}`} value={o}>
            {o}
          </Select.Option>
        ))}
      </Select>
    </CityWrapper>
  );
}

function AdminAddressZipCodeWidget(props) {
  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      if (!window._revAddressWidgetFix) {
        window._revAddressWidgetFix = {};
      }
      window._revAddressWidgetFix[props.id] = {
        onChange: props.onChange,
      };
    }
  });

  return (
    <Wrapper>
      <Input
        id={props.id}
        value={props.value}
        onChange={(e) => props.onChange(e.target.value)}
        onFocus={() => {
          const selectedCityElement = document
            .querySelector(`.${props.id.slice(0, -9)}_city-selectedCity`)
            .querySelector('.ant-select-selector')
            .querySelector('.ant-select-selection-item');

          const selectedDistrictElement = document
            .querySelector(`.${props.id.slice(0, -9)}_city-selectedDistrict`)
            .querySelector('.ant-select-selector')
            .querySelector('.ant-select-selection-item');

          if (selectedCityElement && selectedDistrictElement) {
            if (props.value !== selectedDistrictElement.title) {
              props.onChange(
                getZipCode(
                  selectedCityElement.title,
                  selectedDistrictElement.title,
                ),
              );
            }
          }
        }}
      />
    </Wrapper>
  );
}

const CityWrapper = styled.div`
  display: flex;

  & > .item {
    margin-right: 10px;

    &:last-child {
      margin-right: 0px;
    }
  }
`;

const Wrapper = styled.div``;

export {AdminAddressCityWidget, AdminAddressZipCodeWidget};
