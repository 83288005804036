import React from 'react';
import styled from 'styled-components';
import {Modal, message} from 'antd';
import {getNewOutlet, getOutlet, useOutlet} from 'reconnect.js';
import GenericForm from 'rev.sdk.js/Generic/Form';
import * as AppActions from '../AppActions';
import * as AppActionsEx from '../AppActions/custom';
import AdminSelectProductWidget from '../Generators/AdminResource/AdminSelectProductWidget';
import AdminSelectUserCustomWidget from '../Generators/AdminResource/AdminSelectUserCustomWidget';
import {ModalCustomStyle} from '../AppContext/customStyledCss';

getNewOutlet('admin-mux-permission-apply-modal', null, {autoDelete: false});

export function showAdminMuxPermissionApplyModal(data) {
  getOutlet('admin-mux-permission-apply-modal').update(data);
}

function AdminMuxPermissionApplyModal(props) {
  const [data, setData] = useOutlet('admin-mux-permission-apply-modal');

  return (
    <Modal
      title="授權直播觀看權限"
      footer={null}
      width={500}
      style={{padding: 10}}
      visible={!!data}
      onOk={() => setData(null)}
      onCancel={() => setData(null)}>
      <ModalContent {...data} setData={setData} />
    </Modal>
  );
}

function ModalContent(props) {
  const {product_id, setData} = props;

  const rjsfProps = {
    widgets: {
      'admin-select-product-widget': AdminSelectProductWidget,
      'admin-select-user-custom-widget': AdminSelectUserCustomWidget,
    },
  };

  return (
    <ModalContentWrapper>
      <GenericForm
        rjsfProps={rjsfProps}
        instance={{product_id: product_id}}
        schema={{
          title: '',
          type: 'object',
          required: ['product_id', 'user_id'],
          properties: {
            product_id: {
              type: 'string',
              title: '課程/活動',
              readOnly: true,
            },
            user_id: {
              type: 'string',
              title: '授權會員',
            },
          },
        }}
        uiSchema={{
          product_id: {'ui:widget': 'admin-select-product-widget'},
          user_id: {'ui:widget': 'admin-select-user-custom-widget'},
        }}
        onSubmit={async (formData) => {
          try {
            AppActions.setLoading(true);

            await AppActionsEx.adminMuxPermissionApply({
              product_id: formData.product_id,
              user_id: formData.user_id,
            });

            message.success('授權成功，請聯絡會員，確認重新整理後能否觀看。');

            setData(null);
          } catch (err) {
            console.warn(err);
            message.error('發生錯誤');
          } finally {
            AppActions.setLoading(false);
          }
        }}
      />
    </ModalContentWrapper>
  );
}

const ModalContentWrapper = styled(ModalCustomStyle)``;

export default AdminMuxPermissionApplyModal;
