import {getOutlet} from 'reconnect.js';
import {getStaffValue} from './StaffUtil';

const ACCESS_LIST = [
  //stg
  //prod
  '63aea7e98ae07b4d5d89cc6f', //Admin
  '63eb1ef4c6da4766654d90bf', //test_username
  '63eb2058c6da4766654d90c0', //test_username2
  '63eb20c5c6da4766654d90c1', //test_username3
  '63eb219fc6da4766654d90c2', //test_username4
  '63eb21fec6da4766654d90c3', //test_username5
  '63eb2265c6da4766654d90c4', //test_username6
  '63eb22a8c6da4766654d90c5', //test_username7
];

const UserOutlet = getOutlet('user');

function permissionRequired(key) {
  const user = UserOutlet.getValue();

  const allowedAccessUser = ACCESS_LIST.some((id) => id === user.username);

  if (allowedAccessUser) {
    return true;
  }

  const defaultValue = user[key] || false;
  const permission = getStaffValue(user.sub, key, defaultValue);

  return permission;
}

export default permissionRequired;

function appendSlashSuffix(pathname) {
  if (pathname[pathname.length - 1] !== '/') {
    return pathname + '/';
  }

  return pathname;
}

export {appendSlashSuffix};
