const meetingRooms = [
  'r1',
  'r2',
  'r3',
  'r4',
  'r5',
  'r6',
  'r3-r4',
  'r4-r5',
  'r3-r4-r5',
];

function getMeetingRoomLabel(value, note) {
  switch (value) {
    case 'r1':
      return '第1會議室';
    case 'r2':
      return '第2會議室';
    case 'r3':
      return '第3會議室';
    case 'r4':
      return '第4會議室';
    case 'r5':
      return '第5會議室';
    case 'r6':
      return '第6會議室';
    case 'r3-r4':
      return '第3、4會議室';
    case 'r4-r5':
      return '第4、5會議室';
    case 'r3-r4-r5':
      return '第3、4、5會議室';
    case 'other':
      return note || '';
    default:
      return '';
  }
}

function getMeetingTypeLabel(value) {
  switch (value) {
    case 'INNER':
      return '內部';
    case 'OUTER':
      return '外部';
    case 'GSM':
      return '會員大會';
    case 'BM':
      return '理事會';
    case 'SV':
      return '監事會';
    case 'MD':
      return '常務理事會議';
    case 'BOSM':
      return '理監事聯席會議';
    default:
      return value;
  }
}

function getMeetingEntruseInLabel(value) {
  return value === 'Y' ? '是' : '否';
}

function getDegreeLabel(value) {
  switch (value) {
    case 'B':
      return '大學';
    case 'M':
      return '研究所';
    case 'D':
      return '博士班';
    default:
      return '';
  }
}

function getCommitteePositionLabel(value) {
  switch (value) {
    case 'B01':
      return '主任委員';
    case 'B02':
      return '委員';
    case 'B03':
      return '副主任委員';
    case 'Z01':
      return '顧問';
    case 'Z02':
      return '會員';
    default:
      return '';
  }
}

function getFormTypeLabel(value) {
  switch (value) {
    case 'receive':
      return '收文';
    case 'post':
      return '發文';
    default:
      return '';
  }
}

function getFormStageLabel(value) {
  switch (value) {
    case 'edit':
      return '編輯';
    case 'review_1':
      return '會簽1輪';
    case 'review_2':
      return '會簽2輪';
    case 'review_3':
      return '會簽3輪';
    case 'review_4':
      return '會簽4輪';
    case 'execution':
      return '執行';
    case 'execution_direct':
      return '逕付執行';
    case 'success':
      return '完成';
    case 'abort':
      return '退簽';
    default:
      return value;
  }
}

function getFormStageLabelColor(value) {
  switch (value) {
    case 'edit':
      return 'default';
    case 'review_1':
      return 'blue';
    case 'review_2':
      return 'blue';
    case 'review_3':
      return 'blue';
    case 'review_4':
      return 'blue';
    case 'execution':
      return 'purple';
    case 'execution_direct':
      return 'purple';
    case 'success':
      return 'green';
    case 'abort':
      return 'volcano';
    default:
      return 'default';
  }
}

function getCompanyReviewStatusLabel(value) {
  switch (value) {
    case 'pending':
      return '待處理';
    case 'processing':
      return '處理中';
    case 'success':
      return '審核通過';
    case 'cancel':
      return '審核退件';
    case 'changed':
      return '已變更';
    default:
      return value;
  }
}

function getCompanyReviewStatusLabelColor(value) {
  switch (value) {
    case 'pending':
      return 'cyan';
    case 'processing':
      return 'blue';
    case 'success':
      return 'green';
    case 'cancel':
      return 'volcano';
    case 'changed':
      return 'purple';
    default:
      return 'default';
  }
}

function getAnnualFeeTemplateNameLabel(value) {
  switch (value) {
    case 'on-member-annual':
      return '開徵';
    case 'on-member-december':
      return '催繳';
    case 'on-member-march':
      return '已取得執行名義之催繳';
    default:
      return 'default';
  }
}

export {
  meetingRooms,
  getMeetingRoomLabel,
  getMeetingTypeLabel,
  getMeetingEntruseInLabel,
  getDegreeLabel,
  getCommitteePositionLabel,
  getFormTypeLabel,
  getFormStageLabel,
  getFormStageLabelColor,
  getCompanyReviewStatusLabel,
  getCompanyReviewStatusLabelColor,
  getAnnualFeeTemplateNameLabel,
};
