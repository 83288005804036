import React from 'react';
import styled from 'styled-components';
import {Button} from 'antd';
import {useOutlet} from 'reconnect.js';
import * as AppActions from '../../AppActions';
import {showAdminSearchProductModal} from '../../Components/AdminSearchProductModal/index.js';

export default function AdminSelectProductWidget(props) {
  const [products] = useOutlet('products');
  const [selectedProduct, setSelectedProduct] = React.useState(null);

  //rjsf write "readOnly", but props get "readonly".
  const readonly = props.readonly === true || props.readOnly === true;

  React.useEffect(() => {
    if (props.value) {
      setSelectedProduct(
        products.find((product) => product.id === props.value),
      );
    }
  }, [props.value, products]);

  return (
    <Wrapper>
      <div className="info">
        {!props.value ? (
          `尚未設定${AppActions.L('product')}`
        ) : !selectedProduct ? (
          props.value
        ) : (
          <div>
            <Button
              type="link"
              target="_blank"
              title="點選開啟新分頁編輯"
              style={{
                maxWidth: 400,
                width: '100%',
                whiteSpace: 'pre-wrap',
                textAlign: 'left',
                paddingLeft: 0,
                fontSize: '0.9rem',
                fontWeight: '400',
                lineHeight: '1.5rem',
              }}
              href={`/admin/products/?action=detail&id=${selectedProduct.id}`}>
              {selectedProduct.name}
            </Button>
            <div style={{fontSize: '0.85rem'}}>
              場次時間：
              {selectedProduct.session
                ? `${selectedProduct.session.date || '日期未設定'} ${
                    selectedProduct.session.start_time || '開始時間未設定'
                  } - ${selectedProduct.session.end_time || '結束時間未設定'}`
                : ''}
            </div>
            <div style={{fontSize: '0.85rem'}}>
              費用：${selectedProduct.price}
            </div>
          </div>
        )}
      </div>
      {!readonly && (
        <div className="actions">
          <Button
            onClick={() => {
              props.onChange('');
              setSelectedProduct(null);
            }}
            style={{margin: 5}}>
            清除
          </Button>
          <Button
            type="primary"
            style={{margin: 5}}
            onClick={() =>
              showAdminSearchProductModal({
                onChange: (id) => {
                  props.onChange(id);
                },
              })
            }>
            搜尋
          </Button>
        </div>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 14px;
  border: 1px solid var(--adminBorderColor);
  border-radius: 4px;
  padding: 15px;

  & > .info {
    margin-right: 15px;
  }

  & > .actions {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: end;
  }
`;
