import {getNewOutlet} from 'reconnect.js';
import {buildCatDisplayMap} from 'rev.sdk.js/Utils/buildCatDisplayMap';

const defaultCats = [
  {
    name: 'all',
    display: '所有分類',
    items: [],
  },
];

defaultCats.meta_data = {
  img_url: '',
};

getNewOutlet(
  'sortOptions',
  [
    {name: '-session.date', display: '場次日期(由新到舊)'},
    {name: 'session.date', display: '場次日期(由舊到新)'},
    {name: '-created', display: '上架時間(由新到舊)'},
    {name: 'created', display: '上架時間(由舊到新)'},
    {name: 'price', display: '價格(由低到高)'},
    {name: '-price', display: '價格(由高到低)'},
  ],
  {autoDelete: false},
);

getNewOutlet('extra', {}, {autoDelete: false});

getNewOutlet('expertiseCategories', defaultCats, {autoDelete: false});
getNewOutlet('expertiseCategoryDisplayMap', buildCatDisplayMap(defaultCats), {
  autoDelete: false,
});

getNewOutlet('associationCategories', defaultCats, {autoDelete: false});
getNewOutlet('associationCategoryDisplayMap', buildCatDisplayMap(defaultCats), {
  autoDelete: false,
});

getNewOutlet('groupCategories', defaultCats, {autoDelete: false});
getNewOutlet('groupCategoryDisplayMap', buildCatDisplayMap(defaultCats), {
  autoDelete: false,
});

getNewOutlet('areaCategories', defaultCats, {autoDelete: false});
getNewOutlet('areaCategoryDisplayMap', buildCatDisplayMap(defaultCats), {
  autoDelete: false,
});

getNewOutlet('moraleCategories', defaultCats, {autoDelete: false});
getNewOutlet('moraleCategoryDisplayMap', buildCatDisplayMap(defaultCats), {
  autoDelete: false,
});

getNewOutlet('schoolCategories', defaultCats, {autoDelete: false});
getNewOutlet('schoolCategoryDisplayMap', buildCatDisplayMap(defaultCats), {
  autoDelete: false,
});

getNewOutlet('users', [], {autoDelete: false});
getNewOutlet('staffs', [], {autoDelete: false});
getNewOutlet('companies', [], {autoDelete: false});
getNewOutlet('committeeSummaries', [], {autoDelete: false});
getNewOutlet('receives', [], {autoDelete: false});
getNewOutlet('morales', [], {autoDelete: false});

/* $$__CODEGEN_EXTRA_APPOUTLET_HEAD__$$ */

getNewOutlet('extra', {}, {autoDelete: false});
/* $$__CODEGEN_EXTRA_APPOUTLET_TAIL__$$ */
