function msgParams() {
  return [
    {name: 'name', label: '會員姓名'},
    {name: 'date', label: '日期'},
  ];
}

function msgContent(paramValues = {}) {
  const {name = '[name]', date = '[date]'} = paramValues;

  return `
      <h2>${name}，準會員到期日：${date}</h2>
  `;
}

export {msgParams, msgContent};
