import React from 'react';
import styled from 'styled-components';
import {Modal, message} from 'antd';
import {getNewOutlet, getOutlet, useOutlet} from 'reconnect.js';
import GenericForm from 'rev.sdk.js/Generic/Form';
import * as AppActions from '../AppActions';
import * as AppActionsEx from '../AppActions/custom.js';
import AdminSelectUserCustomWidget from '../Generators/AdminResource/AdminSelectUserCustomWidget';
import {ModalCustomStyle} from '../AppContext/customStyledCss.js';

getNewOutlet('admin-withdraw-modal', null, {autoDelete: false});

export function showAdminWithdrawModal(data) {
  getOutlet('admin-withdraw-modal').update(data);
}

function AdminWithdrawModal(props) {
  const [data, setData] = useOutlet('admin-withdraw-modal');

  return (
    <Modal
      title="退會申請"
      footer={null}
      width={500}
      style={{padding: 10}}
      visible={!!data}
      onOk={() => {
        setData(null);
      }}
      onCancel={() => {
        setData(null);
      }}
      destroyOnClose={true}>
      <ModalContent {...data} setData={setData} />
    </Modal>
  );
}

function ModalContent(props) {
  const {record, clientCallbacks, setData} = props;

  const rjsfProps = {
    widgets: {
      'admin-select-user-custom-widget': AdminSelectUserCustomWidget,
    },
  };

  return (
    <Wrapper>
      <GenericForm
        rjsfProps={rjsfProps}
        instance={record}
        schema={{
          title: '',
          type: 'object',
          required: ['user_id', 'withdraw_date', 'leave_reason'],
          properties: {
            user_id: {
              type: 'string',
              title: '申請會員',
              readOnly: true,
            },
            withdraw_date: {
              type: 'string',
              title: '欲退會日期',
              format: 'date',
            },
            leave_reason: {
              type: 'string',
              title: '欲退會原因',
              default: 'A',
              enum: ['none', 'A', 'B', 'C', 'D', 'E'],
              enumNames: [
                '無',
                '自請退會',
                '決議退會',
                '停止執行職務',
                '病故',
                '許可證到期退會',
              ],
            },
            note: {
              type: 'string',
              title: '備註',
            },
          },
        }}
        uiSchema={{
          user_id: {
            'ui:widget': 'admin-select-user-custom-widget',
          },
        }}
        onSubmit={async (formData, extValue) => {
          AppActions.setLoading(true);

          try {
            AppActionsEx.createWithdrawRecord({
              user_id: formData.user_id,
              withdraw_date: formData.withdraw_date,
              leave_reason: formData.leave_reason,
              note: formData.note,
            });
            message.success('退會紀錄創建成功，請至「會員退會紀錄」查看。');

            setData(null);
            AppActions.setLoading(false);
            await clientCallbacks.refresh();
          } catch (err) {
            console.warn(err);
            message.error('退會紀錄創建失敗。');
            AppActions.setLoading(false);
          }
        }}
      />
    </Wrapper>
  );
}

const Wrapper = styled(ModalCustomStyle)``;

export default AdminWithdrawModal;
