import {useOutlet} from 'reconnect.js';

function useGlobalOutlets() {
  useOutlet('config');
  useOutlet('locale');
  useOutlet('categories');

  //tba custom widget
  useOutlet('companies');
  useOutlet('users');
}

export default useGlobalOutlets;
