import React from 'react';
import styled from 'styled-components';
import {Modal, message} from 'antd';
import {getNewOutlet, useOutlet} from 'reconnect.js';
import GenericForm from 'rev.sdk.js/Generic/Form';
import AdminSelectProductWidget from '../../Generators/AdminResource/AdminSelectProductWidget';
import * as AppActions from '../../AppActions';
import * as AppActionsEx from '../../AppActions/custom';
import {ModalCustomStyle} from '../../AppContext/customStyledCss';

getNewOutlet('AdminCreateProductSignFormModal', null, {autoDelete: true});

export default function AdminCreateProductSign(props) {
  const [modalData, setModalData] = useOutlet(
    'AdminCreateProductSignFormModal',
  );
  const [user] = useOutlet('user');

  return (
    <Modal
      title={null}
      footer={null}
      bodyStyle={{padding: 0}}
      width={640}
      visible={modalData}
      onOk={() => {
        setModalData(null);
      }}
      onCancel={() => {
        setModalData(null);
      }}
      destroyOnClose={true}>
      {user && modalData && (
        <ModalContent
          modalData={modalData}
          setModalData={setModalData}
          user={user}
        />
      )}
    </Modal>
  );
}

function ModalContent(props) {
  const {modalData, setModalData} = props;
  const {clientCallbacks} = modalData;
  const rjsfProps = {
    widgets: {
      'admin-select-product-widget': AdminSelectProductWidget,
    },
  };

  return (
    <Wrapper>
      <h2 className="title">活動/課程簽到表建立</h2>
      <GenericForm
        schema={{
          title: '',
          type: 'object',
          required: ['product'],
          properties: {
            product: {
              type: 'string',
              title: '',
            },
          },
        }}
        uiSchema={{
          product: {
            'ui:widget': 'admin-select-product-widget',
            'ui:options': {label: false},
          },
        }}
        rjsfProps={rjsfProps}
        onSubmit={async (formData, extValue) => {
          try {
            await AppActionsEx.createProductSignForm({
              product_id: formData.product,
            });

            message.success('創建成功');
            clientCallbacks.refresh();
            setModalData(null);
          } catch (err) {
            console.warn(err);
            message.error('創建失敗');
            AppActions.setLoading(true);
          }
        }}
      />
    </Wrapper>
  );
}

const Wrapper = styled(ModalCustomStyle)`
  padding: 20px;

  & > .title {
    margin-bottom: 10px;
  }
`;
