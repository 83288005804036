import React from 'react';
import styled from 'styled-components';
import {Button} from 'antd';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import * as Cart from 'rev.sdk.js/Actions/Cart';
import {showAdminSearchCrossFeeOrderModal} from '../../Components/AdminSearchCrossFeeOrderModal';

export default function AdminSelectCrossFeeOrderWidget(props) {
  const [selectedOrder, setSelectedOrder] = React.useState(null);
  const [owner, setOwner] = React.useState(null);

  //rjsf write "readOnly", but props get "readonly".
  const readonly = props.readonly === true || props.readOnly === true;

  const fetchOrderByOrderNumber = React.useCallback(async (order_number) => {
    try {
      const order = await JStorage.fetchOneDocument('order', {
        order_number,
      });

      setSelectedOrder(order);
      setOwner(order.owner);
    } catch (err) {
      console.warn(err);
    }
  }, []);

  React.useEffect(() => {
    if (props.value) {
      fetchOrderByOrderNumber(props.value);
    }
  }, [props.value, fetchOrderByOrderNumber]);

  return (
    <Wrapper>
      <div className="info">
        {!props.value ? (
          `尚未設定訂單`
        ) : !selectedOrder ? (
          props.value
        ) : (
          <div>
            <Button
              type="link"
              target="_blank"
              title="點選開啟新分頁編輯"
              className="link-button"
              href={`/admin/order?action=detail&id=${selectedOrder.id}&backPath=/admin/orders/export`}>
              {selectedOrder.order_number}
            </Button>
            <div>
              {selectedOrder.items[0].product.name}{' '}
              {Cart.PAYMENT_STATUS_DISPLAY[selectedOrder.payment_status]?.label}
            </div>
            <div>
              {' '}
              {selectedOrder.items[0].config.variants[0].choice} $
              {selectedOrder.total}
            </div>
            <div>
              執業開始日期：
              {selectedOrder.items[0].config.extra_data.start_date}
            </div>
            <div>
              執業結束日期：
              {selectedOrder.items[0].config.extra_data.end_date}
            </div>
          </div>
        )}
      </div>
      {!readonly && (
        <div>
          <Button
            onClick={() => {
              props.onChange('');
              setSelectedOrder(null);
            }}
            style={{margin: 5}}>
            清除
          </Button>

          <Button
            type="primary"
            style={{margin: 5}}
            onClick={() =>
              showAdminSearchCrossFeeOrderModal({
                owner,
                onChange: (order_number) => {
                  props.onChange(order_number);
                },
              })
            }>
            搜尋
          </Button>
        </div>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 14px;
  border: 1px solid var(--adminBorderColor);
  border-radius: 4px;
  padding: 15px;

  & > .info {
    margin-right: 15px;
  }

  & > .actions {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: end;
  }

  .link-button {
    max-width: 400px;
    width: 100%;
    white-space: pre-wrap;
    text-align: left;
    padding-left: 0px;
    font-size: 0.9rem;
    font-weight: 400px;
    line-height: 1.5rem;
  }
`;
