import React from 'react';
import styled from 'styled-components';
import {Button, Input, Modal, Tabs, Table, message} from 'antd';
import {getNewOutlet, getOutlet, useOutlet} from 'reconnect.js';
import * as Jstorage from 'rev.sdk.js/Actions/JStorage';
import * as AppActions from '../../AppActions';
import {ModalCustomStyle} from '../../AppContext/customStyledCss';

getNewOutlet('admin-search-staff-modal', null, {autoDelete: false});

export function showAdminSearchStaffModal(data) {
  getOutlet('admin-search-staff-modal').update(data);
}

const initValues = {
  keyword: '',
  selectedTab: 'all',
  selectedRows: [],
  records: null,
  pagination: {total: 0, pageSize: 4},
};

const fetchRecords = async ({
  keyword,
  query,
  columns,
  pagination = {offset: 0, limit: 4},
}) => {
  try {
    AppActions.setLoading(true);

    const {results: staffs, total} = await Jstorage.fetchDocuments(
      'admin_user',
      {
        $or: columns.map((column) => ({
          [column.key]: {$regex: keyword},
        })),
        ...query,
      },
      null,
      pagination,
    );

    return {total, results: staffs};
  } catch (err) {
    message.error('發生錯誤。');
  } finally {
    AppActions.setLoading(false);
  }
};

export default function AdminSearchStaffModal(props) {
  const [data, setData] = useOutlet('admin-search-staff-modal');
  const [keyword, setKeyword] = React.useState(initValues.keyword);
  const [selectedTab, setSelectedTab] = React.useState(initValues.selectedTab);
  const [records, setRecords] = React.useState(initValues.records);
  const [pagination, setPagination] = React.useState(initValues.pagination);
  const [sRecords, setSRecords] = React.useState(initValues.records);
  const [sPagination, setSPagination] = React.useState(initValues.pagination);

  const columns = [
    {
      key: 'name',
      title: '姓名',
      dataIndex: 'name',
      fixed: 'left',
    },
    {
      key: 'group',
      title: '單位',
      dataIndex: 'group',
    },
    {
      key: 'role',
      title: '職稱',
      dataIndex: 'role',
    },
    {
      key: 'status',
      title: '狀態',
      dataIndex: 'status',
    },
    {
      key: 'type',
      title: '類別',
      dataIndex: 'type',
    },
    {
      key: '',
      title: '',
      dataIndex: '',
      render: (_, item) => {
        return (
          <Button
            onClick={() => {
              data.onChange(item.id);
              onClose();
            }}>
            選擇
          </Button>
        );
      },
    },
  ];

  function onClose() {
    setKeyword(initValues.keyword);
    setSelectedTab(initValues.selectedTab);
    setRecords(initValues.records);
    setSRecords(initValues.records);
    setData(null);
  }

  React.useEffect(() => {
    if (data && data.type) {
      setSelectedTab(data.type);
    }
  }, [data]);

  return (
    <Modal
      visible={!!data}
      onCancel={onClose}
      footer={null}
      width={1000}
      closable={false}
      maskClosable={true}
      destroyOnClose={true}>
      <Wrapper>
        <Input.Search
          style={{marginBottom: 10}}
          placeholder="請輸入關鍵字"
          allowClear
          value={keyword}
          onChange={(e) => setKeyword(e.target.value)}
          onSearch={async () => {
            let {total, results} = await fetchRecords({
              keyword,
              columns,
              query:
                selectedTab === 'supervisior' ? {type: {$eq: '主管律師'}} : {},
              pagination: {
                offset: 0,
                limit: pagination.pageSize,
              },
            });

            if (selectedTab === 'supervisior') {
              setSRecords(results);
              setSPagination({...sPagination, total});
            } else {
              setRecords(results);
              setPagination({...pagination, total});
            }
          }}
        />
        <Tabs
          type="card"
          size="small"
          activeKey={selectedTab}
          onChange={(key) => setSelectedTab(key)}
          style={{marginBottom: 20}}>
          <Tabs.TabPane key="all" tab="全部">
            <Table
              scroll={{x: 500, y: 300}}
              pagination={{
                ...pagination,
                onChange: async (page, pageSize) => {
                  let {total, results} = await fetchRecords({
                    keyword,
                    columns,
                    pagination: {
                      offset: (page - 1) * pageSize,
                      limit: pageSize,
                    },
                  });
                  setRecords(results);
                  setPagination({...pagination, total});
                },
              }}
              columns={columns}
              dataSource={records}
            />
          </Tabs.TabPane>
          <Tabs.TabPane key="supervisior" tab="主管律師">
            <Table
              scroll={{x: 500, y: 300}}
              pagination={{
                ...sPagination,
                onChange: async (page, pageSize) => {
                  let {total, results} = await fetchRecords({
                    keyword,
                    columns,
                    pagination: {
                      offset: (page - 1) * pageSize,
                      limit: pageSize,
                    },
                  });
                  setSRecords(results);
                  setSPagination({...pagination, total});
                },
              }}
              columns={columns}
              dataSource={sRecords}
            />
          </Tabs.TabPane>
        </Tabs>
      </Wrapper>
    </Modal>
  );
}

const Wrapper = styled(ModalCustomStyle)``;
