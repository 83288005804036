import React from 'react';
import styled from 'styled-components';
import {Modal, message} from 'antd';
import {getNewOutlet, useOutlet, getOutlet} from 'reconnect.js';
import GenericForm from 'rev.sdk.js/Generic/Form';
import * as ApiUtil from 'rev.sdk.js/Utils/ApiUtil';
import DataJson from '../../data.json';
import * as AppActions from '../AppActions';
import {ModalCustomStyle} from '../AppContext/customStyledCss';

getNewOutlet('AdminOrderModifyPriceModal', null, {autoDelete: false});

export default function AdminOrderModifyPriceModal(props) {
  const [modalData, setModalData] = useOutlet('AdminOrderModifyPriceModal');
  const [user] = useOutlet('user');

  return (
    <Modal
      title={null}
      footer={null}
      bodyStyle={{padding: 0}}
      width={640}
      visible={modalData}
      onOk={() => {
        setModalData(null);
      }}
      onCancel={() => {
        setModalData(null);
      }}
      destroyOnClose={true}>
      <ModalContent
        modalData={modalData}
        setModalData={setModalData}
        user={user}
      />
    </Modal>
  );
}

const FormSpecDiscountItems = {
  schema: {
    discount_item: {
      title: '',
      type: 'object',
      properties: {
        name: {
          title: '減項名稱',
          type: 'string',
          default: '',
        },
        amount: {
          title: '減項金額',
          type: 'number',
          default: 0,
          minimum: 0,
        },
        payment_offline_method: {
          title: '線下收款方式',
          type: 'string',
          enum: ['郵政劃撥', '傳真刷卡', '現金', 'ATM轉帳匯款'],
        },
        paid_date: {
          title: '收款日期',
          type: 'string',
          format: 'date',
        },
        note: {
          title: '減項備註',
          type: 'string',
          default: '',
        },
      },
    },
  },
  uiSchema: {
    discount_item: {
      amount: {
        'ui:help': '請填入大於等於0的數值，若為0表示不異動',
      },
    },
  },
};

const FormSpecFeeItems = {
  schema: {
    fee_item: {
      title: '',
      type: 'object',
      properties: {
        name: {
          title: '加項名稱',
          type: 'string',
          default: '',
        },
        amount: {
          title: '加項金額',
          type: 'number',
          default: 0,
          minimum: 0,
        },
        note: {
          title: '加項備註',
          type: 'string',
          default: '',
        },
      },
    },
  },
  uiSchema: {
    fee_item: {
      amount: {
        'ui:help': '請填入大於等於0的數值，若為0表示不異動',
      },
    },
  },
};

function ModalContent(props) {
  const {modalData, setModalData} = props;
  const {instance} = modalData;

  const formTitle =
    modalData.type === 'discount' ? `調整金額減項` : `調整金額加項`;
  const formSpec =
    modalData.type === 'discount' ? FormSpecDiscountItems : FormSpecFeeItems;

  return (
    <Wrapper>
      <h2>{formTitle}</h2>

      <GenericForm
        instance={{}}
        schema={{
          title: '',
          type: 'object',
          properties: {
            total: {
              type: 'integer',
              title: '目前訂單總金額',
              default: instance.total,
              readOnly: true,
            },
            ...formSpec.schema,
          },
        }}
        uiSchema={{
          ...formSpec.uiSchema,
        }}
        onSubmit={async (formData, extValue) => {
          AppActions.setLoading(true);
          try {
            const data = {
              fee_item: formData.fee_item,
              discount_item: formData.discount_item,
            };
            await ApiUtil.req(
              `${DataJson.apiHost}/checkout/modify?order_number=${
                instance.order_number
              }&token=${getOutlet('user').getValue().token}`,
              {
                method: 'POST',
                data,
              },
            );

            setModalData(null);
            message.success('更新成功');
            setTimeout(() => window.location.reload(), 1500);
          } catch (err) {
            console.warn(err);
            message.error('本訂單無法調整價格');
          }
          AppActions.setLoading(false);
        }}
      />
    </Wrapper>
  );
}

const Wrapper = styled(ModalCustomStyle)`
  padding: 20px;

  & > .container {
    margin-top: 10px;
    margin-bottom: 20px;
  }

  .ant-input-number-input {
    color: #4e4e4e !important;
  }
`;
