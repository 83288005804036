import * as AppActions from '../AppActions';

const L = AppActions.L;

export function getStartEndDate(product) {
  const datePattern = /\d{4}-\d{2}-\d{2}/;

  if (!datePattern.test(product.stock_start_date)) {
    return [];
  } else if (isNaN(product.stock_duration) || product.stock_duration < 1) {
    return [];
  }

  const startDate = new Date(`${product.stock_start_date}T00:00`);
  const endDate = new Date(startDate);
  endDate.setDate(startDate.getDate() + product.stock_duration - 1);

  if (product.stock_start_time) {
    let [h, m] = product.stock_start_time.split(':');
    h = parseInt(h);
    m = parseInt(m);
    if (!isNaN(h) && !isNaN(m) && 0 <= h && h <= 23 && 0 <= m && m <= 59) {
      startDate.setHours(h);
      startDate.setMinutes(m);
    }
  }

  if (product.stock_end_time) {
    let [h, m] = product.stock_end_time.split(':');
    h = parseInt(h);
    m = parseInt(m);
    if (!isNaN(h) && !isNaN(m) && 0 <= h && h <= 23 && 0 <= m && m <= 59) {
      endDate.setHours(h);
      endDate.setMinutes(m);
    }
  }

  return [startDate, endDate];
}

export function isSoldOut(product) {
  if (!product.stock_type || product.stock_type === 'always') {
    return false;
  } else if (
    typeof product.stock_amount === 'number' &&
    typeof product.stock_sold_amount === 'number'
  ) {
    return product.stock_sold_amount >= product.stock_amount;
  }

  // don't let users to buy any products before the configure the amount properly
  return true;
}

export function getOnSaleInfo(product) {
  if (!product.stock_type || product.stock_type === 'always') {
    return {isOnSale: true, label: L('cart_add')};
  } else if (product.stock_type === 'total') {
    const soldOut = isSoldOut(product);
    return {
      isOnSale: !soldOut,
      label: !soldOut ? L('cart_add') : '已額滿',
    };
  } else if (product.stock_type === 'period') {
    const [startDate, endDate] = getStartEndDate(product);

    if (!startDate || !endDate) {
      return {isOnSale: false, label: '尚未開放'};
    }

    const now = new Date();
    const notOpen = now.getTime() < startDate.getTime();
    const closed = now.getTime() > endDate.getTime();
    const soldOut = isSoldOut(product);
    const isOnSale = !notOpen && !closed && !soldOut;
    let label = L('cart_add');

    if (notOpen) {
      label = '尚未開放';
    } else if (closed) {
      label = '超過報名時間';
    } else if (soldOut) {
      label = '已額滿';
    }

    return {isOnSale, label};
  }
}
