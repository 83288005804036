import React from 'react';
import styled from 'styled-components';
import {useOutlet, useOutletSetter} from 'reconnect.js';
import * as User from 'rev.sdk.js/Actions/User';
import * as AppActions from '../../AppActions';

const TOP_ROUTES = [
  {label: 'ENG', key: '/eng'},
  {label: 'JPN', key: '/japan'},
  {label: '常用下載', key: '/normal-download'},
  {label: '會員服務權益', key: '/rights'},
];

export default function TopContent(props) {
  const showLoginModal = useOutletSetter('login-modal');
  const [locale, setLocale] = useOutlet('locale');
  const [user] = useOutlet('user');
  const [cart] = useOutlet('cart');
  const [dimension] = useOutlet('dimension');
  const mobile = dimension.rwd === 'mobile';
  const [mobileNavVisible, setMobileNavVisible] = React.useState(false);

  return (
    <Wrapper>
      <Top mobile={mobile}>
        {TOP_ROUTES.map((item, index) => {
          return (
            <div
              key={`top-nav-item-${index}`}
              className="nav-item"
              onClick={() => {
                if (item.key === '/eng') {
                  setLocale({lang: 'en'});
                } else if (item.key === '/japan') {
                  setLocale({lang: 'jp'});
                }

                AppActions.navigate(item.key);
              }}>
              {item.label}
            </div>
          );
        })}

        {user ? (
          <div className="nav-item">
            <span
              className="profile"
              onClick={() => {
                AppActions.setLoading(true);
                AppActions.navigate('/member');
                setTimeout(() => AppActions.setLoading(false), 350);
              }}>
              {user.data?.name}
            </span>
            <span
              className="logout"
              onClick={async () => {
                AppActions.setLoading(true);
                await User.logout();
                AppActions.navigate('/');
                AppActions.setLoading(false);
              }}>
              登出
            </span>
          </div>
        ) : (
          <div className="nav-item">
            <span
              className="register"
              onClick={() => {
                AppActions.navigate('/register/request');
              }}>
              申請入會
            </span>
            <span className="divider">|</span>
            <span
              className="login"
              onClick={() => {
                showLoginModal(true);
              }}>
              會員登入
            </span>
          </div>
        )}
      </Top>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: var(--primaryColor);
`;

const Top = styled.div`
  max-width: var(--contentMaxWidth);
  width: 100%;
  margin: 0 auto;

  background-color: var(--primaryColor);
  padding: 6px;

  display: flex;
  justify-content: flex-end;

  & > .nav-item {
    color: #ffffff;
    font-size: ${(props) => (props.mobile ? '0.8rem' : '1rem')};
    margin-right: 8px;
    cursor: pointer;

    & > .profile,
    .cart,
    .logout,
    .register,
    .login {
      cursor: pointer;
    }

    & > .divider,
    .logout {
      margin-left: 5px;
      margin-right: 5px;
    }
  }

  @media screen and (max-width: 359px) {
    & > .nav-item {
      font-size: 0.7rem;
      margin-right: 5px;

      & > .divider,
      .cart {
        margin-left: 3px;
        margin-right: 3px;
      }
    }
  }
`;
