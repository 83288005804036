import React from 'react';
import {Button, Input, Modal, Table, message} from 'antd';
import {getNewOutlet, getOutlet, useOutlet} from 'reconnect.js';
import styled from 'styled-components';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import * as AppActions from '../../AppActions';
import {ModalCustomStyle} from '../../AppContext/customStyledCss';

getNewOutlet('admin-search-company-modal', null, {autoDelete: false});

export function showAdminSearchCompanyModal(data) {
  getOutlet('admin-search-company-modal').update(data);
}

const fetchRecords = async (
  keyword,
  columns,
  paging = {offset: 0, limit: 4},
) => {
  try {
    AppActions.setLoading(true);
    const jsStorageResult = await JStorage.fetchDocuments(
      'Company',
      {
        $or: columns.map((column) => {
          if (column.key === 'address') {
            return {
              $or: [
                {address_zip_code: {$regex: keyword}},
                {address_city: {$regex: keyword}},
                {address_detail: {$regex: keyword}},
              ],
            };
          }

          return {[column.key]: {$regex: keyword}};
        }),
      },
      null,
      paging,
    );

    if (jsStorageResult.total > 0) {
      const countResults = await JStorage.aggregateDocuments('Company', [
        {
          $match: {
            CONAME: {$in: jsStorageResult.results.map((c) => c.CONAME)},
          },
        },
        {$addFields: {company_id: {$toString: '$_id'}}},
        {
          $lookup: {
            from: 'user_profile',
            localField: 'company_id',
            foreignField: 'company_current',
            as: 'profiles',
          },
        },
        {
          $addFields: {
            _profiles: {
              $filter: {
                input: '$profiles',
                as: 'profile',
                cond: {$eq: ['$$profile.state', 1]},
              },
            },
          },
        },
        {
          $project: {
            count: {$size: '$profiles'},
            count_state_1: {$size: '$_profiles'},
          },
        },
      ]);

      for (const company of jsStorageResult.results) {
        const index = countResults.findIndex((i) => i._id.$oid === company.id);

        company['count'] = countResults[index].count;
        company['count_state_1'] = countResults[index].count_state_1;
      }
    }

    return jsStorageResult;
  } catch (err) {
    message.error('發生錯誤。');
  } finally {
    AppActions.setLoading(false);
  }
};

export default function AdminSearchCompanyModal(props) {
  const [data, setData] = useOutlet('admin-search-company-modal');
  const [keyword, setKeyword] = React.useState(null);
  const [records, setRecords] = React.useState(null);
  // const [_selectedRows, _setSelectedRows] = React.useState([]);
  const [pagination, setPagination] = React.useState({
    total: 0,
    pageSize: 4,
  });

  const columns = [
    {
      key: 'CONAME',
      title: '名稱',
      dataIndex: 'CONAME',
    },
    {
      title: '地址',
      key: 'address',
      dataIndex: 'address',
      render: (_, item) => {
        const display = `${item['address_zip_code']} ${item['address_city']} ${item['address_detail']}`;
        return <div style={{maxWidth: 250, width: '100%'}}>{display}</div>;
      },
    },
    {
      title: '電話',
      key: 'COPHONE1',
      dataIndex: 'COPHONE1',
      width: 150,
    },
    {
      title: '傳真',
      key: 'COFAX1',
      dataIndex: 'COFAX1',
      width: 150,
    },
    {
      title: '會員人數',
      key: 'count',
      dataIndex: 'count',
      width: 100,
    },
    {
      title: '入會人數',
      key: 'count_state_1',
      dataIndex: 'count_state_1',
      width: 100,
    },
    {
      key: '',
      title: '',
      dataIndex: '',
      render: (_, item) => {
        return (
          <Button
            onClick={() => {
              data.onChange(item.id);
              onClose();
            }}>
            選擇
          </Button>
        );
      },
    },
  ];

  function onClose() {
    setKeyword(null);
    setRecords(null);
    // _setSelectedRows([]);
    setData(null);
  }

  return (
    <Modal
      visible={!!data}
      onCancel={onClose}
      footer={null}
      width={1000}
      closable={false}
      maskClosable={true}
      destroyOnClose={true}>
      <Wrapper>
        <Input.Search
          style={{marginBottom: 10}}
          placeholder="請輸入關鍵字，例如：名稱、地址、電話、傳真。電話和傳真不含區域碼。"
          allowClear
          value={keyword}
          onChange={(e) => setKeyword(e.target.value)}
          onSearch={async () => {
            let {total, results} = await fetchRecords(keyword, columns, {
              offset: 0,
              limit: pagination.pageSize,
            });
            setRecords(results);
            setPagination({...pagination, total});
          }}
        />

        <Table
          style={{marginBottom: 10}}
          scroll={{x: 500, y: 400}}
          pagination={{
            ...pagination,
            size: 'small',
            showTotal: (total) => `共 ${total} 筆`,
            onChange: async (page, pageSize) => {
              let {total, results} = await fetchRecords(keyword, columns, {
                offset: (page - 1) * pageSize,
                limit: pageSize,
              });
              setRecords(results);
              setPagination({...pagination, total});
            },
          }}
          columns={columns}
          dataSource={records}
        />
      </Wrapper>
    </Modal>
  );
}

const Wrapper = styled(ModalCustomStyle)``;
