import React from 'react';
import {Helmet} from 'react-helmet';
import styled from 'styled-components';
import {getOutlet} from 'reconnect.js';
import {Steps} from 'antd';
import Config from '../../../data.json';
import userPendingJson from '../../../content/official/user-pending.json';
import {getUserStateLabel, getUserSubstateLabel} from '../../Utils/UserUtil';

const UserOutlet = getOutlet('user');

export function renderHtmlHelmet(props) {
  const {location} = props;

  if (
    location.pathname.indexOf('admin') !== -1 ||
    location.pathname.indexOf('print-preview') > -1
  ) {
    return (
      <Helmet>
        <title>{Config.site_name_dashboard}</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
        />
        <script
          src="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.13.216/pdf.min.js"
          integrity="sha512-IM60GPudO4jk+ZQm3UlJgKHhXQi5pNDM6mP+pLKL968YgkHMc7He3aGJOVHEZ9rJ4vAaEtJ8W6SKa7Qq4inzBA=="
          crossorigin="anonymous"
          referrerpolicy="no-referrer"
        />
        <script src="https://cdn.jsdelivr.net/jsbarcode/3.3.20/JsBarcode.all.min.js" />
        <script src="https://cdn.jsdelivr.net/npm/davidshimjs-qrcodejs@0.0.2/qrcode.min.js" />
        {/* <script src="https://cdn.sheetjs.com/xlsx-latest/package/dist/xlsx.full.min.js" /> */}
        <link href="/prism/prism.css" rel="stylesheet" />
        <script src="/prism/prism.js" />
      </Helmet>
    );
  }

  return (
    <Helmet>
      <title>{Config.site_name_full}</title>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
      />
      <link
        rel="stylesheet"
        type="text/css"
        charSet="UTF-8"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
      />
      <link
        rel="stylesheet"
        type="text/css"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
      />
      <script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${Config.gaId}`}
      />
    </Helmet>
  );
}

export function renderProfileCustomSection(sectionId, activePath, extraData) {
  if (sectionId === 'A') {
    const upr = UserOutlet.getValue().data;

    if (upr) {
      const user_type = upr.user_type;

      if (user_type === 'member') {
        const status = upr.status; //審核狀態
        const state = upr.state; //狀態
        const substate = upr.substate; //次狀態
        const jsonRequired = userPendingJson.schema.required;

        //實習律師
        if (state === 4) {
          const isStatusPending =
            status === 'pending_checkout' || status === 'pending_review';

          if (isStatusPending) {
            const isUprRequired = jsonRequired.every((key) => {
              if (key === 'public') {
                return typeof upr[key] === 'boolean';
              }
              return !!upr[key];
            });

            return (
              <ProfileSectionWrapper>
                <GraySectionContainer>
                  <div className="container">
                    <h2>歡迎您註冊台北律師公會！</h2>
                    <h3>
                      敬請完成以下項目，以利本會
                      <span className="highlight">
                        {getUserStateLabel(state)}
                      </span>
                      審查流程。
                    </h3>
                    <div className="content">
                      <Steps direction="vertical">
                        <Steps.Step
                          title="填寫個人資料"
                          description="到「個人資訊」填寫資料，＊為必填欄位。"
                          status={isUprRequired ? 'finish' : 'process'}
                        />
                      </Steps>
                    </div>
                  </div>
                </GraySectionContainer>
              </ProfileSectionWrapper>
            );
          }
        }

        //一般會員、特別會員、外國法事務律師
        if (substate === '4' || substate === '5' || substate === '6') {
          const isStatusPending =
            status === 'pending_checkout' || status === 'pending_review';

          if (isStatusPending) {
            const isUprRequired = jsonRequired.every((key) => {
              if (key === 'public') {
                return typeof upr[key] === 'boolean';
              }
              return !!upr[key];
            });

            return (
              <ProfileSectionWrapper>
                <GraySectionContainer>
                  <div className="container">
                    <h2>歡迎您註冊台北律師公會！</h2>
                    <h3>
                      敬請完成以下項目，以利本會
                      <span className="highlight">
                        {getUserSubstateLabel(substate)}
                      </span>
                      審查流程。
                    </h3>
                    <div className="content">
                      <Steps direction="vertical">
                        <Steps.Step
                          title="填寫個人資料"
                          description="到「個人資訊」填寫資料，＊為必填欄位。"
                          status={isUprRequired ? 'finish' : 'process'}
                        />
                        <Steps.Step
                          title="繳交入會費"
                          description="到「會費繳納」繳交入會費用。"
                          status={
                            status === 'pending_review' ? 'finish' : 'process'
                          }
                        />
                        <Steps.Step
                          title="寄送證明及簽名文件"
                          description={
                            <div style={{whiteSpace: 'pre-wrap'}}>
                              <p>
                                下載
                                <a
                                  style={{marginRight: 5, marginLeft: 5}}
                                  className="link"
                                  blank="_target"
                                  href={
                                    substate === '6'
                                      ? 'https://tba-revtel2-com-prod.s3.ap-northeast-1.amazonaws.com/外國法事務律師整合版-1701854755'
                                      : 'https://tba-revtel2-com-prod.s3.ap-northeast-1.amazonaws.com/會員入會資料整合版-1701854706'
                                  }
                                  rel="noreferrer">
                                  入會須知
                                </a>
                                詳閱填妥資料簽名。
                              </p>
                              <p>準備身分證影本、律師證書，</p>
                              <p>公職提供離職證明、退役軍人需檢附退伍令，</p>
                              <p>寄送至公會 tbax@ms17.hinet.net。</p>
                            </div>
                          }
                          status={'process'}
                        />
                      </Steps>
                    </div>
                  </div>
                </GraySectionContainer>
              </ProfileSectionWrapper>
            );
          }
        }
      }
    }
  }

  return null;
}

const ProfileSectionWrapper = styled.div``;

const GraySectionContainer = styled.div`
  margin: 0 auto;
  padding: 35px 20px 20px;
  text-align: center;
  white-space: pre;
  background-color: #f0f0f0;

  & > .container {
    margin: 0 auto;
    max-width: 500px;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & > h2 {
      margin-bottom: 10px;
      white-space: pre-wrap;
      font-size: 24px;
      color: var(--primaryColor);
    }

    & > h3 {
      margin-bottom: 20px;
      white-space: pre-wrap;
      color: var(--secondColor);

      .highlight {
        color: #343434;
        margin-left: 5px;
        margin-right: 5px;
      }
    }

    & > .content {
    }
  }
`;
