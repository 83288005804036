import React from 'react';
import {Form, Input, Button, Modal, message} from 'antd';
import styled from 'styled-components';
import {useOutlet} from 'reconnect.js';
import {InfoCircleFilled} from '@ant-design/icons';
import SdkLoginModal from 'rev.sdk.js/Components/LoginModal';
import * as User from 'rev.sdk.js/Actions/User';
import * as AppActions from '../../AppActions';

export default function LoginModal(props) {
  const [info, setInfo] = useOutlet('login-modal');

  if (info && info.admin) {
    return <SdkLoginModal {...props} />;
  }

  return (
    <Modal
      title={null}
      footer={null}
      bodyStyle={{padding: 0}}
      width={450}
      visible={!!info}
      onOk={() => {
        setInfo(false);
      }}
      onCancel={() => {
        setInfo(false);
      }}
      destroyOnClose={true}>
      <ModalContent {...props} closeModal={() => setInfo(false)} />
    </Modal>
  );
}

function ModalContent(props) {
  async function onFinish(values) {
    let {username, password} = values;

    AppActions.setLoading(true);
    try {
      await User.login({username, password});
      props.closeModal();
    } catch (ex) {
      console.warn(ex);

      if (ex?.response.error === 'user_permission_error') {
        message.error('很抱歉，您目前非本會會員，無法登入。');
      } else {
        message.error('無法登入, 請確認帳號密碼。');
      }
    }
    AppActions.setLoading(false);
  }

  return (
    <Wrapper>
      <h2>會員登入</h2>
      <div className="hint">
        <div className="text">
          <InfoCircleFilled style={{marginRight: 6}} />
          帳號請輸入您的身分證字號，第一個字母為大寫。
        </div>
        <div className="text">
          <InfoCircleFilled style={{marginRight: 6}} />
          若為舊系統用戶第一次登入，密碼預設為您的生日（年份使用西元年，例如:
          19850710）。
        </div>
      </div>

      <Form onFinish={onFinish} style={{maxWidth: 320, width: '100%'}}>
        <Form.Item
          name="username"
          rules={[{required: true, message: '帳號為必填欄位'}]}>
          <Input addonBefore="帳號" size="large" placeholder="請輸入帳號" />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{required: true, message: '密碼為必填欄位'}]}>
          <Input.Password
            addonBefore="密碼"
            size="large"
            placeholder="請輸入密碼"
          />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            className="submit">
            登入
          </Button>
        </Form.Item>
      </Form>

      <div className="extras">
        <Button
          type="link"
          size="large"
          onClick={() => {
            AppActions.navigate('/register/request/');
            props.closeModal();
          }}>
          申請入會
        </Button>
        <span className="divider">|</span>
        <Button
          type="link"
          size="large"
          onClick={() => {
            AppActions.navigate('/forgot-password/request');
            props.closeModal();
          }}>
          忘記密碼
        </Button>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  min-height: 330px;
  padding: 20px;

  border: 8px solid #c8b48c;

  & > h2 {
    margin-bottom: 20px;
    font-size: 1.5rem;
    color: #b49664;
  }

  & > .description {
    margin-bottom: 20px;
    max-width: 320px;
    font-size: 1rem;

    & > .link {
      padding-left: 8px;
      padding-right: 8px;
      font-weight: 500;
      color: #b49664;
      cursor: pointer;
    }
  }

  & > .extras {
    align-self: center;

    & > .divider {
      color: #c8b48c;
    }
  }

  .hint {
    margin-bottom: 15px;
    max-width: 320px;
    width: 100%;
    padding: 0px 10px;

    & > .text {
      font-size: 0.95rem;
      color: #868e96;
    }

    & > .text > .link {
      margin-right: 5px;
      margin-left: 5px;
    }
  }

  .ant-input-group-addon {
    color: #969696;
  }

  .submit {
    margin-bottom: 10px;
    padding: 5px;
    background-color: #323232;
    width: 100%;
    color: #b49664;
  }
`;
