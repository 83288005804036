import React from 'react';
import {getOutlet} from 'reconnect.js';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import SearchCompanyModal from '../Components/SearchCompanyModal';
import MoraleQueryModal from '../Components/MoraleQueryModal';

const CompaniesOutlet = getOutlet('companies');

export default function useLayoutInit(props) {
  const {location, pageContext} = props;

  React.useEffect(() => {
    // trigget only once
  }, []);

  React.useEffect(() => {
    // trigger when location change

    //tba custom logic
    async function fetchAllCompanies() {
      try {
        let resp = await JStorage.fetchAllDocuments('Company', {}, null, {
          id: 1,
          CONAME: 1,
          address_city: 1,
          address_zip_code: 1,
          address_detail: 1,
          COPHONE1: 1,
          COFAX1: 1,
        });

        CompaniesOutlet.update(resp);
      } catch (err) {
        console.warn(err);
      }
    }

    if (
      location.pathname.indexOf('/profile') > -1 ||
      location.pathname.indexOf('/company-request') > -1
    ) {
      fetchAllCompanies();
    }
  }, [location]);

  return {
    extraElement: (
      <>
        <SearchCompanyModal />
        <MoraleQueryModal />
      </>
    ),
  };
}
