import React from 'react';
import {Modal, Button, message} from 'antd';
import styled from 'styled-components';
import {getNewOutlet, getOutlet, useOutlet} from 'reconnect.js';
import GenericForm from 'rev.sdk.js/Generic/Form';
import {formatDateStr} from '../Utils/TimeUtil';
import * as AppActions from '../AppActions';
import * as AppActionsEx from '../AppActions/custom';

getNewOutlet('vegetarian-edit-modal', null, {autoDelete: false});

export function showVegetarianEditModal(data) {
  getOutlet('vegetarian-edit-modal').update(data);
}

function VegetarianEditModal(props) {
  const [data, setData] = useOutlet('vegetarian-edit-modal');

  return (
    <Modal
      title="會員及眷屬葷素資料"
      footer={null}
      width={750}
      style={{padding: 0}}
      visible={!!data}
      onOk={() => {
        setData(null);
      }}
      onCancel={() => {
        setData(null);
      }}
      destroyOnClose={true}>
      <ModalContent {...data} setData={setData} />
    </Modal>
  );
}

function calcTbaSeat(order) {
  let tba_seat = 0;
  const config_variants = order.items[0].config.variants || [];
  const product_variants = order.items[0].product.variants || [];

  config_variants.forEach((cv) => {
    const product_variant = product_variants.find((pv) => pv.name === cv.name);
    const product_choice = product_variant.choices.find(
      (pvc) => pvc.name === cv.choice,
    );

    tba_seat += product_choice.tba_seat || 0;
  });

  return tba_seat;
}

function ModalContent(props) {
  const {order, setData, isAdmin} = props;
  const {order_id, vegetarians} = order;
  const [values, setValues] = React.useState(vegetarians ? vegetarians : []);

  return (
    <ModalContentWrapper>
      <Button
        onClick={async () => {
          const resp = await AppActionsEx.getVegetarianInfo({
            isAdmin,
            user_id: order.owner,
          });

          if (Object.keys(resp).length > 0) {
            setValues(resp);
            return;
          }
          message.warning('未找到您的葷素資料，敬請手動填寫。');
        }}>
        取得葷素資料
      </Button>

      <GenericForm
        instance={{vegetarians: values}}
        schema={{
          title: '',
          type: 'object',
          required: [],
          properties: {
            'rev-line-0': {
              type: 'string',
              title: '葷素資料詳情',
            },
            vegetarians: {
              type: 'array',
              title: '',
              maxItems: calcTbaSeat(order),
              items: {
                type: 'object',
                properties: {
                  name: {type: 'string', title: '姓名'},
                  is_vegetarian: {type: 'boolean', title: '素食者'},
                  type: {
                    type: 'string',
                    title: '身份',
                    enum: ['member', 'adult', 'child'],
                    enumNames: ['會員', '眷屬大人', '眷屬小孩'],
                  },
                },
              },
            },
          },
        }}
        uiSchema={{
          'rev-line-0': {
            'ui:widget': 'rev-line',
            'ui:options': {label: false},
          },
        }}
        onSubmit={async (formData) => {
          const {vegetarians} = formData;
          try {
            await AppActionsEx.updateVegetariansInfo({
              isAdmin,
              order_id,
              vegetarians,
            });
            message.success('更新成功！');
            setData(null);
            setTimeout(() => {
              window.location.reload();
            }, 500);
          } catch (err) {
            console.warn(err);
            message.error('發生錯誤');
          } finally {
            AppActions.setLoading(false);
          }
        }}
      />
    </ModalContentWrapper>
  );
}

const ModalContentWrapper = styled.div``;

export default VegetarianEditModal;
