import {getOutlet} from 'reconnect.js';

const StaffsOutlet = getOutlet('staffs');

function findStaffInstance(username) {
  const instance = StaffsOutlet.getValue().find(
    (staff) => staff.id === username,
  );

  return instance;
}

export function getStaffValue(username, key, defaultValue) {
  const instance = findStaffInstance(username);
  const hasDefaultValue = typeof defaultValue !== 'undefined';

  if (!instance) {
    return hasDefaultValue ? defaultValue : '---';
  } else if (!instance[key]) {
    return hasDefaultValue ? defaultValue : '---';
  }

  return instance[key];
}
