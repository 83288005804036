import React from 'react';
import qs from 'query-string';
import * as User from 'rev.sdk.js/Actions/User';

function withIsInApp(WrappedComponent) {
  return function NextComponent(props) {
    const params = qs.parse(props.location.search);
    const {isInApp, refresh} = params;
    const [isLoadingAppToken, setIsLoadingApptoken] = React.useState(true);

    React.useEffect(() => {
      async function tryAutoLogin({refresh}) {
        setIsLoadingApptoken(true);
        await User.autoLogin({refresh});
        setIsLoadingApptoken(false);
      }

      if (refresh) {
        tryAutoLogin({refresh});
      } else {
        setIsLoadingApptoken(false);
      }
    }, [refresh]);

    return (
      <WrappedComponent
        {...props}
        isInApp={!!isInApp}
        isLoadingAppToken={isLoadingAppToken}
      />
    );
  };
}
export default withIsInApp;
