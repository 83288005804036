import React from 'react';
import styled from 'styled-components';
import {Modal, message} from 'antd';
import {getNewOutlet, getOutlet, useOutlet} from 'reconnect.js';
import GenericForm from 'rev.sdk.js/Generic/Form';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import * as AppActions from '../../AppActions';
import AdminSelectMoraleWidget from './AdminSelectMoraleWidget';
import {ModalCustomStyle} from '../../AppContext/customStyledCss';

getNewOutlet('admin-form-morale-list-edit-modal', null, {autoDelete: false});

export function showAdminFormMoraleListEditModal(data) {
  getOutlet('admin-form-morale-list-edit-modal').update(data);
}

function AdminFormMoraleListEditModal(props) {
  const [data, setData] = useOutlet('admin-form-morale-list-edit-modal');

  return (
    <Modal
      title="編輯倫理風紀欄位"
      footer={null}
      width={650}
      style={{padding: 10}}
      visible={!!data}
      onOk={() => {
        setData(null);
      }}
      onCancel={() => {
        setData(null);
      }}
      destroyOnClose={true}>
      <ModalContent {...data} setData={setData} />
    </Modal>
  );
}

function ModalContent(props) {
  const [user] = useOutlet('user');
  const {instance, values, extValues, setData} = props;

  const rjsfProps = {
    widgets: {
      'admin-select-morale-widget': AdminSelectMoraleWidget,
    },
  };

  return (
    <Wrapper>
      <GenericForm
        rjsfProps={rjsfProps}
        instance={{morale_list: values.morale_list || []}}
        schema={{
          title: '',
          type: 'object',
          required: [],
          properties: {
            morale_list: {
              type: 'array',
              title: '相關倫理風紀列表',
              items: {
                type: 'object',
                properties: {
                  morale: {
                    title: '倫理風紀項目',
                    type: 'string',
                  },
                  note: {
                    type: 'string',
                    title: '備註',
                  },
                },
              },
            },
          },
        }}
        uiSchema={{
          morale_list: {
            items: {
              morale: {
                'ui:widget': 'admin-select-morale-widget',
              },
              note: {
                'ui:widget': 'textarea',
              },
            },
          },
        }}
        onSubmit={async (formData, extValue) => {
          try {
            AppActions.setLoading(true);
            await JStorage.updateDocument(
              'form',
              {id: instance.id},
              {...formData},
            );

            message.success('修改成功');
            AppActions.setLoading(false);
            setData(null);
            setTimeout(() => window.location.reload(), 300);
          } catch (err) {
            console.warn(err);
            message.error('發生錯誤');
            AppActions.setLoading(false);
          }
        }}
      />
    </Wrapper>
  );
}

const Wrapper = styled(ModalCustomStyle)``;

export default AdminFormMoraleListEditModal;
