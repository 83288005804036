import React from 'react';
import styled from 'styled-components';
import {useOutlet, useOutletSetter} from 'reconnect.js';
import {Button, Menu} from 'antd';
import {Menu as MenuIcon} from '@styled-icons/boxicons-regular/Menu';
import * as AppActions from '../../AppActions';
import TopContent from './TopContent';

const L = AppActions.L;

export default function SiteNavBar(props) {
  const [locale, setLocale] = useOutlet('locale');
  const [dimension] = useOutlet('dimension');
  const mobile = dimension.rwd === 'mobile';
  const [mobileNavVisible, setMobileNavVisible] = React.useState(false);

  const routes = [
    {
      label: L('about'),
      key: '',
      children: [
        {
          key: '',
          label: '公會簡介',
          children: [
            {
              key: '/about?group=introduction&category=history',
              label: '軌跡-歷史沿革',
              children: [],
            },
            {
              key: '/about?group=introduction&category=team',
              label: '團隊-組織架構',
              children: [],
            },
            {
              key: '/about?group=introduction&category=thinking',
              label: '思維-使命理念',
              children: [],
            },
            {
              key: '/about?group=introduction&category=opposition',
              label: '在野-改革參與',
              children: [],
            },
            {
              key: '/about?group=introduction&category=overview',
              label: '視野-國際交流',
              children: [],
            },
            {
              key: '/about?group=introduction&category=personnel',
              label: '公會歷屆監理事',
              children: [],
            },
            {
              key: '/about?group=introduction&category=contact',
              label: '聯絡方式',
              children: [],
            },
          ],
        },
        {
          key: '',
          label: '公會規章',
          children: [
            {
              key: '/about?group=regulation&category=association',
              label: '會務相關法規',
              children: [],
            },
            {
              key: '/about?group=regulation&category=business',
              label: '業務相關法規',
              children: [],
            },
            {
              key: '/about?group=regulation&category=practicing',
              label: '律師執業相關法規',
              children: [],
            },
            {
              key: '/about?group=regulation&category=morale',
              label: '律師倫理案例選輯',
              children: [],
            },
          ],
        },
        {
          key: '',
          label: '會館巡禮',
          children: [
            {
              key: '/about?group=environment&category=pictures',
              label: '會館寫真',
              children: [],
            },
            {
              key: '/about?group=environment&category=operator',
              label: '會館營運辦法',
              children: [],
            },
            {
              key: '/about?group=environment&category=charge',
              label: '台北律師公會會館會議室外借須知暨收費標準',
              children: [],
            },
          ],
        },
        {
          key: '',
          label: '圖書資訊室',
          children: [
            {
              key: '/about?group=library&category=introduction',
              label: '館藏介紹',
              children: [],
            },
            {
              key: '/about?group=library&category=fax',
              label: '傳真檢索',
              children: [],
            },
            {
              key: '/about?group=library&category=search',
              label: '館藏檢索',
              children: [],
            },
            {
              key: '/about?group=library&category=university-serial',
              label: '大學期刊檢索',
              children: [],
            },
            {
              key: '/about?group=library&category=borrow',
              label: '各大學圖書館借閱方式',
              children: [],
            },
          ],
        },
        {
          key: '/about?group=member',
          label: '台北律師公會會員動態',
          children: [],
        },
      ],
    },
    {
      label: L('member'),
      key: '',
      children: [
        {key: '/member', label: '會員主頁', children: []},
        {key: '/profile', label: '個人資訊', children: []},
        {key: '/videos', label: '北律雲-課程影片', children: []},
        {key: '/live_streams', label: '北律雲-直播串流', children: []},
        {key: '/products?sort=-session.date', label: '課程活動', children: []},
      ],
    },
    {
      label: L('law'),
      key: '',
      children: [
        {
          key: '/law-service?group=periodicals',
          label: '律師法學期刊',
          children: [],
        },
        {
          key: '',
          label: '出版線上',
          children: [
            {
              key: '/law-service?group=online&category=book',
              label: '新書通報',
              children: [],
            },
            {
              key: '/law-service?group=online&category=magazine',
              label: '會刊',
              children: [],
            },
            {
              key: '/law-service?group=online&category=publication',
              label: '公會出版品',
              children: [],
            },
            {
              key: '/law-service?group=online&category=periodical',
              label: '律師雜誌',
              children: [],
            },
          ],
        },
        {
          key: '/law-service?group=internal',
          label: '國內法律資料庫網站',
          children: [],
        },
        {
          key: '/law-service?group=external',
          label: '國外法律資料庫網站',
          children: [],
        },
        {
          key: '/law-service?group=search',
          label: '法律類搜尋引擎',
          children: [],
        },
        {
          key: '',
          label: '圖書資訊服務',
          children: [
            {
              key: '/law-service?group=library&category=introduction',
              label: '館藏介紹',
              children: [],
            },
            {
              key: '/law-service?group=library&category=fax',
              label: '傳真檢索',
              children: [],
            },
            {
              key: '/law-service?group=library&category=search',
              label: '館藏檢索',
              children: [],
            },
            {
              key: '/law-service?group=library&category=university-serial',
              label: '大學期刊檢索',
              children: [],
            },
            {
              key: '/law-service?group=library&category=borrow',
              label: '各大學圖書館借閱方式',
              children: [],
            },
          ],
        },
        {
          key: 'https://portal.ezlawyer.com.tw/Login.do',
          label: '司法院律師單一入口',
          children: [],
        },
        {key: '/law-service?group=column', label: '法律普及專欄', children: []},
      ],
    },
    {
      label: L('public'),
      key: '__group-public-service',
      children: [
        {
          key: '/public-service?group=roster',
          label: '我要找律師',
          children: [],
        },
        {
          key: '/public-service?group=brand',
          label: '商標法事務專區',
          children: [],
        },
        {
          key: '',
          label: '社會參與',
          children: [
            {
              key: '/public-service?group=society&category=human-rights',
              label: '人權委員會',
              children: [],
            },
            {
              key: '/public-service?group=society&category=law-helper',
              label: '法律扶助委員會',
              children: [],
            },
            {
              key: '/public-service?group=society&category=judicial-reform',
              label: '司法改革委員會',
              children: [],
            },
            {
              key: '/public-service?group=society&category=law-education',
              label: '法治教育委員會',
              children: [],
            },
            {
              key: '/public-service?group=society&category=environmental-law',
              label: '環境法委員會',
              children: [],
            },
            {
              key: '/public-service?group=society&category=family-law',
              label: '家事法委員會',
              children: [],
            },
            {
              key: '/public-service?group=society&category=lawyer-charity',
              label: '律師公益事務委員會',
              children: [],
            },
            {
              key: '/public-service?group=society&category=labor-law',
              label: '勞動法委員會',
              children: [],
            },
          ],
        },
        {
          key: '/public-service?group=subsidy',
          label: '論文補助',
          children: [],
        },
        {
          key: '',
          label: '網域爭議名稱處理',
          children: [
            {
              key: '/public-service?group=domain&category=manual',
              label: '宣導手冊',
              children: [],
            },
            {
              key: '/public-service?group=domain&category=rules',
              label: '相關法規',
              children: [],
            },
            {
              key: '/public-service?group=domain&category=flow',
              label: '流程說明',
              children: [],
            },
            {
              key: '/public-service?group=domain&category=expert',
              label: '專家名單',
              children: [],
            },
            {
              key: '/public-service?group=domain&category=standard',
              label: '費用標準',
              children: [],
            },
            {
              key: '/public-service?group=domain&category=form',
              label: '書狀表格',
              children: [],
            },
            {
              key: '/public-service?group=domain&category=qa',
              label: 'Q & A',
              children: [],
            },
            {
              key: '/public-service?group=domain&category=announcement',
              label: '爭議決定公告',
              children: [],
            },
          ],
        },
        {
          key: '/public-service?group=literature',
          label: '法律文學',
          children: [],
        },
        {
          key: '/public-service?group=lecturer',
          label: '上市櫃講師',
          children: [],
        },
        {
          key: '/public-service?group=fraud',
          label: '詐騙警報',
          children: [],
        },
        {
          key: '/public-service?group=report',
          label: '倫理風紀專區',
          children: [],
        },
      ],
    },
  ];

  return (
    <Wrapper>
      <TopContent />
      <div className="main-container">
        <Main mobile={mobile}>
          <div
            className="logo"
            onClick={() => {
              AppActions.navigate('/');
              setLocale({lang: 'tw'});
            }}>
            <img className="logo-img" src="/images/site-logo.png" alt="logo" />
          </div>
          <div className="nav-items">
            {routes.map((item, index) => (
              <div
                key={`root-nav-item-${index}`}
                className="nav-item"
                onClick={() => {
                  if (item.key.indexOf('/') > -1) {
                    AppActions.navigate(item.key);
                  }
                }}>
                {item.label}

                {item.children.length > 0 && (
                  <div className="submenu">
                    <div className="container">
                      <div className={`left index-${index}`}>
                        <div className="nav-items">
                          {item.children.map((subroute, index) => {
                            return (
                              <div
                                key={`subroute-nav-item-${index}`}
                                className={`nav-item selected-index-${index}`}
                                onClick={() =>
                                  AppActions.navigate(subroute.key)
                                }>
                                <div className="gutter-1" />
                                <div className="gutter-2" />
                                <div className="text">{subroute.label}</div>
                                {subroute.children.length > 0 && (
                                  <div className="menu">
                                    {subroute.children.map((route, index) => (
                                      <div
                                        key={`route-${index}`}
                                        className="menu-item"
                                        onClick={() =>
                                          AppActions.navigate(route.key)
                                        }>
                                        {route.label}
                                      </div>
                                    ))}
                                  </div>
                                )}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      <div className={`right selected-${index}`}>
                        <div className="nav-items">
                          <div className="fake-nav-item" />
                          <div className="fake-nav-item" />
                          <div className="fake-nav-item" />
                          <div className="gutter-2" />
                          <div className="gutter-1" />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
          <div className="search"></div>
          <div className="rwd-toggle">
            <Button
              className="rwd-toggle-button"
              shape="circle"
              size="small"
              icon={<MenuIcon size={18} color="#ffffff" />}
              onClick={() => setMobileNavVisible(true)}
            />
          </div>
        </Main>
      </div>

      <MobileNavDrawer
        visible={mobile && mobileNavVisible}
        setVisible={setMobileNavVisible}
        routes={routes}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: fixed;
  top: 0px;
  z-index: 101;
  width: 100vw;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

  & > .main-container {
    background-color: var(--thirdColor);
  }
`;

const Main = styled.div`
  max-width: var(--contentMaxWidth);
  width: 100%;
  margin: 0 auto;

  background-color: var(--thirdColor);
  padding: 0px 20px;
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  & > .logo {
    width: 100px;
    display: flex;
    align-items: center;

    & > .logo-img {
      width: 85px;
      cursor: pointer;
    }

    ${(props) => (props.mobile ? `& > .logo-img {width: 55px;}` : ``)};
  }

  & > .nav-items {
    flex: 1;

    display: flex;
    justify-content: center;
    align-items: center;

    & > .nav-item {
      max-width: 200px;
      width: 100%;
      height: 100%;

      color: var(--primaryColor);
      font-size: 1.3rem;
      font-weight: 500;
      text-align: center;
      letter-spacing: 1px;
      cursor: pointer;

      padding: 20px 25px;

      &:hover {
        background-color: #ffffff;

        & > .submenu {
          border-top: 5px solid var(--primaryColor);
          height: 360px;
        }
      }

      ${(props) =>
        props.mobile
          ? `visibility: hidden; width:0px; height:0px; padding:0px;`
          : `visibility: visible;`};

      & > .submenu {
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        position: absolute;
        top: 62px;
        left: 0px;

        max-width: var(--contentMaxWidth);
        width: 100%;
        margin: 0 auto;

        background-color: #646464;
        height: 0px;
        overflow: hidden;
        transition: 200ms;

        & > .container {
          width: 100%;
          display: flex;
          height: 360px;
          color: #ffffff;

          & > .left {
            width: 100%;

            &.index-0 {
              ${getLeftMaxWidth(0)}
            }

            &.index-1 {
              ${getLeftMaxWidth(1)}
            }

            &.index-2 {
              ${getLeftMaxWidth(2)}
            }

            &.index-3 {
              ${getLeftMaxWidth(3)}
            }

            & > .nav-items {
              width: 100%;

              & > .nav-item {
                display: flex;
                justify-content: flex-end;
                position: relative;

                & > .text {
                  padding: 8px 25px;
                  max-width: 200px;
                  width: 100%;
                  text-align: left;
                  font-size: 0.9rem;
                }

                &:hover {
                  background-color: #323232;
                }

                & > .menu {
                  position: absolute;
                  right: -200px;

                  max-width: 200px;
                  width: 100%;
                  height: 0px;
                  overflow: hidden;
                  transition: 200ms;

                  & > .menu-item {
                    padding: 8px 25px;
                    width: 100%;
                    text-align: left;
                    font-size: 0.9rem;
                    color: #969696;

                    &:hover {
                      font-weight: 600;
                      color: #323232;
                    }
                  }
                }
              }

              & > .selected-index-0 {
                ${getSelectedIndexCss(0)}
              }

              & > .selected-index-1 {
                ${getSelectedIndexCss(1)}
              }

              & > .selected-index-2 {
                ${getSelectedIndexCss(2)}
              }

              & > .selected-index-3 {
                ${getSelectedIndexCss(3)}
              }

              & > .selected-index-4 {
                ${getSelectedIndexCss(4)}
              }

              & > .selected-index-5 {
                ${getSelectedIndexCss(5)}
              }

              & > .selected-index-6 {
                ${getSelectedIndexCss(6)}
              }

              & > .selected-index-7 {
                ${getSelectedIndexCss(7)}
              }
            }
          }

          & > .right {
            flex: 1;
            height: 360px;
            background-color: #ffffff;

            & > .nav-items {
              display: flex;
              width: 100%;

              & > .fake-nav-item {
                max-width: 200px;
                width: 100%;
                height: 38px;
              }
            }

            &.selected-0 {
            }

            &.selected-1 {
              & > .nav-items > .nav-item:nth-child(3) {
                display: none;
              }
            }

            &.selected-2 {
              & > .nav-items > .nav-item:nth-child(3),
              .nav-item:nth-child(2) {
                display: none;
              }
            }

            &.selected-3 {
              & > .nav-items > .nav-item:nth-child(3),
              .nav-item:nth-child(2),
              .nav-item:nth-child(1) {
                display: none;
              }
            }
          }
        }

        & .gutter-1 {
          width: 120px;
        }

        & .gutter-2 {
          flex: 1;
        }
      }
    }
  }

  & > .search {
    width: 100px;
  }

  & > .rwd-toggle {
    & > .rwd-toggle-button {
      background-color: var(--thirdColor);
      border: none;
    }

    ${(props) => (props.mobile ? `display: block;` : `display: none;`)}
  }

  ${(props) => (props.mobile ? `padding: 10px;` : ``)};
`;

function getLeftMaxWidth(index) {
  let styles = '';

  if (typeof window === 'undefined' || !window.screen) {
    return styles;
  }

  if (window.screen.width > 1440) {
    let gutter = 200;
    styles = `max-width: ${320 + gutter + 200 * index}px;`;
  } else if (window.screen.width >= 1040 && window.screen.width <= 1440) {
    let gutter = (window.screen.width - 800 - 240) / 2;

    if (gutter < 1) {
      gutter = 0;
    }

    styles = `@media screen and (max-width: ${window.screen.width}px) {
      max-width: ${320 + gutter + 200 * index}px;
    }`;
  } else {
    let buttonWidth = (window.screen.width - 240) / 4;

    styles = `@media screen and (max-width: ${window.screen.width}px){
      max-width: ${buttonWidth * (index + 1) + 120}px;

      & > .nav-items > .nav-item > .text {
        max-width: ${buttonWidth}px;
        padding-left: 5px;
        padding-right: 5px;
        font-size: 0.5rem;
      }
    }`;
  }

  return styles;
}

function getSelectedIndexCss(index) {
  let styles = '';

  if (typeof window === 'undefined' || !window.screen) {
    return styles;
  }

  if (window.screen.width > 1040) {
    styles = `&:hover {
      & > .menu {
        top: -${38 * index}px;
        height: auto;
      }
    }`;
  } else {
    styles = `&:hover {
      & > .menu {
        top: -${34 * index}px;
        height: auto;
        & > .menu-item {
          font-size: 0.5rem;
        }
      }
    }`;
  }

  return styles;
}

function MobileNavDrawer(props) {
  const {visible, setVisible, routes} = props;
  const [dimension] = useOutlet('dimension');
  const mobile = dimension.rwd === 'mobile';

  return (
    <>
      <MobileNavDrawerBackDrop
        mobile={mobile}
        visible={visible}
        onClick={() => setVisible(false)}
      />

      <MobileNavDrawerWrapper mobile={mobile} visible={visible}>
        <h3>功能選單</h3>
        <Menu
          mode="inline"
          theme="dark"
          style={{
            width: '100%',
            height: '100%',
            backgroundColor: 'var(--thirdColor)',
          }}>
          {routes.map((item, index) => {
            if (item.children.length > 0) {
              return (
                <Menu.SubMenu
                  key={`mobile-root-nav-item-${index}`}
                  className="white-color"
                  title={item.label}>
                  {item.children.map((subroute, idx) => {
                    if (subroute.children.length > 0) {
                      return (
                        <Menu.SubMenu
                          key={`mobile-subroute-nav-item-${idx}`}
                          className="white-color"
                          title={subroute.label}
                          onClick={() => {
                            AppActions.navigate(subroute.key);
                            setVisible(false);
                          }}>
                          {subroute.children.map((route, i) => (
                            <Menu.Item
                              // key={`mobile-nav-item-${i}`}
                              className="white-color"
                              onClick={() => {
                                AppActions.navigate(route.key);
                                setVisible(false);
                              }}>
                              {route.label}
                            </Menu.Item>
                          ))}
                        </Menu.SubMenu>
                      );
                    }
                    return (
                      <Menu.Item
                        key={`mobile-subroute-nav-item-${idx}`}
                        className="white-color"
                        onClick={() => {
                          if (subroute.key.indexOf('/') > -1) {
                            console.log('DBG subroute', subroute);
                            AppActions.navigate(subroute.key);
                            setVisible(false);
                          }
                        }}>
                        {subroute.label}
                      </Menu.Item>
                    );
                  })}
                </Menu.SubMenu>
              );
            }

            return (
              <Menu.Item
                key={`mobile-root-nav-item-${index}`}
                className="white-color"
                onClick={() => {
                  if (item.key.indexOf('/') > -1) {
                    AppActions.navigate(item.key);
                    setVisible(false);
                  }
                }}>
                {item.label}
              </Menu.Item>
            );
          })}
        </Menu>
      </MobileNavDrawerWrapper>
    </>
  );
}

const MobileNavDrawerBackDrop = styled.div`
  width: 100vw;
  height: 100vh;

  position: absolute;
  top: 0px;
  left: 0px;
  background-color: rgba(255, 255, 255, 0.5);

  ${(props) => (props.visible ? `display: block;` : `display: none;`)};
`;

const MobileNavDrawerWrapper = styled.div`
  width: 256px;
  height: calc(100vh - 32px);
  overflow-y: auto;
  background-color: var(--thirdColor);

  position: absolute;
  top: 32px;
  right: -256px;
  transition: 300ms;

  & > h3 {
    padding: 20px 24px 0px;
    color: #ffffff;
  }

  & .white-color {
    color: #ffffff;
  }

  ${(props) => (props.visible ? `right: 0px;` : ``)}
`;
