export function getUserTypeLabel(userType) {
  switch (userType) {
    case 'member':
      return '律師';
    case 'extra_member':
      return '其他';
    default:
      return userType;
  }
}

//審核狀態
export function getUserStatusLabel(status) {
  switch (status) {
    case 'pending_checkout':
      return '入會費待繳';
    case 'pending_review':
      return '入會待審核';
    case 'success':
      return '入會';
    case 'withdraw':
      return '退會';
    default:
      return status;
  }
}

//狀態
export function getUserStateLabel(state) {
  switch (state) {
    case 1:
      return '入會';
    case 2:
      return '退會';
    case 3:
      return '其他';
    case 4:
      return '實習律師';
    case 5:
      return '跨區';
    case 6:
      return '停止';
    default:
      return state;
  }
}

//次狀態
export function getUserSubstateLabel(substate) {
  switch (substate) {
    case '':
      return '無';
    case '1':
      return '主區會員';
    case '2':
      return '準會員';
    case '3':
      return '兼區會員';
    case '4':
      return '一般會員';
    case '5':
      return '特別會員';
    case '6':
      return '外國法事務律師';
    case '7':
      return '準特別會員';
    default:
      return substate;
  }
}

export function getInsuranceTypeLabel(insType) {
  switch (insType) {
    case 'member':
      return '會員本人';
    case 'adult':
      return '眷屬(大人)';
    case 'child':
      return '眷屬(小孩)';
    default:
      return insType;
  }
}

export function getInsuranceRelationshipLabel(value) {
  switch (value) {
    case 'legal_heir':
      return '法定繼承人';
    case 'mate':
      return '配偶';
    case 'parent':
      return '父母';
    case 'child':
      return '子女';
    case 'grandparent':
      return '祖父母';
    case 'brother':
      return '兄弟';
    case 'sister':
      return '姐妹';
    case 'grandson':
      return '孫子';
    default:
      return value;
  }
}

export function getUserSexLabel(sex) {
  switch (sex) {
    case 'M':
      return '男';
    case 'F':
      return '女';
    default:
      return sex;
  }
}

export function getUserInformToLabel(value) {
  switch (value) {
    case undefined:
      return '無';
    case null:
      return '無';
    case 1:
      return '事務所';
    case 2:
      return '律師法第26條陳明收受送達處所';
    case 3:
      return '不寄';
    default:
      return value;
  }
}

export function getUserInformByLabel(value) {
  switch (value) {
    case null:
      return '無';
    case 1:
      return 'Eamil';
    case 2:
      return '傳真';
    case 3:
      return '地址條';
    default:
      return value;
  }
}

export function getUserPunishmentTypeLabel(value) {
  switch (value) {
    case null:
      return '無';
    case 'IN':
      return '會內';
    case 'OUT':
      return '會外';
    default:
      return value;
  }
}

export function getUserInoutLeaveReasonLabel(value) {
  switch (value) {
    case 'none':
      return '無';
    case 'A':
      return '自請退會';
    case 'B':
      return '決議退會';
    case 'C':
      return '停止執行職務';
    case 'D':
      return '病故';
    case 'E':
      return '許可證到期退會';
    default:
      return value;
  }
}

export function getUserDegree(value) {
  switch (value) {
    case 'B':
      return '大學';
    case 'M':
      return '研究所';
    case 'D':
      return '博士班';
    default:
      return value;
  }
}

export function getUserExperienceTbaPositionLabel(value) {
  switch (value) {
    case 'none':
      return '無';
    case 'A01':
      return '理事長';
    case 'A02':
      return '常務理事';
    case 'A03':
      return '理事';
    case 'A04':
      return '常務監事';
    case 'A05':
      return '監事';
    case 'A06':
      return '全聯會代表';
    case 'B01':
      return '主任委員';
    case 'B03':
      return '副主任委員';
    case 'B02':
      return '委員';
    case 'B04':
      return '會員';
    case 'B05':
      return '執行長';
    case 'B06':
      return '顧問';
    case 'C01':
      return '秘書長';
    case 'C02':
      return '副秘書長';
    case 'C03':
      return '專職副秘書長';
    case 'C04':
      return '主任';
    case 'C05':
      return '文書主任';
    case 'C06':
      return '總務主任';
    case 'C07':
      return '會計主任';
    case 'C08':
      return '圖書主任';
    case 'C09':
      return '公關主任';
    case 'D01':
      return '執行秘書';
    case 'D02':
      return '資訊顧問';
    case 'E01':
      return '秘書';
    case 'E02':
      return '專員';
    case 'E03':
      return '事務員';
    case 'E04':
      return '資深專員';
    case 'F01':
      return '專職主任';
    case 'F02':
      return '研究員';
    default:
      return value;
  }
}

export function getUserAddressInfo({user, company}) {
  if (!user) {
    return '';
  }

  const hasMailAddress =
    !!user.mail_address_zip_code &&
    !!user.mail_address_city &&
    !!user.mail_address_detail;

  const hasCompanyAddress = !!user.company_current;

  const hasResidenceAddress =
    !!user.residence_address_zip_code &&
    !!user.residence_address_city &&
    !!user.residence_address_detail;

  if (hasMailAddress) {
    return `${user.mail_address_zip_code} ${user.mail_address_city}${user.mail_address_detail}`;
  }

  if (hasCompanyAddress) {
    const address = company
      ? `${user.company_zip_code || company.address_zip_code} ${
          user.company_city || company.address_city
        }${user.company_detail || company.address_detail}`
      : null;

    return `${address}`;
  }

  if (hasResidenceAddress) {
    return `${user.residence_address_zip_code} ${user.residence_address_city}${user.residence_address_detail}`;
  }

  return '';
}

export function getUserJoinDates(inouts) {
  let join_start_date = '';
  let join_end_date = '';

  if (inouts && Array.isArray(inouts) && inouts.length > 0) {
    const _inouts = [...inouts]
      .filter((r) => !!r.EFF_DATE)
      .map((r) => ({
        ...r,
        EFF_DATE: r.EFF_DATE.split('T')[0],
      }))
      .sort(
        (a, b) =>
          new Date(b.EFF_DATE).getTime() - new Date(a.EFF_DATE).getTime(),
      );

    join_start_date = _inouts.length > 0 ? _inouts[0].EFF_DATE : '';

    join_end_date =
      _inouts.length > 0
        ? _inouts[0].EXPR_DATE !== '9999-12-31T00:00:00'
          ? _inouts[0].EXPR_DATE
            ? _inouts[0].EXPR_DATE.split('T')[0]
            : ''
          : ''
        : '';
  }

  return {join_start_date, join_end_date};
}

export function getUserTransformDate(transforms) {
  let transform_type = '';
  let transform_date = '';

  if (transforms && Array.isArray(transforms) & (transforms.length > 0)) {
    const _transforms = [...transforms]
      .filter((r) => !!r.TRNS_DATE)
      .map((r) => ({...r, TRANS_DATE: r.TRNS_DATE.split('T')[0]}))
      .sort(
        (a, b) =>
          new Date(b.TRANS_DATE).getTime() - new Date(a.TRNS_DATE).getTime(),
      );

    transform_type = _transforms.length > 0 ? _transforms[0].TYPE : '';
    transform_date = _transforms.length > 0 ? _transforms[0].TRANS_DATE : '';
  }

  return {transform_type, transform_date};
}

const USER_TWBA_KEYS = [
  'LAWYER_NO', //地區公會會員編號
  'name', //會員姓名
  'NAME_ENG', //會員英文姓名
  'SEX', //會員性別
  'birthday', //出生年月日
  'id_card_number', //身分證字號
  'residence_address_city', //戶籍地址
  'residence_address_zip_code', //戶籍地址
  'residence_address_detail', //戶籍地址
  'substate', //次狀態
  'inouts', //入會日期、退會日期 array of object
  'transforms', //擇定日期 array of object
  'C_QUALIFY_DATE', //職前訓練合格證書字號
  'C_QUALIFY_WORD', //職前訓練合格證書字號
  'C_QUALIFY_NO', //職前訓練合格證書字號
  'A_QUALIFY_DATE', //律師證書字號
  'A_QUALIFY_WORD', //律師證書字號
  'A_QUALIFY_NO', //律師證書字號
  'educations', //學歷 array of object
  'experiences', //經歷 array of object
  'company_current', //主事務所或機構律師任職法人之名稱
  'company_city', //主事務所或機構律師任職法人之地址
  'company_zip_code', //主事務所或機構律師任職法人之地址
  'company_detail', //主事務所或機構律師任職法人之地址
  'company_phone', //電話
  'company_fax', //傳真電話
  'email', //電子郵件信箱
  'phone', //行動電話
  'punishments', //曾否受過懲戒或判處罪刑 array of object
  'mail_address_city', //通訊地址、26條陳明收送地址
  'mail_address_zip_code', //通訊地址、26條陳明收送地址
  'mail_address_detail', //通訊地址、26條陳明收送地址
];

const USER_TWBA_ARRAY_KEYS = [
  'inouts',
  'transforms',
  'educations',
  'experiences',
  'punishments',
];

export function isUpdateUserTwbaData({instance, formData}) {
  const diffResults = [];

  if (
    instance &&
    formData &&
    Object.keys(instance).length > 0 &&
    Object.keys(formData).length > 0
  ) {
    for (const key of USER_TWBA_KEYS) {
      if (instance[key] !== formData[key]) {
        if (!USER_TWBA_ARRAY_KEYS.includes(key)) {
          //primitive value
          diffResults.push({
            key,
            before: instance[key],
            after: formData[key],
          });
        }

        if (USER_TWBA_ARRAY_KEYS.includes(key)) {
          if (instance[key] && !formData[key]) {
            diffResults.push({
              key,
              before: instance[key],
              after: formData[key],
            });
          }

          if (!instance[key] && formData[key]) {
            diffResults.push({
              key,
              before: instance[key],
              after: formData[key],
            });
          }

          if (Array.isArray(instance[key]) && Array.isArray(formData[key])) {
            if (instance[key].length !== formData[key].length) {
              diffResults.push({
                key,
                before: instance[key],
                after: formData[key],
              });
            }

            if (instance[key].length === formData[key].length) {
              for (let i = 0; i < instance[key].length; i++) {
                const instanceItem = instance[key][i];
                const formDataItem = formData[key][i];

                const iItemKeys = Object.keys(instanceItem);
                const fItemKeys = Object.keys(formDataItem);

                if (iItemKeys.length !== fItemKeys.length) {
                  diffResults.push({
                    key,
                    before: instance[key],
                    after: formData[key],
                  });
                }

                if (iItemKeys.length === fItemKeys.length) {
                  for (const iKey of iItemKeys) {
                    if (instanceItem[iKey] !== formDataItem[iKey]) {
                      diffResults.push({
                        key,
                        before: instance[key],
                        after: formData[key],
                      });
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    const isUpdate = diffResults.length > 0;
    return [isUpdate, diffResults];
  }

  return [false, diffResults];
}
