import React from 'react';
import styled from 'styled-components';
import {Modal, Form, Input, Button, message} from 'antd';
import {getNewOutlet, getOutlet, useOutlet} from 'reconnect.js';
import * as AppActions from '../AppActions';
import * as AppActionsEx from '../AppActions/custom';
import permissionRequired from '../Utils/PermissionRequiredUtil';
import {ModalCustomStyle} from '../AppContext/customStyledCss';

getNewOutlet('AdminMemberPasswordResetModal', null, {autoDelete: false});

export function showAdminMemberPasswordResetModal(data) {
  getOutlet('AdminMemberPasswordResetModal').update(data);
}

export default function AdminMemberPasswordResetModal(props) {
  const [data, setData] = useOutlet('AdminMemberPasswordResetModal');

  return (
    <Modal
      title="重設密碼"
      footer={null}
      bodyStyle={{padding: 0}}
      width={400}
      visible={data}
      onOk={() => {
        setData(null);
      }}
      onCancel={() => {
        setData(null);
      }}>
      <ModalContent {...data} setData={setData} />
    </Modal>
  );
}

function ModalContent(props) {
  const {context, setData} = props;
  const {instance, values, setValues} = context;
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    const {password, password1} = values;

    if (!permissionRequired('/admin/users/_write_reset_password')) {
      message.error('您無此權限');
      return;
    }

    if (password !== password1) {
      message.error('兩次新密碼輸入不相符');
    }

    try {
      await AppActions.setLoading(true);
      await AppActionsEx.adminResetPasswordByUser({
        user_id: instance.owner,
        new_password: password,
      });

      message.success('重設完成。');

      form.resetFields();
      setData(null);
    } catch (err) {
      console.warn(err);
      message.error('發生錯次');
    } finally {
      AppActions.setLoading(false);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Wrapper>
      <Center>
        <Form
          name="basic"
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}>
          <Form.Item
            label="請輸入新密碼"
            name="password"
            rules={[
              {
                required: true,
                message: '新密碼不可為空',
              },
            ]}>
            <Input />
          </Form.Item>
          <Form.Item
            label="請再次輸入新密碼"
            name="password1"
            rules={[
              {
                required: true,
                message: '新密碼不可為空',
              },
            ]}>
            <Input />
          </Form.Item>

          <Form.Item style={{marginTop: 50, textAlign: 'right'}}>
            <Button type="primary" htmlType="submit">
              確認重設
            </Button>
          </Form.Item>
        </Form>
      </Center>
    </Wrapper>
  );
}

const Wrapper = styled(ModalCustomStyle)`
  padding: 20px;

  & > .container {
    margin-top: 10px;
    margin-bottom: 20px;
  }
`;

const Center = styled.div`
  max-width: 320px;
  width: 100%;
  margin: 0 auto;
`;
