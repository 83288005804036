import React from 'react';
import styled from 'styled-components';
import {Modal, message} from 'antd';
import {getNewOutlet, getOutlet, useOutlet} from 'reconnect.js';
import GenericForm from 'rev.sdk.js/Generic/Form';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import * as AppActions from '../AppActions';
import * as AppActionsEx from '../AppActions/custom.js';
import AdminSelectUserCustomWidget from '../Generators/AdminResource/AdminSelectUserCustomWidget';
import AdminSelectCrossFeeOrderWidget from '../Generators/AdminResource/AdminSelectCrossFeeOrderWidget.js';
import {ModalCustomStyle} from '../AppContext/customStyledCss.js';

getNewOutlet('admin-stop-modal', null, {autoDelete: false});

export function showAdminStopModal(data) {
  getOutlet('admin-stop-modal').update(data);
}

function AdminStopModal(props) {
  const [data, setData] = useOutlet('admin-stop-modal');

  return (
    <Modal
      title="申請停止"
      footer={null}
      width={500}
      style={{padding: 10}}
      visible={!!data}
      onOk={() => {
        setData(null);
      }}
      onCancel={() => {
        setData(null);
      }}
      destroyOnClose={true}>
      <ModalContent {...data} setData={setData} />
    </Modal>
  );
}

function ModalContent(props) {
  const {record, clientCallbacks, setData} = props;
  const {user_id} = record;
  const [order, setOrder] = React.useState(null);

  const fetchLatestCrossFeeOrders = React.useCallback(async ({owner}) => {
    try {
      const orderResp = await JStorage.fetchDocuments(
        'order',
        {
          'items.0.product.name': '跨區會費',
          payment_status: 'success',
          owner,
        },
        ['-created'],
        {offset: 0, limit: 1},
        {items: 1, order_number: 1, buyer_name: 1},
      );

      if (orderResp.total === 0) {
        message.error('查無跨區會費。');
        return;
      }

      setOrder(orderResp.results[0]);
    } catch (err) {
      console.warn(err);
    }
  }, []);

  React.useEffect(() => {
    fetchLatestCrossFeeOrders({owner: user_id});
  }, [fetchLatestCrossFeeOrders, user_id]);

  const rjsfProps = {
    widgets: {
      'admin-select-user-custom-widget': AdminSelectUserCustomWidget,
      'admin-select-cross-fee-order-widget': AdminSelectCrossFeeOrderWidget,
    },
  };

  return (
    <Wrapper>
      <GenericForm
        rjsfProps={rjsfProps}
        instance={{
          user_id,
          order_number: order ? order.order_number : undefined,
        }}
        schema={{
          title: '',
          type: 'object',
          required: ['user_id', 'stop_date', 'leave_reason', 'order_number'],
          properties: {
            user_id: {
              type: 'string',
              title: '申請會員',
              readOnly: true,
            },
            stop_date: {
              type: 'string',
              title: '欲停止日期',
              format: 'date',
            },
            leave_reason: {
              type: 'string',
              title: '欲停止原因',
              default: 'C',
              enum: ['none', 'A', 'B', 'C', 'D', 'E'],
              enumNames: [
                '無',
                '自請退會',
                '決議退會',
                '停止執行職務',
                '病故',
                '許可證到期退會',
              ],
            },
            order_number: {
              type: 'string',
              title: '跨區會費訂單',
            },
            note: {
              type: 'string',
              title: '備註',
            },
          },
        }}
        uiSchema={{
          user_id: {
            'ui:widget': 'admin-select-user-custom-widget',
          },
          order_number: {
            'ui:widget': 'admin-select-cross-fee-order-widget',
          },
        }}
        onSubmit={async (formData, extValue) => {
          AppActions.setLoading(true);

          try {
            AppActionsEx.createStopRecord({
              user_id: formData.user_id,
              stop_date: formData.stop_date,
              leave_reason: formData.leave_reason,
              order_number: formData.order_number,
              note: formData.note,
            });
            message.success('停止紀錄創建成功，請至「會員停止紀錄」查看。');

            setData(null);
            AppActions.setLoading(false);
            await clientCallbacks.refresh();
          } catch (err) {
            console.warn(err);
            message.error('停止紀錄創建失敗。');
            AppActions.setLoading(false);
          }
        }}
      />
    </Wrapper>
  );
}

const Wrapper = styled(ModalCustomStyle)``;

export default AdminStopModal;
