import React from 'react';
import {Button, Input, Modal, Table, Tag, message} from 'antd';
import {getNewOutlet, getOutlet, useOutlet} from 'reconnect.js';
import styled from 'styled-components';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import * as Cart from 'rev.sdk.js/Actions/Cart';
import {ModalCustomStyle} from '../../AppContext/customStyledCss';

getNewOutlet('admin-search-annual-fee-order-modal', null, {autoDelete: false});

export function showAdminSearchAnnualFeeOrderModal(data) {
  getOutlet('admin-search-annual-fee-order-modal').update(data);
}

export default function AdminSearchAnnualFeeOrderModal(props) {
  const [data, setData] = useOutlet('admin-search-annual-fee-order-modal');

  const [users] = useOutlet('users');
  const [keyword, setKeyword] = React.useState(null);

  const [owner, setOwner] = React.useState(null);
  const [year, setYear] = React.useState(null);
  const [records, setRecords] = React.useState(null);
  const [pagination, setPagination] = React.useState({
    total: 0,
    pageSize: 4,
  });

  const columns = [
    {key: 'annual_year', title: '年度', dataIndex: 'annual_year', width: 100},
    {
      key: 'month',
      title: '起訖月份',
      dataIndex: 'month',
      width: 150,
      render: (_, item) => {
        return (
          <div>
            {item.start_month || 1}月 - {item.end_month || 12}月
          </div>
        );
      },
    },
    {key: 'buyer_name', title: '姓名', dataIndex: 'buyer_name', width: 150},
    {
      key: 'total',
      title: '金額',
      dataIndex: 'total',
      width: 150,
      render: (_, item) => {
        return <div>${item.total}</div>;
      },
    },
    {
      key: 'info',
      title: '常年會費資訊',
      dataIndex: 'info',
      render: (_, item) => {
        return (
          <div>
            <div>{item.order_number}</div>
            <span>{item.items[0].product.name} </span>
            <span>{generatePaymentStatusTag(item.payment_status)}</span>
          </div>
        );
      },
    },
    {
      key: '',
      title: '',
      dataIndex: '',
      width: 130,
      render: (_, item) => {
        return (
          <Button
            onClick={() => {
              data.onChange(item.order_number);
              onClose();
            }}>
            選擇
          </Button>
        );
      },
    },
  ];

  function onClose() {
    setKeyword(null);
    setYear(null);
    setOwner(null);
    setRecords(null);
    // _setSelectedRows([]);
    setData(null);
  }

  const fetchRecords = React.useCallback(
    async ({owners, year}) => {
      try {
        const query = {
          'items.0.product.name': {$regex: '常年會費'},
          payment_status: {$ne: 'success'},
        };

        if (Array.isArray(owners) && owners.length > 0) {
          query['owner'] = {$in: owners};
        }

        if (year) {
          query['annual_year'] = year;
        }

        const {total, results} = await JStorage.fetchDocuments(
          'checkout',
          query,
          ['created'],
          {pagination},
        );

        setRecords(results);
        setPagination({...pagination, total});
      } catch (err) {
        console.warn(err);
        message.error('發生錯誤');
      }
    },
    [pagination],
  );

  React.useEffect(() => {
    if (((data && data.owner) || (data && data.year)) && !records) {
      fetchRecords({owners: [owner], year});
    }

    if (data && data.name && !keyword) {
      setKeyword(data.name);
    }

    if (data && data.year && !year) {
      setYear(data.year);
    }

    if (data && data.owner && !year) {
      setOwner(data.owner);
    }
  }, [data, year, owner, keyword, users, records, fetchRecords]);

  return (
    <Modal
      visible={!!data}
      onCancel={onClose}
      footer={null}
      width={1000}
      closable={false}
      maskClosable={true}
      destroyOnClose={true}>
      <Wrapper>
        <h2 className="title">常年會費搜尋</h2>
        <div className="container">
          <Input
            addonBefore="年度"
            className="annual-year-input"
            value={year}
            onChange={(e) => setYear(e.target.value)}
          />
          <Input
            addonBefore="會員"
            style={{maxWidth: 300, width: '100%', marginRight: 10}}
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
          />
          <Button
            onClick={async () => {
              const targetUsersOwners = users
                .filter((user) => user.name.indexOf(keyword) > -1)
                .map((user) => user.owner);

              await fetchRecords({
                owners: targetUsersOwners,
                year: year,
              });
            }}>
            搜尋
          </Button>
        </div>

        <Table
          style={{marginBottom: 10}}
          scroll={{x: 500, y: 300}}
          columns={columns}
          dataSource={records}
        />
      </Wrapper>
    </Modal>
  );
}

function generatePaymentStatusTag(value) {
  const display = Cart.PAYMENT_STATUS_DISPLAY[value]?.label;

  if (value === 'success') {
    return <Tag color="success">{display}</Tag>;
  }

  return <Tag>{display}</Tag>;
}

const Wrapper = styled(ModalCustomStyle)`
  & > .title {
    margin-bottom: 10px;
  }

  & > .container {
    margin-bottom: 20px;
    display: flex;

    & > .annual-year-input {
      max-width: 130px;
      width: 100%;
      margin-right: 20px;

      .ant-input[disabled] {
        color: #000000;
      }
    }
  }
`;
