import {useOutlet, useOutletSetter} from 'reconnect.js';
import * as AppActions from '../../AppActions';
import * as User from 'rev.sdk.js/Actions/User';
import * as Cart from 'rev.sdk.js/Actions/Cart';
import {Menu, Tabs, message} from 'antd';
import React from 'react';
import {navigate} from 'gatsby';

function ProfileTabs(props) {
  const showResetPasswordModal = useOutletSetter('reset-password-modal');
  const [user] = useOutlet('user');
  const {activePath} = props;

  async function onTabClick(nextKey) {
    if (nextKey[0] === '/') {
      // this is a path, simply navigate to it
      await AppActions.navigate(nextKey);
      return;
    }

    if (nextKey === 'clear-cart') {
      AppActions.setLoading(true);
      try {
        await Cart.clearCart();
        AppActions.setLoading(false);
        message.success(AppActions.L('cart_clear_success'));
      } catch (err) {
        console.warn(err);
        AppActions.setLoading(false);
        message.error(AppActions.L('cart_clear_failure'));
      }
    } else if (nextKey === 'reset-password') {
      showResetPasswordModal({admin: false});
    } else if (nextKey === 'logout') {
      await User.logout();
      setTimeout(() => {
        AppActions.navigate('/');
      }, 100);
    }
  }

  return (
    <Tabs type="card" activeKey={activePath} onChange={onTabClick}>
      <Tabs.TabPane key="/profile" tab="個人資訊" />
      <Tabs.TabPane key="/profile/fees" tab={'會費繳納'} />
      <Tabs.TabPane key="/profile/orders" tab={AppActions.L('order_title')} />
      {/* FIXME: TBA hide ec entry temporarily */}
      {/* <Tabs.TabPane key="/profile/coupons" tab="我的優惠券" /> */}
      {/* <Tabs.TabPane key="clear-cart" tab={AppActions.L('cart_clear')} /> */}

      {user.data?.provider === 'default' && (
        <Tabs.TabPane key="reset-password" tab="重設密碼" />
      )}
      <Tabs.TabPane key="logout" tab="登出" />
    </Tabs>
  );
}

function ProfileMenu(props) {
  const [user] = useOutlet('user');
  const showResetPasswordModal = useOutletSetter('reset-password-modal');
  const {activePath} = props;
  const selectedKeys = [activePath ? activePath : 'info'];

  return (
    <Menu style={{width: 240}} selectedKeys={selectedKeys} mode="inline">
      <Menu.Item key="info" onClick={() => navigate('/profile')}>
        個人資訊
      </Menu.Item>
      <Menu.Item key="fees" onClick={() => navigate('/profile/fees')}>
        會費繳納
      </Menu.Item>
      <Menu.Item key="orders" onClick={() => navigate('/profile/orders')}>
        {AppActions.L('order_title')}
      </Menu.Item>

      {/* FIXME: TBA hide ec entry temporarily */}
      {/*
      <Menu.Item key="coupons" onClick={() => navigate('/profile/coupons')}>
        我的優惠券
      </Menu.Item> */}
      {/*
      <Menu.Item
        key="clear-cart"
        onClick={async () => {
          AppActions.setLoading(true);
          try {
            await Cart.clearCart();
            AppActions.setLoading(false);
            message.success(AppActions.L('cart_clear_success'));
          } catch (err) {
            console.warn(err);
            AppActions.setLoading(false);
            message.error(AppActions.L('cart_clear_failure'));
          }
        }}>
        {AppActions.L('cart_clear')}
      </Menu.Item> */}

      {user.data?.provider === 'default' && (
        <Menu.Item
          key="reset-password"
          onClick={() => showResetPasswordModal({admin: false})}>
          重設密碼
        </Menu.Item>
      )}
      <Menu.Item
        key={'logout'}
        onClick={() => {
          User.logout();
          setTimeout(async () => {
            await navigate('/');
          }, 100);
        }}>
        登出
      </Menu.Item>
    </Menu>
  );
}

export {ProfileTabs};
export default ProfileMenu;
