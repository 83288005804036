import React from 'react';
import {Modal, message} from 'antd';
import styled from 'styled-components';
import {getNewOutlet, getOutlet, useOutlet} from 'reconnect.js';
import GenericForm from 'rev.sdk.js/Generic/Form';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import * as AppActions from '../AppActions';
import AdminSelectUserCustomWidget from '../Generators/AdminResource/AdminSelectUserCustomWidget';
import {ModalCustomStyle} from '../AppContext/customStyledCss';

getNewOutlet('admin-order-cross-date-modal', null, {autoDelete: false});

export function showAdminOrderCrossDateModal(data) {
  getOutlet('admin-order-cross-date-modal').update(data);
}

function AdminOrderCrossDateModal(props) {
  const [data, setData] = useOutlet('admin-order-cross-date-modal');

  return (
    <Modal
      title="跨區填寫起迄"
      footer={null}
      width={500}
      style={{top: 20, padding: 0}}
      visible={!!data}
      onOk={() => {
        setData(null);
      }}
      onCancel={() => {
        setData(null);
      }}
      destroyOnClose={true}>
      <ModalContent {...data} setData={setData} />
    </Modal>
  );
}

function ModalContent(props) {
  const {order: data, setData} = props;

  const rjsfProps = {
    widgets: {
      'admin-select-user-custom-widget': AdminSelectUserCustomWidget,
    },
  };

  return (
    <ModalContentWrapper>
      <GenericForm
        rjsfProps={rjsfProps}
        instance={{
          owner: data.owner,
          total: data.total,
          start_date: data.items[0].config.extra_data?.start_date || '',
          end_date: data.items[0].config.extra_data?.end_date || '',
        }}
        schema={{
          title: '',
          type: 'object',
          required: [],
          properties: {
            owner: {
              type: 'string',
              title: '會員',
              readOnly: true,
            },
            total: {
              type: 'integer',
              title: '金額',
              readOnly: true,
            },
            start_date: {
              type: 'string',
              title: '執業開始日期',
              format: 'date',
            },
            end_date: {
              type: 'string',
              title: '執業結束日期',
              format: 'date',
            },
          },
        }}
        uiSchema={{
          owner: {
            'ui:widget': 'admin-select-user-custom-widget',
            'ui:options': {label: false},
          },
        }}
        onSubmit={async (formData, extValue) => {
          const {start_date, end_date} = formData;

          try {
            AppActions.setLoading(true);

            const nextItems = [...data.items];

            nextItems[0].config.extra_data = {
              ...nextItems[0].config.extra_data,
              start_date: start_date,
              end_date: end_date,
            };

            await JStorage.updateDocument(
              'checkout',
              {
                order_number: data.order_number,
              },
              {
                items: nextItems,
              },
            );

            if (data.order_id) {
              await JStorage.updateDocument(
                'order',
                {
                  order_number: data.order_number,
                },
                {
                  items: nextItems,
                },
              );
            }

            message.success('填寫成功');
            setData(null);
          } catch (err) {
            console.warn(err);
            message.error(`發生錯誤 ${err.response?.error || ''}`);
          } finally {
            AppActions.setLoading(false);
          }
        }}
      />
    </ModalContentWrapper>
  );
}

const ModalContentWrapper = styled(ModalCustomStyle)``;

export default AdminOrderCrossDateModal;
