import React from 'react';
import styled from 'styled-components';
import {useOutlet} from 'reconnect.js';
import {FacebookSquare} from '@styled-icons/boxicons-logos/FacebookSquare';
import * as NavUtil from '../../Utils/NavUtil';
import * as Config from '../../../data.json';

export default function SiteFooter(props) {
  useOutlet('footerConfig');
  const footerConfig = NavUtil.getFooterConfig(props);

  return (
    <Wrapper>
      <div className="container">
        <div className="contacts">
          <div className="items">
            <div className="item">
              <h1>
                <a
                  target="_blank"
                  href="https://www.facebook.com/TaipeiBar/?locale=zh_TW"
                  rel="noreferrer"
                  style={{marginBottom: 8, marginRight: 5, cursor: 'pointer'}}>
                  <FacebookSquare
                    size={38}
                    color="#000000"
                    style={{marginBottom: 5}}
                  />
                </a>
                {Config.site_name}
              </h1>
            </div>
            <div className="item">
              <div>{Config.address}</div>
              <div>{Config.contact_email}</div>
            </div>
            <div className="item">
              <div>電話：{Config.contact_tel}</div>
              <div>傳真：{Config.contact_fax}</div>
            </div>
          </div>
          <div className="copyright">
            <div>
              <span style={{marginRight: 5}}>
                {new Date().getFullYear()} Taipei Bar Association. All rights
                reserved. 版權所有
                {Config.site_name}
              </span>
              |
              <a
                href="/site-service-policy"
                rel="noreferrer"
                style={{marginLeft: 10}}>
                <span
                  style={{
                    fontWeight: 'bold',
                    color: '#000000',
                    cursor: 'pointer',
                  }}>
                  網站服務條款
                </span>
              </a>
            </div>
          </div>
        </div>
        <div className="apps">
          <div className="item">
            <a
              target="_blank"
              href="https://apps.apple.com/tw/app/%E5%8F%B0%E5%8C%97%E5%BE%8B%E5%B8%AB%E5%85%AC%E6%9C%83%E8%A1%8C%E5%8B%95app/id6470195138"
              rel="noreferrer">
              <img
                className="store"
                alt="apple"
                src="https://tba-revtel2-com-prod.s3.ap-northeast-1.amazonaws.com/btn-app-store-badge@3x-1680252026"
              />
            </a>
            <img
              className="qrcode"
              alt="apple-qucode"
              src="https://tba-revtel2-com-prod.s3.ap-northeast-1.amazonaws.com/tba-app-ios-qrcode-1705455712"
            />
          </div>
          <div className="item">
            <a
              target="_blank"
              href="https://play.google.com/store/apps/details?id=com.tba.newapp&pcampaignid=web_share"
              rel="noreferrer">
              <img
                className="store"
                alt="google"
                src="https://tba-revtel2-com-prod.s3.ap-northeast-1.amazonaws.com/btn-google-play-badge@3x-1680252085"
              />
            </a>
            <img
              className="qrcode"
              alt="android-qrcode"
              src="https://tba-revtel2-com-prod.s3.ap-northeast-1.amazonaws.com/tba-app-android-qrcode-1705455743"
            />
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin: 0 auto;
  padding: 20px;
  background-color: var(--primaryColor);

  & > .container {
    max-width: var(--containerMaxWidth);
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;

    & > div.contacts {
      flex: 1;
      margin: 20px;

      display: flex;
      flex-direction: column;
      justify-content: space-between;

      & > div.items {
        display: flex;
        flex-wrap: wrap;

        & > div.item {
          margin-right: 20px;
          max-width: 300px;
          font-weight: 500;
          font-size: 1.05rem;
        }
      }

      & > div.copyright {
        margin-top: 10px;
        font-size: 1rem;
      }
    }

    & > div.apps {
      & > div.item {
        margin: 20px;
      }

      & > div.item > a > img.store {
        width: 120px;
        margin-right: 20px;
      }

      & > div.item > img.qrcode {
        width: 40px;
      }
    }
  }
`;
