import React from 'react';
import styled from 'styled-components';
import {Modal, message} from 'antd';
import {getNewOutlet, useOutlet} from 'reconnect.js';
import GenericForm from 'rev.sdk.js/Generic/Form';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import * as AppActions from '../../AppActions';
import * as AppActionsEx from '../../AppActions/custom';
import AdminSelectCompanyWidget from '../../Generators/AdminResource/AdminSelectCompanyWidget';
import {ModalCustomStyle} from '../../AppContext/customStyledCss';

getNewOutlet('AdminCompanyBillingModal', null, {autoDelete: false});

export default function AdminCompanyBillingModal(props) {
  const [modalData, setModalData] = useOutlet('AdminCompanyBillingModal');
  const [user] = useOutlet('user');

  return (
    <Modal
      title={null}
      footer={null}
      bodyStyle={{padding: 0}}
      width={640}
      visible={modalData}
      onOk={() => {
        setModalData(null);
      }}
      onCancel={() => {
        setModalData(null);
      }}
      destroyOnClose={true}>
      {user && modalData && (
        <ModalContent
          modalData={modalData}
          setModalData={setModalData}
          user={user}
        />
      )}
    </Modal>
  );
}

function ModalContent(props) {
  const {modalData, setModalData} = props;
  const {clientCallbacks} = modalData;

  const rjsfProps = {
    widgets: {
      'admin-select-company-widget': AdminSelectCompanyWidget,
    },
  };

  return (
    <Wrapper>
      <h1>創建常年會費：事務所</h1>
      <GenericForm
        schema={{
          title: '',
          type: 'object',
          required: ['annual_year', 'company_id'],
          properties: {
            annual_year: {
              type: 'string',
              title: '年度',
            },
            company_id: {
              type: 'string',
              title: '事務所',
            },
            payment_status: {
              type: 'string',
              title: '付款狀態',
              default: 'pending',
              enum: ['pending', 'success'],
              enumNames: ['尚未付款', '付款成功'],
              readOnly: true,
            },
          },
        }}
        uiSchema={{
          company_id: {
            'ui:widget': 'admin-select-company-widget',
          },
        }}
        rjsfProps={rjsfProps}
        onSubmit={async (formData, extValue) => {
          AppActions.setLoading(true);
          try {
            const {annual_year, company_id} = formData;
            const company = await JStorage.fetchOneDocument('Company', {
              id: company_id,
            });

            if (company.is_company_billing) {
              await AppActionsEx.createAnnualFeeByCompany({
                annual_year,
                company_id,
              });
              message.success('創建成功');

              await clientCallbacks.refresh();
              AppActions.setLoading(false);
              setModalData(null);
            } else {
              message.error('該事務所未開啟「團繳常年會費」功能。');
              AppActions.setLoading(false);
            }
          } catch (err) {
            console.warn(err);
            message.error('創建失敗');
            AppActions.setLoading(false);
          }
        }}
      />
    </Wrapper>
  );
}

const Wrapper = styled(ModalCustomStyle)`
  padding: 20px;
`;
