import {getOutlet} from 'reconnect.js';
import * as tw from './tw';
import * as en from './en';
import * as jp from './jp';

const LocaleOutlet = getOutlet('locale');
const Dictionaries = {
  tw,
  en,
  jp,
};

function getLabel(key, defaultValue) {
  let value = null;
  try {
    value = Dictionaries[LocaleOutlet.getValue().lang].labels[key];
  } catch (ex) {
    // pass
  }

  if (value) {
    return value;
  } else if (defaultValue) {
    return defaultValue;
  }
  return key;
}

function getField(obj, field, locale) {
  if (!obj) {
    return '';
  }

  if (!locale) {
    locale = getOutlet('locale').getValue();
  }

  return obj[`${field}_${locale.lang}`] || obj[field];
}

export {getLabel, getField};
