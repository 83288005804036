export const FEE_PRODUCTS = [
  '63c4e3a2210ae6e7c69d9cac', //入會費
  '65040ab86878db7d11faf046', //外國法入會費
  '656003e5b0a24dcaeab6b6ff', //特別會員入會費
  '63c4e3e4210ae6e7c69d9cad', //常年會費
  '65040a896878db7d11faf045', //外國法常年會費
  '65548c9e6878db7d11faf14d', //特別會員常年會費
  '65e6b97217a61d7ad966b00f', //常年會費-拆單
  '6630a3d59fce043cc9f4ed74', //外國法常年會費-拆單
  '6630a3e09fce043cc9f4ed76', //特別會員常年會費-拆單
  '656596dafbef81b89d8ebb56', //常年會費-未繳(常年會費*)
  '6401664a2512ae8cbb2b1af0', //跨區會費
];

export const COURSE_INTERNAL_LABELS = ['在職進修', '採認進修', '專錄影片'];

export const ACTIVITY_INTERMAL_LABELS = [
  '座談研討會',
  '午間小品',
  '拜會',
  '接待',
  '律師節活動',
  '外部活動',
  '外租場地',
  '其他',
];

export const WORK_AROUND_PRODUCTS = [
  //TODO: remove 不動產系列子課程
  //不動產系列課程 18 堂 + 1
  '664ab18501180db66e7db323',
  '664ab39a3c3eb7dfae90713a',
  '664ab3f63c3eb7dfae90713b',
  '664ab42486346763adb8f973',
  '664ab59d3c3eb7dfae90713c',
  '664ab5d1f1ec41ae3421aa71',
  '664ab5f7f1ec41ae3421aa72',
  '664ab618f1ec41ae3421aa73',
  '664ab62e86346763adb8f974',
  '664ab65086346763adb8f975',
  '664ab6713c3eb7dfae90713d',
  '664ab68d16f2204e94383ad8',
  '664ab6aa16f2204e94383ad9',
  '664ab6d6f1ec41ae3421aa74',
  '664ab6f04cca688b7f26e788',
  '664ab779f1ec41ae3421aa75',
  '664ab7c9f65a254157cf9b8b',
  '664ab7f986346763adb8f976',
  //test-直播課程2
  '6674e4ba1cdc67fa9ce0efd7',
];
