import React from 'react';
import {Modal, Button, message} from 'antd';
import styled from 'styled-components';
import {getNewOutlet, getOutlet, useOutlet} from 'reconnect.js';
import GenericForm from 'rev.sdk.js/Generic/Form';
import * as Cart from 'rev.sdk.js/Actions/Cart';
import * as AppActions from '../AppActions';
import * as AppActionsEx from '../AppActions/custom';
import AdminSelectUserCustomWidget from '../Generators/AdminResource/AdminSelectUserCustomWidget';
import {ModalCustomStyle} from '../AppContext/customStyledCss';

const PAYMENT_SUBTYPE = [
  Cart.PAYMENT_SUBTYPE.credit,
  Cart.PAYMENT_SUBTYPE.barcode,
  Cart.PAYMENT_SUBTYPE.cvs,
  Cart.PAYMENT_SUBTYPE.offline,
];

getNewOutlet('admin-order-split-modal', null, {autoDelete: false});

export function showAdminOrderSplitModal(data) {
  getOutlet('admin-order-split-modal').update(data);
}

function AdminOrderSplitModal(props) {
  const [data, setData] = useOutlet('admin-order-split-modal');

  return (
    <Modal
      title="拆成兩筆"
      footer={null}
      width={650}
      style={{top: 20, padding: 0}}
      visible={!!data}
      onOk={() => {
        setData(null);
      }}
      onCancel={() => {
        setData(null);
      }}
      destroyOnClose={true}>
      <ModalContent {...data} setData={setData} />
    </Modal>
  );
}

function ModalContent(props) {
  const {order: data, setData} = props;

  const rjsfProps = {
    widgets: {
      'admin-select-user-custom-widget': AdminSelectUserCustomWidget,
    },
  };

  return (
    <Wrapper>
      <GenericForm
        rjsfProps={rjsfProps}
        instance={{
          order_number: data.order_number,
          owner: data.owner,
          orders: [
            {
              start_month: data.start_month || 1,
              end_month: data.end_month || 12,
              receivable: data.total,
            },
          ],
        }}
        schema={{
          title: '',
          type: 'object',
          required: ['owner', 'payment_subtype'],
          properties: {
            owner: {
              type: 'string',
              title: '會員',
              readOnly: true,
            },
            payment_subtype: {
              type: 'string',
              title: '付款方式',
              default: PAYMENT_SUBTYPE[0],
              enum: PAYMENT_SUBTYPE,
              enumNames: PAYMENT_SUBTYPE.map(
                (subtype) => Cart.PAYMENT_SUBTYPE_DISPLAY[subtype]?.label,
              ),
            },
            payment_offline_method: {
              type: 'string',
              title: '線下收款方式',
              readOnly: true,
              enum: ['', '郵政劃撥', '傳真刷卡', '現金', 'ATM轉帳匯款'],
            },
            orders: {
              type: 'array',
              title: '拆成兩單',
              maxItems: 2,
              minItems: 2,
              items: {
                type: 'object',
                required: ['start_month', 'end_month', 'receivable'],
                properties: {
                  start_month: {
                    type: 'integer',
                    title: '開月',
                  },
                  end_month: {
                    type: 'integer',
                    title: '結月',
                  },
                  offline_date: {
                    type: 'string',
                    title: '收款日期',
                    format: 'date',
                  },
                  receivable: {
                    type: 'integer',
                    title: '應收',
                  },
                  is_paid: {
                    type: 'boolean',
                    title: '已繳款',
                  },
                },
              },
            },
          },
          dependencies: {
            payment_subtype: {
              oneOf: [
                {
                  required: ['payment_offline_method'],
                  properties: {
                    payment_subtype: {
                      enum: ['offline'],
                    },
                    payment_offline_method: {
                      readOnly: false,
                    },
                  },
                },
              ],
            },
          },
        }}
        uiSchema={{
          owner: {
            'ui:widget': 'admin-select-user-custom-widget',
            'ui:options': {label: false},
          },
        }}
        onSubmit={async (formData, extValue) => {
          const {
            order_number,
            payment_subtype,
            payment_offline_method,
            orders,
          } = formData;

          if (!orders || orders.length <= 1) {
            message.warning('拆單不能小於 1 張');
            return;
          }

          try {
            AppActions.setLoading(true);

            await AppActionsEx.adminSplitOrder({
              order_number: order_number,
              start_month: orders[0].start_month,
              end_month: orders[0].end_month,
              payment_subtype,
              payment_offline_method,
              offline_date_1: orders[0].offline_date,
              offline_date_2: orders[1].offline_date,
              receivable_1: orders[0].receivable,
              receivable_2: orders[1].receivable,
              is_paid_1: orders[0].is_paid,
              is_paid_2: orders[1].is_paid,
            });

            message.success('拆單成功');
            setData(null);
          } catch (err) {
            console.warn(err);
            message.error(`發生錯誤 ${err.response?.error || ''}`);
          } finally {
            AppActions.setLoading(false);
          }
        }}
      />
    </Wrapper>
  );
}

const Wrapper = styled(ModalCustomStyle)``;

export default AdminOrderSplitModal;
