import {message} from 'antd';
import * as AppActions from '../AppActions';

export function generateExportRecords({records, signRecords, viewtimeRecords}) {
  const nextRecords = [];

  for (let record of records) {
    for (let uvh of record.user_view_histories) {
      const index = nextRecords.findIndex((r) => r.user_id === uvh.user_id);

      if (index === -1) {
        nextRecords.push({
          user_id: uvh.user_id,
          name: uvh.name,
          state: uvh.state,
          substate: uvh.substate,
          id_card_number: uvh.id_card_number,
          [record.id]: uvh.user_time,
          [`${record.id}_user_backup`]: uvh.user_backup,
        });
      } else {
        nextRecords[index] = {
          ...nextRecords[index],
          [record.id]: uvh.user_time,
          [`${record.id}_user_backup`]: uvh.user_backup,
        };
      }
    }
  }

  for (let sign_record of signRecords) {
    for (let ush of sign_record.users_sign_histories) {
      const index = nextRecords.findIndex((r) => r.user_id === ush.owner);

      if (index === -1) {
        nextRecords.push({
          user_id: ush.owner,
          name: ush.name,
          state: ush.state,
          substate: ush.substate,
          id_card_number: ush.id_card_number,
          [sign_record.id]: ush.created,
        });
      } else {
        nextRecords[index] = {
          ...nextRecords[index],
          [sign_record.id]: ush.created,
        };
      }
    }
  }

  for (let viewtimeRecord of viewtimeRecords) {
    const index = nextRecords.findIndex(
      (r) => r.user_id === viewtimeRecord.user_id,
    );

    if (index === -1) {
      nextRecords.push({
        user_id: viewtimeRecord.user_id,
        name: viewtimeRecord.name,
        state: viewtimeRecord.state,
        substate: viewtimeRecord.substate,
        id_card_number: viewtimeRecord.id_card_number,
        played: viewtimeRecord.played,
        start: viewtimeRecord.start,
      });
    } else {
      nextRecords[index] = {
        ...nextRecords[index],
        played: viewtimeRecord.played,
        start: viewtimeRecord.start,
      };
    }
  }

  return nextRecords;
}

export function generateExportRecordsToAoa({
  product,
  nextRecords,
  recordIds,
  recordTitles,
  signRecordTitles,
  signRecordIds,
  viewtimeRecordTitles,
  calcViewTimes = null,
  calcConnectTimes = null,
  calcSignCounts = null,
}) {
  const producNameRow = product ? product.name : '';

  const productSessionRow =
    product &&
    product.session &&
    Object.keys(product.session).length &&
    product.session.date
      ? `\n${product.session.date} ${product.session.start_time}-${product.session.end_time}`
      : '';

  const productTbaHoursRow = !!product.tba_hours
    ? `\n進修時數：${product.tba_hours} 小時`
    : '';

  const calcSignCountsRow =
    calcSignCounts !== null ? `\n設定點名次數試算：${calcSignCounts} 次` : '';

  const calcConnectTimesRow =
    calcConnectTimes !== null
      ? `\n設定連線時長試算：${calcConnectTimes} 分鐘`
      : '';

  const calcViewTimesRow =
    calcViewTimes !== null ? `\n設定觀看時長試算：${calcViewTimes} 分鐘` : '';

  return [
    [
      `${producNameRow}${productSessionRow}${productTbaHoursRow}${calcViewTimesRow}${calcConnectTimesRow}${calcSignCountsRow}`,
      ...recordTitles.map((_) => ''),
      '',
      ...signRecordTitles.map((_) => ''),
      '',
      '',
    ],
    [
      '',
      ...viewtimeRecordTitles,
      ...recordTitles,
      '合計',
      ...signRecordTitles,
      '合計',
      '已出席',
    ],
    ...nextRecords.map((r) => {
      const userInfo = `${r.id_card_number || ''} ${r.name || ''}`;

      const start = r.start ? `${new Date(r.start).toLocaleString('sv')}` : '';
      const played = (() => {
        if (r.played) {
          const minutes = parseInt(r.played / 1000 / 60);
          const seconds = parseInt((r.played % (1000 * 60)) / 1000);

          return `${minutes} 分 ${seconds} 秒`;
        }

        return '';
      })();

      const _records = recordIds.map((id) => {
        let text = r[id]
          ? (() => {
              const minutes = parseInt(r[id] / 1000 / 60);
              const seconds = parseInt((r[id] % (1000 * 60)) / 1000);
              const timestamp = r[`${id}_user_backup`]
                .sort((a, b) => a.time - b.time)
                .filter((item) => item.status === 'connected')[0]?.time;

              const start = timestamp
                ? new Date(timestamp).toLocaleString('sv')
                : '';

              return `${start}\n${minutes} 分 ${seconds} 秒`;
            })()
          : '';

        return text;
      });

      const _signRecords = signRecordIds.map((id) => {
        let text = r[id] ? 'v' : '';

        return text;
      });

      const recordsTotal = (() => {
        let text = '';

        const targetIds = recordIds.filter((id) => !!r[id]);
        const total = targetIds.reduce((acc, cur) => acc + r[cur], 0);

        if (total > 0) {
          const minutes = parseInt(total / 1000 / 60);
          const seconds = parseInt((total % (1000 * 60)) / 1000);

          text = `${minutes} 分 ${seconds} 秒`;
        }

        return text;
      })();

      const signRecordsTotal = (() => {
        let text = '';

        const targetIds = signRecordIds.filter((id) => !!r[id]);

        const total = targetIds.length;

        if (total > 0) {
          text = `${total} 次`;
        }

        return text;
      })();

      const isAttend = (() => {
        let text = '';

        if (calcViewTimes || calcSignCounts || calcConnectTimes) {
          let isAttendConnectTimes = false;
          let isAttendSignCounts = false;
          let isAttendViewtimes = false;

          const connectTargetIds = recordIds.filter((id) => !!r[id]);
          const connectTimeTotal = connectTargetIds.reduce(
            (acc, cur) => acc + r[cur],
            0,
          );

          const signCountTargetIds = signRecordIds.filter((id) => !!r[id]);
          const signCountTotal = signCountTargetIds.length;

          isAttendViewtimes = (r.played || 0) >= calcViewTimes * 1000 * 60;
          isAttendConnectTimes =
            connectTimeTotal >= calcConnectTimes * 1000 * 60;
          isAttendSignCounts = signCountTotal >= calcSignCounts;

          if (isAttendViewtimes && isAttendConnectTimes && isAttendSignCounts) {
            text = '是';
          } else {
            text = '否';
          }
        }

        return text;
      })();

      return [
        userInfo,
        start,
        played,
        ..._records,
        recordsTotal,
        ..._signRecords,
        signRecordsTotal,
        isAttend,
      ];
    }),
  ];
}

export function generateExportWorkSheet(ws) {
  const cells = Object.keys(ws);

  //title
  const titles = cells.filter(
    (cell) => cell.length === 2 && cell.indexOf('1') > -1,
  );

  //header
  const headers = cells.filter(
    (cell) => cell.length === 2 && cell.indexOf('2') > -1,
  );

  const excludeCells = ['!cols', '!merges', '!ref', ...titles];

  for (let cell of cells) {
    if (!excludeCells.includes(cell)) {
      ws[cell].s = {
        alignment: {
          wrapText: true,
          vertical: 'center',
        },
      };
    }
  }

  ws['!merges'] = [{s: {r: 0, c: 0}, e: {r: 0, c: titles.length - 1}}];
  ws['!cols'] = headers.map((cell) => ({wpx: 150}));
}

export async function generateExcelFile(records, updateWorkSheet) {
  if (!window.XLSX) {
    console.log('no XLSX');
    return;
  }

  try {
    AppActions.setLoading(true);

    const wb = window.XLSX.utils.book_new();
    const ws = window.XLSX.utils.aoa_to_sheet(records);

    if (typeof updateWorkSheet === 'function') {
      updateWorkSheet(ws);
    }

    window.XLSX.utils.book_append_sheet(wb, ws, '試算報表');

    const wbout = window.XLSX.write(wb, {
      bookType: 'xlsx',
      bookSST: false,
      type: 'array',
      cellStyles: true,
      bookImages: true,
    });

    const objUrl = URL.createObjectURL(
      new Blob([wbout], {type: 'application/octet-stream'}),
    );
    await AppActions.delay(600);
    message.success('成功創建下載連結');
    return objUrl;
  } catch (err) {
    console.warn(err);
    message.error('發生錯誤');
  } finally {
    AppActions.setLoading(false);
  }
}

export function generateAttendMembersData({
  nextRecords,
  records,
  signRecords,
  recordIds,
  signRecordIds,
  calcViewTimes,
  calcConnectTimes,
  calcSignCounts,
}) {
  if (calcViewTimes || calcConnectTimes || calcSignCounts) {
    const attendUsers = [];

    for (let i = 0; i < nextRecords.length; i++) {
      const r = nextRecords[i];

      let isAttendViewtimes = false;
      let isAttendConnectTimes = false;
      let isAttendSignCounts = false;

      const connectTargetIds = recordIds.filter((id) => !!r[id]);
      const connectTimeTotal = connectTargetIds.reduce(
        (acc, cur) => acc + r[cur],
        0,
      );

      const signCountTargetIds = signRecordIds.filter((id) => !!r[id]);
      const signCountTotal = signCountTargetIds.length;

      const viewtimeTotal = r.played ? r.played : 0;

      isAttendViewtimes = viewtimeTotal >= calcViewTimes * 1000 * 60;
      isAttendConnectTimes = connectTimeTotal >= calcConnectTimes * 1000 * 60;
      isAttendSignCounts = signCountTotal >= calcSignCounts;

      if (isAttendViewtimes && isAttendConnectTimes && isAttendSignCounts) {
        attendUsers.push({
          owner: r.user_id,
          name: r.name,
          state: r.state,
          substate: r.substate,
          is_attend: true,
        });
      }
    }

    return attendUsers;
  }

  return [];
}
