import React from 'react';
import * as hello from './hello';
import * as userBillingCall from './user-billing-call';
import * as userExpiredCall from './user-expired-call';
import * as userBirthday from './user-birthday';
import * as Ant from 'antd';

const AllMsgTemplates = [
  {name: '', label: '無', model: null},
  // {name: 'hello', label: '測試用範本', model: hello},
  {
    name: 'user-billing-call',
    label: '檢附常年會費電子帳單',
    model: userBillingCall,
  },
  {
    name: 'user-expired-call',
    label: '準會員到期通知',
    model: userExpiredCall,
  },
  {
    name: 'user-birthday',
    label: '生日祝賀',
    model: userBirthday,
  },
];

function MsgTemplateParamEditor(props) {
  const {type, params, setParams} = props;
  const [showPreview, setShowPreview] = React.useState(false);
  const template = AllMsgTemplates.find((it) => it.name === type);

  if (!template) {
    return <h3>No such template</h3>;
  }

  const model = template.model;
  const fields = model.msgParams();

  return (
    <div style={{padding: '20px 0'}}>
      <h3>設定參數</h3>
      {fields.map((it) => {
        return (
          <div
            key={it.name}
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginBottom: 10,
            }}>
            <label>{it.label}</label>
            <Ant.Input
              value={params[it.name]}
              onChange={(e) => {
                setParams({...params, [it.name]: e.target.value});
              }}
            />
          </div>
        );
      })}

      <div style={{display: 'flex', alignItems: 'center', paddingTop: 20}}>
        <div style={{flex: 1, height: 1, backgroundColor: '#ccc'}} />
        <Ant.Button onClick={() => setShowPreview(!showPreview)} size="small">
          {showPreview ? '關閉預覽' : '開啟預覽'}
        </Ant.Button>
        <div style={{flex: 1, height: 1, backgroundColor: '#ccc'}} />
      </div>
      {showPreview && (
        <div dangerouslySetInnerHTML={{__html: model.msgContent(params)}} />
      )}
    </div>
  );
}

function generateMsgHtml(type, params) {
  const template = AllMsgTemplates.find((it) => it.name === type);

  if (!template) {
    console.warn('MessageTemplates.generateHtml: cannot find template');
    return '';
  }

  const model = template.model;
  return model.msgContent(params);
}

export {AllMsgTemplates, MsgTemplateParamEditor, generateMsgHtml};
