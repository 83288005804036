import React from 'react';
import styled from 'styled-components';
import {Modal, message} from 'antd';
import {getNewOutlet, useOutlet} from 'reconnect.js';
import GenericForm from 'rev.sdk.js/Generic/Form';
import * as AppActions from '../../AppActions';
import * as AppActionsEx from '../../AppActions/custom.js';
import {ModalCustomStyle} from '../../AppContext/customStyledCss.js';

getNewOutlet('AdminUserBillingModal', null, {autoDelete: false});

export default function AdminUserBillingModal(props) {
  const [modalData, setModalData] = useOutlet('AdminUserBillingModal');
  const [user] = useOutlet('user');

  return (
    <Modal
      title={null}
      footer={null}
      bodyStyle={{padding: 0}}
      width={640}
      visible={modalData}
      onOk={() => {
        setModalData(null);
      }}
      onCancel={() => {
        setModalData(null);
      }}
      destroyOnClose={true}>
      {user && modalData && (
        <ModalContent
          modalData={modalData}
          setModalData={setModalData}
          user={user}
        />
      )}
    </Modal>
  );
}

function ModalContent(props) {
  const {modalData, setModalData} = props;
  const {clientCallbacks} = modalData;

  const rjsfProps = {
    widgets: {},
  };

  return (
    <Wrapper>
      <h1>開徵常年會費：會員</h1>
      <GenericForm
        schema={{
          title: '',
          type: 'object',
          required: ['annual_year'],
          properties: {
            annual_year: {
              type: 'string',
              title: '年度',
            },
          },
        }}
        uiSchema={{}}
        rjsfProps={rjsfProps}
        onSubmit={async (formData, extValue) => {
          const resp = window.confirm(
            `確認開徵「${formData.annual_year}年度」嗎？`,
          );

          if (resp) {
            AppActions.setLoading(true);
            try {
              const {annual_year} = formData;
              await AppActionsEx.createAnnualFeeByUsers({
                annual_year,
              });
              message.success('創建成功');
              clientCallbacks.refresh();
              AppActions.setLoading(false);
              setModalData(null);
            } catch (err) {
              console.warn(err);
              message.error('創建失敗');
              AppActions.setLoading(true);
            }
          }
        }}
      />
    </Wrapper>
  );
}

const Wrapper = styled(ModalCustomStyle)`
  padding: 20px;
`;
