import React from 'react';
import styled from 'styled-components';
import {getNewOutlet, getOutlet, useOutlet} from 'reconnect.js';
import {Modal} from 'antd';
import GenericForm from 'rev.sdk.js/Generic/Form';
import AdminDateTimeWidget from '../Generators/AdminResource/AdminDateTimeWidget';
import * as AppActions from '../AppActions';
import {ModalCustomStyle} from '../AppContext/customStyledCss';
import {
  AdminAddressZipCodeWidget,
  AdminAddressCityWidget,
} from '../Generators/AdminResource/AdminAddressWidget';

getNewOutlet('AdminDescriptionsItemModal', null, {autoDelete: false});

export function showAdminDescriptionsItemModal(data) {
  getOutlet('AdminDescriptionsItemModal').update(data);
}

function AdminDescriptionsItemModal(props) {
  const [data, setData] = useOutlet('AdminDescriptionsItemModal');

  return (
    <Modal
      title={null}
      footer={null}
      bodyStyle={{padding: 0}}
      width={640}
      visible={data}
      onOk={() => {
        setData(null);
      }}
      onCancel={() => {
        setData(null);
      }}>
      <ModalContent {...data} setData={setData} />
    </Modal>
  );
}

function ModalContent(props) {
  const {
    is_created,
    record,
    index,
    formData,
    schema,
    uiSchema,
    onChange,
    setData,
  } = props;

  const rjsfProps = {
    widgets: {
      'admin-date-time-widget': AdminDateTimeWidget,
      'admin-address-zip-code-widget': AdminAddressZipCodeWidget,
      'admin-address-city-widget': AdminAddressCityWidget,
    },
  };

  return (
    <Wrapper>
      <h2>
        {is_created ? '新增' : '修改'}
        {uiSchema.title}
      </h2>
      <GenericForm
        rjsfProps={rjsfProps}
        instance={record}
        schema={{
          title: '',
          type: 'object',
          properties: {...schema.items.properties},
        }}
        uiSchema={{...uiSchema.items}}
        onSubmit={(_formData) => {
          const nextValues = [...formData];

          if (is_created) {
            nextValues.push(_formData);
          } else {
            nextValues[index] = {..._formData};
          }

          onChange(nextValues);
          setData(null);
          AppActions.setLoading(false);
        }}
      />
    </Wrapper>
  );
}

const Wrapper = styled(ModalCustomStyle)`
  padding: 20px;
`;

export default AdminDescriptionsItemModal;
