import React from 'react';
import styled from 'styled-components';
import {Button} from 'antd';
import {useOutlet} from 'reconnect.js';
import {showAdminSearchStaffModal} from '../../Components/AdminSearchStaffModal';

export default function AdminSelectStaffWidget(props) {
  const [staffs] = useOutlet('staffs');
  const [selectedStaff, setSelectedStaff] = React.useState(null);

  //rjsf write "readOnly", but props get "readonly".
  const readonly = props.readonly === true || props.readOnly === true;

  React.useEffect(() => {
    if (props.value) {
      setSelectedStaff(staffs.find((staff) => staff.id === props.value));
    }
  }, [props.value, staffs]);

  return (
    <Wrapper>
      <div>
        {!props.value
          ? '尚未設定工作人員'
          : !selectedStaff
          ? props.value
          : `${selectedStaff.name} ${selectedStaff.group || ''} ${
              selectedStaff.role || ''
            }`}
      </div>

      {!readonly && (
        <>
          <Button
            onClick={() => {
              props.onChange('');
              setSelectedStaff(null);
            }}
            style={{marginRight: 10}}>
            清除
          </Button>
          <Button
            type="primary"
            onClick={() =>
              showAdminSearchStaffModal({
                type: props.uiSchema.type,
                onChange: (id) => {
                  props.onChange(id);
                },
              })
            }>
            搜尋
          </Button>
        </>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 14px;
  border: 1px solid var(--adminBorderColor);
  border-radius: 4px;
  padding: 15px;

  & > div {
    margin-right: 15px;
    letter-space: 1.5;
  }
`;
