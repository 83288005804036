import React from 'react';
import {Button, Modal, Table, message} from 'antd';
import {getNewOutlet, getOutlet, useOutlet} from 'reconnect.js';
import styled from 'styled-components';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import * as Cart from 'rev.sdk.js/Actions/Cart';
import {ModalCustomStyle} from '../../AppContext/customStyledCss';

getNewOutlet('admin-search-cross-fee-order-modal', null, {autoDelete: false});

export function showAdminSearchCrossFeeOrderModal(data) {
  getOutlet('admin-search-cross-fee-order-modal').update(data);
}

export default function AdminSearchCrossFeeOrderModal(props) {
  const [data, setData] = useOutlet('admin-search-cross-fee-order-modal');
  const [keyword, setKeyword] = React.useState(null);
  const [records, setRecords] = React.useState(null);
  const [pagination, setPagination] = React.useState({
    total: 0,
    pageSize: 4,
  });

  const columns = [
    {
      key: 'order_number',
      title: '訂單流水號',
      dataIndex: 'order_number',
    },
    {key: 'buyer_name', title: '姓名', dataIndex: 'buyer_name', width: 150},
    {
      key: 'payment_status',
      title: '付款狀態',
      dataIndex: 'payment_status',
      render: (_, item) => (
        <div>{Cart.PAYMENT_STATUS_DISPLAY[item.payment_status]?.label}</div>
      ),
    },
    {
      key: 'info',
      title: '跨區資訊',
      dataIndex: 'info',
      width: 300,
      render: (_, item) => {
        return (
          <div>
            <div>
              {item.items[0].product.name}{' '}
              {item.items[0].config.variants[0].choice} ${item.total}
            </div>
            <div>
              執業開始日期：{item.items[0].config.extra_data.start_date}
            </div>
            <div>執業結束日期：{item.items[0].config.extra_data.end_date}</div>
          </div>
        );
      },
    },
    {
      key: '',
      title: '',
      dataIndex: '',
      width: 130,
      render: (_, item) => {
        return (
          <Button
            onClick={() => {
              data.onChange(item.order_number);
              onClose();
            }}>
            選擇
          </Button>
        );
      },
    },
  ];

  function onClose() {
    setKeyword(null);
    setRecords(null);
    // _setSelectedRows([]);
    setData(null);
  }

  React.useEffect(() => {
    async function fetchRecordsByOwner(owner) {
      try {
        const {total, results} = await JStorage.fetchDocuments(
          'order',
          {
            'items.0.product.name': '跨區會費',
            // payment_status: 'success',
            owner,
          },
          ['-created'],
          {pagination},
        );

        setRecords(results);
        setPagination({...pagination, total});
      } catch (err) {
        console.warn(err);
        message.error('發生錯誤');
      }
    }

    if (data?.owner && !records) {
      fetchRecordsByOwner(data.owner);
    }
  }, [data?.owner, pagination, records]);

  return (
    <Modal
      visible={!!data}
      onCancel={onClose}
      footer={null}
      width={1000}
      closable={false}
      maskClosable={true}
      destroyOnClose={true}>
      <Wrapper>
        <Table
          style={{marginBottom: 10}}
          scroll={{x: 500, y: 300}}
          columns={columns}
          dataSource={records}
        />
      </Wrapper>
    </Modal>
  );
}

const Wrapper = styled(ModalCustomStyle)``;
