import styled from 'styled-components';
import React from 'react';
import {useOutlet, useOutletSetter} from 'reconnect.js';
import * as User from 'rev.sdk.js/Actions/User';
import {Layout, Menu, message, Result} from 'antd';
import {withLoginRequired} from 'rev.sdk.js/Components/LoginRequired';
import {FileUploadModal} from '../../Utils/FileUploaderUtil';
import useAdminLayoutInit from '../../Hooks/useAdminLayoutInit';
import SiteNavBar from '../SiteNavBar';
import LoginRequired from '../LoginRequired';
import AdminCustomOrderModal, {
  showAdminCustomOrderModal,
} from '../AdminCustomOrderModal';
import AdminSearchUserModal from '../AdminSearchUserModal';
import AdminSearchProductModal from '../AdminSearchProductModal';
import AdminSearchArticleModal from '../AdminSearchArticleModal';
import AdminProductStockModal from '../AdminProductStockModal';
import AdminMultiOrderModal from '../AdminMutliOrderModal';
import AdminMessageEditorModal from '../AdminMessageEditorModal';
import AdminSearchOrderFixedButton from '../AdminSearchOrderFixedButton';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import * as AppActions from '../../AppActions';
import {getStaffValue} from '../../Utils/StaffUtil';
import * as CustomAdminLayout from '../../Utils/CustomAdminLayout';
import Config from '../../../data.json';
import permissionRequired, {
  appendSlashSuffix,
} from '../../Utils/PermissionRequiredUtil';

function getSelectedMenuKey() {
  if (typeof window === 'undefined') {
    return '';
  }

  const pathname = window.location.pathname;
  if (pathname[pathname.length - 1] !== '/') {
    return pathname + '/';
  }
  return pathname;
}

function AdminLayout(props) {
  const {children, location} = props;
  const [dimension] = useOutlet('dimension');
  const showResetPasswordModal = useOutletSetter('reset-password-modal');
  const [initialized, setInitialized] = React.useState(false);
  const [showMobileMenu, setShowMobileMenu] = React.useState(false);
  const mobile = !dimension.rwd || dimension.rwd === 'mobile';
  const [user] = useOutlet('user');
  const [fileUploadModal, setFileUploadModal] = useOutlet('file-upload-modal');
  const Routes = CustomAdminLayout.getAdminRoutes();
  const {extraElement} = useAdminLayoutInit({location});
  const canRead = permissionRequired(
    `${appendSlashSuffix(location.pathname)}_read`,
  );

  const shouldShowOrderSearchButton = React.useMemo(() => {
    if (location.pathname.indexOf('/admin/orders') === 0) {
      return true;
    }
    return false;
  }, [location]);

  const getMenuItemProps = (path) => {
    if (path === 'admin-custom-order') {
      return {
        onClick: () => showAdminCustomOrderModal(true),
      };
    } else if (path === 'image-uploader') {
      return {
        onClick: () => setFileUploadModal({preview: true, accept: 'image/*'}),
      };
    } else if (path === 'file-uploader') {
      return {
        onClick: () =>
          setFileUploadModal({preview: false, accept: 'file_extension'}),
      };
    } else if (path === 'reset-password') {
      return {
        onClick: () => showResetPasswordModal({admin: true}),
      };
    } else if (path === 'logout') {
      return {
        onClick: async () => {
          await User.logout(true);
          AppActions.navigate('/admin');
        },
      };
    }

    return {
      selected: path === location.pathname,
      onClick: () => AppActions.navigate(path),
    };
  };

  React.useEffect(() => {
    setShowMobileMenu(false);
  }, [location]);

  React.useEffect(() => {
    const _initializeSiteConfig = async () => {
      AppActions.setLoading(true);
      try {
        let results = await JStorage.fetchDocuments('site', {}, null, null);
        const {siteConfig} = Config;

        for (let key in siteConfig) {
          let name = siteConfig[key].value;
          let initial_data = siteConfig[key].initial_data || {};

          if (!results.find((r) => r.name === name)) {
            await JStorage.createDocument('site', {name, ...initial_data});
          }
        }
      } catch (ex) {
        message.warn('設定初始化失敗');
      }
      AppActions.setLoading(false);
      setInitialized(true);
    };

    if (!initialized && user) {
      _initializeSiteConfig();
    }
  }, [initialized, user]);

  const siderStyle = {
    overflow: 'auto',
    height: '100vh',
    position: 'fixed',
    boxShadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
    left: 0,
  };

  if (mobile) {
    siderStyle.left = showMobileMenu ? 0 : -200;
  }

  return (
    <Layout>
      <Layout style={{marginLeft: mobile ? 0 : 200, backgroundColor: 'white'}}>
        <Wrapper id="rev-admin-layout">
          {initialized &&
            (!canRead ? (
              <Result
                status="warning"
                title="您沒有瀏覽權限"
                subTitle="如需開啟權限，請洽系統管理員 Admin"
              />
            ) : (
              children
            ))}
        </Wrapper>
      </Layout>

      <Layout.Sider theme="light" style={siderStyle}>
        <AppHeader
          style={{cursor: 'pointer'}}
          onClick={() => AppActions.navigate('/admin')}
        />

        <div style={{marginLeft: 15, marginBottom: 10}}>
          <div>單位：{getStaffValue(user.username, 'group', user.group)}</div>
          <div>職稱：{getStaffValue(user.username, 'role', user.role)}</div>
          <div>姓名：{getStaffValue(user.username, 'name', user.name)}</div>
        </div>

        <Menu mode="inline" selectedKeys={[getSelectedMenuKey()]}>
          {Routes.map(({name, path, subroutes = []}, idx) => {
            if (subroutes.length === 0) {
              return (
                <Menu.Item key={path} {...getMenuItemProps(path)}>
                  {name}
                </Menu.Item>
              );
            }
            return (
              <Menu.SubMenu key={path} title={name}>
                {subroutes
                  .filter((subroute) => subroute)
                  .map(({name, path}) => (
                    <Menu.Item key={path} {...getMenuItemProps(path)}>
                      {name}
                    </Menu.Item>
                  ))}
              </Menu.SubMenu>
            );
          })}
        </Menu>
      </Layout.Sider>

      <AdminSearchUserModal />
      <AdminSearchProductModal />
      <AdminSearchArticleModal />
      <AdminCustomOrderModal />
      <AdminProductStockModal />
      <AdminMultiOrderModal />
      <AdminMessageEditorModal />
      <FileUploadModal />

      {extraElement}

      {/*shouldShowOrderSearchButton && (
        <AdminSearchOrderFixedButton offsetBottom={mobile ? 110 : 32} />
      )*/}

      {mobile && (
        <MobileMainMenu
          onClick={() => {
            setShowMobileMenu(!showMobileMenu);
          }}>
          選單
        </MobileMainMenu>
      )}
    </Layout>
  );
}

function AppHeader(props) {
  const {style = {}, onClick} = props;

  return (
    <AppHeaderWrapper style={style} onClick={onClick}>
      <figure>
        <img src={Config.favicon} alt="site icon" />
      </figure>

      <div className="content">
        <p>{Config.site_name}</p>
        <p style={{color: '#ccc'}}>Dashboard</p>
      </div>
    </AppHeaderWrapper>
  );
}

const Wrapper = styled.div`
  &#rev-admin-layout button.array-item-add {
    & span:first-child {
      transform: scale(1.2) translateY(1px);
    }
    & span:last-child {
      display: none;
    }
  }
`;

const AppHeaderWrapper = styled.header`
  background-color: white;
  display: flex;
  align-items: center;

  & > figure {
    padding: 10px;
    margin: 0px;

    & > img {
      width: 50px;
      height: 50px;
      object-fit: contain;
    }
  }

  & > .content {
    padding: 8px;

    & p {
      padding: 0;
      margin: 0;
    }
  }
`;

const MobileMainMenu = styled.button`
  position: fixed;
  z-index: 2;
  right: 32px;
  bottom: 32px;
  z-index: 1;
  width: 64px;
  height: 64px;
  border-radius: 32px;
  border: none;
  outline: none;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default withLoginRequired(AdminLayout, {
  admin: true,
  SiteNavBar,
  renderLoginRequired: LoginRequired,
});
