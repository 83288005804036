import React from 'react';
import styled from 'styled-components';
import {getNewOutlet, getOutlet, useOutlet} from 'reconnect.js';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import * as Ant from 'antd';
import * as AppActions from '../AppActions';
import {ModalCustomStyle} from '../AppContext/customStyledCss';

getNewOutlet('admin-download-excel-modal', null, {autoDelete: false});

export function showAdminDownloadExcelModal(data) {
  getOutlet('admin-download-excel-modal').update(data);
}

export default function DownloadModal(props) {
  const [data, setData] = useOutlet('admin-download-excel-modal');

  return (
    <Ant.Modal
      title={null}
      footer={null}
      bodyStyle={{padding: 0}}
      width={480}
      visible={!!data}
      onOk={() => {
        setData(null);
      }}
      onCancel={() => {
        setData(null);
      }}
      destroyOnClose={true}>
      <ModalContent {...data} />
    </Ant.Modal>
  );
}

function ModalContent(props) {
  const {
    collection,
    query,
    sort,
    projection,
    filename = 'export.xlsx',
    recordsToAoa,
    fetchRecords,
    updateWorkSheet,
    clientCallback = {
      refresh: () => 0,
    },
  } = props;

  const [records, setRecords] = React.useState(null);
  const [downloadLink, setDownloadLink] = React.useState(null);

  React.useEffect(() => {
    async function fetchData() {
      AppActions.setLoading(true);
      try {
        let fetchResp = null;

        if (typeof fetchRecords === 'function') {
          fetchResp = await fetchRecords();
        } else {
          fetchResp = await JStorage.fetchAllDocuments(
            collection,
            query || {},
            sort,
            projection,
          );
        }

        setRecords(fetchResp);
      } catch (ex) {
        console.warn(ex);
      }
      AppActions.setLoading(false);
    }

    fetchData();
  }, [collection, query, sort, projection, fetchRecords]);

  async function generateFile() {
    if (!window.XLSX) {
      console.log('no XLSX');
      return;
    }

    AppActions.setLoading(true);
    try {
      const aoaRecords = await recordsToAoa(records);

      const wb = window.XLSX.utils.book_new();
      const ws = window.XLSX.utils.aoa_to_sheet(aoaRecords);

      if (typeof updateWorkSheet === 'function') {
        updateWorkSheet(ws);
      }
      window.XLSX.utils.book_append_sheet(wb, ws, '列表');

      const objUrl = URL.createObjectURL(
        new Blob(
          [
            window.XLSX.write(wb, {
              bookType: 'xlsx',
              bookSST: false,
              type: 'array',
              cellStyles: true,
              bookImages: true,
            }),
          ],
          {type: 'application/octet-stream'},
        ),
      );

      setDownloadLink(objUrl);
      await AppActions.delay(600);
      clientCallback.refresh();
      Ant.message.success('成功創建下載連結');
    } catch (ex) {
      console.warn('AdminDownloadExcelModal.generateFile ex', ex);
    }
    AppActions.setLoading(false);
  }

  return (
    <Wrapper>
      <h2>下載</h2>

      {records && (
        <div className="result">
          <div>共找到 {records.length} 筆</div>
          <div style={{flex: 1}} />
          {downloadLink && (
            <Ant.Button
              type="link"
              href={downloadLink}
              target="_blank"
              download={`${filename}`}>
              點此下載
            </Ant.Button>
          )}
          <Ant.Button type="primary" onClick={generateFile}>
            下載
          </Ant.Button>
        </div>
      )}
    </Wrapper>
  );
}

const Wrapper = styled(ModalCustomStyle)`
  padding: 20px;

  & > .store-fields {
    padding: 15px;
    border-radius: 15px;
    background-color: #eee;

    & label {
      margin-right: 8px;
    }

    & > .search-store {
      display: flex;
      align-items: center;
      & > input {
        width: 180px;
        padding: 3px 10px;
      }
    }

    & > .select-store {
      margin: 10px 0;
    }
  }

  & > .field {
    & > label {
      margin-right: 8px;
    }
    margin: 10px 0;
  }

  & > .result {
    display: flex;
    align-items: center;
    margin-top: 30px;
    border-radius: 8px;
    padding: 10px;
    background-color: #eee;
    box-shadow: rgb(0 0 0 / 16%) 0px 3px 6px, rgb(0 0 0 / 23%) 0px 3px 6px;
  }
`;
