function msgParams() {
  return [
    {name: 'name', label: '會員姓名'},
    {name: 'date', label: '日期'},
  ];
}

function msgContent(paramValues = {}) {
  const {name = '[name]', date = '[date]'} = paramValues;

  return `
        <h2>受文者：${name} 大律師</h2>
        <h3>主 旨：敬請　貴會員依說明三之繳費辦法及所列金額繳納常年會費，以利會務運作。</h3>
        <h4>說 明：</h4>
        <p>1、 本會承蒙　貴會員之熱心指導與參與，得以順利推展會務，謹此致上十二萬分之謝意。</p>
        <p>2、 依本會章程及相關決議，會員應按月繳納常年會費，一般會員為新台幣（下同）陸佰元，特別會員為肆佰元，外國法事務律師為陸佰元，但加入公會十年以上且年滿六十五歲之會員，常年會費減半繳納；加入公會十五年以上且年滿七十歲之會員，免予繳納；加入公會合計未滿三年且三十歲以下之會員，常年會費亦減半繳納。謹請 貴會員於接獲本繳費通知後，撥冗依說明三所示繳費方式繳納常年會費。若有疑問請洽（02）2351-5071分機38，承辦人員可提供說明服務。</p>
        <p>3、 繳費辦法：</p>
        <p>會員自行於本會網站（<a href="www.tba.org.tw">www.tba.org.tw</a>）登入列印繳款單或線上刷卡繳納，說明如下：會員至台北律師公會網站，進入『會員服務』，以您的帳號密碼登入，於『會員資料紀錄』中，如顯示會費未繳，可點選『會費未繳明細』查閱未繳紀錄，點選繳費方式。</p>
        <p>繳納方式如下：</p>
        <p>（1）『自動繳款機及網路ATM』：玉山銀行代號808、繳費單上帳號95796…共16碼，繳款單上之帳號僅限該次常年會費繳納使用，無法用於其他繳費之繳款。（免手續費）</p>
        <p>（2）『超商繳款』：列印繳款單至各地統一、全家、OK及萊爾富等超商均受理。（免手續費）</p>
        <p>（3）『信用卡繳款』。</p>
        <p>（4）『郵政劃撥』：劃撥帳號：15228739，惟此劃撥帳號將於112年1月1日關閉，故如　貴會員於111年12月31日後繳費者，請使用上述(1)至(3)方式繳費。</p>
        <br/>
        <p>會員可以依方便性，選擇不同的繳費方式。繳費單之收執聯，繳費後請自行保管1年，以確保個人權益。</p>
        <br/>
        <p>台北律師公會理事長 張志朋</p>
        <p>${date}</p>
  `;
}

export {msgParams, msgContent};
