import React from 'react';
import {Modal, Button, message} from 'antd';
import styled from 'styled-components';
import {getNewOutlet, getOutlet, useOutlet} from 'reconnect.js';
import GenericForm from 'rev.sdk.js/Generic/Form';
import * as AppActions from '../AppActions';
import * as AppActionsEx from '../AppActions/custom';

getNewOutlet('insurance-edit-modal', null, {autoDelete: false});

export function showInsuranceEditModal(data) {
  getOutlet('insurance-edit-modal').update(data);
}

function InsuranceEditModal(props) {
  const [data, setData] = useOutlet('insurance-edit-modal');

  return (
    <Modal
      title="會員及眷屬保險資料"
      footer={null}
      width={750}
      style={{padding: 0}}
      visible={!!data}
      onOk={() => {
        setData(null);
      }}
      onCancel={() => {
        setData(null);
      }}
      destroyOnClose={true}>
      <ModalContent {...data} setData={setData} />
    </Modal>
  );
}

function calcTbaSeat(order) {
  let tba_seat = 0;
  const config_variants = order.items[0].config.variants || [];
  const product_variants = order.items[0].product.variants || [];

  config_variants.forEach((cv) => {
    const product_variant = product_variants.find((pv) => pv.name === cv.name);
    const product_choice = product_variant.choices.find(
      (pvc) => pvc.name === cv.choice,
    );

    tba_seat += product_choice.tba_seat || 0;
  });

  return tba_seat;
}

function ModalContent(props) {
  const {order, setData, isAdmin} = props;
  const {order_id, insurances} = order;
  const [values, setValues] = React.useState(insurances ? insurances : []);

  return (
    <ModalContentWrapper>
      <Button
        onClick={async () => {
          const resp = await AppActionsEx.getInsuranceInfo({
            isAdmin,
            user_id: order.owner,
          });

          if (Object.keys(resp).length > 0) {
            setValues(resp);
            return;
          }
          message.warning('未找到您的保險資料，敬請手動填寫。');
        }}>
        取得保險資料
      </Button>

      <GenericForm
        instance={{insurances: values}}
        schema={{
          title: '',
          type: 'object',
          required: [],
          properties: {
            'rev-line-0': {
              type: 'string',
              title: '保險資料詳情',
            },
            insurances: {
              type: 'array',
              title: '',
              maxItems: calcTbaSeat(order),
              items: {
                type: 'object',
                properties: {
                  name: {type: 'string', title: '姓名'},
                  birthday: {type: 'string', title: '生日', format: 'date'},
                  is_foreigner: {type: 'boolean', title: '外國人'},
                  id_card_number: {type: 'string', title: '身分證字號'},
                  r_name: {type: 'string', title: '保險受益人姓名'},
                  r_id_card_number: {
                    type: 'string',
                    title: '保險受益人身分證字號',
                  },
                  r_birthday: {
                    type: 'string',
                    title: '保險受益人生日',
                    format: 'date',
                  },
                  relationship: {
                    type: 'string',
                    title: '與受益人關係',
                    enum: [
                      'legal_heir',
                      'mate',
                      'parent',
                      'child',
                      'grandparent',
                      'grandson',
                    ],
                    enumNames: [
                      '法定繼承人',
                      '配偶',
                      '父母',
                      '子女',
                      '祖父母',
                      '孫子',
                    ],
                  },
                  type: {
                    type: 'string',
                    title: '身份',
                    enum: ['member', 'adult', 'child'],
                    enumNames: ['會員', '眷屬大人', '眷屬小孩'],
                  },
                },
              },
            },
          },
        }}
        uiSchema={{
          'rev-line-0': {
            'ui:widget': 'rev-line',
            'ui:options': {label: false},
          },
        }}
        onSubmit={async (formData) => {
          const {insurances} = formData;
          try {
            await AppActionsEx.updateInsurancesInfo({
              isAdmin,
              order_id,
              insurances,
            });
            message.success('更新成功！');
            setData(null);
            setTimeout(() => {
              window.location.reload();
            }, 500);
          } catch (err) {
            console.warn(err);
            message.error('發生錯誤');
          } finally {
            AppActions.setLoading(false);
          }
        }}
      />
    </ModalContentWrapper>
  );
}

const ModalContentWrapper = styled.div``;

export default InsuranceEditModal;
