import React from 'react';
import styled from 'styled-components';
import {Modal, Button, message, Select} from 'antd';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import RichTextEditor from 'rev.sdk.js/Components/RichTextEditor';
import {getNewOutlet, useOutlet, getOutlet} from 'reconnect.js';
import {generateHtml} from 'rev.sdk.js/Components/ArticleEditor/ArticleEditorUtil';
import * as ApiUtil from 'rev.sdk.js/Utils/ApiUtil';
import * as AppActions from '../../AppActions';
import * as AppActionsEx from '../../AppActions/custom.js';
import {ModalCustomStyle} from '../../AppContext/customStyledCss.js';
import {
  AllMsgTemplates,
  MsgTemplateParamEditor,
  generateMsgHtml,
} from './MessageTemplates';

getNewOutlet('AdminMessageEditorModal', null, {autoDelete: false});

function b64FromFile(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = (err) => {
      reject(err);
    };
    reader.readAsDataURL(file);
  });
}

function b64toBlob(b64Data, contentType = '', sliceSize = 512) {
  const byteCharacters = window.atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, {type: contentType});
  return blob;
}

export default function AdminMessageEditorModal(props) {
  const [modalData, setModalData] = useOutlet('AdminMessageEditorModal');
  const [user] = useOutlet('user');

  return (
    <Modal
      title={null}
      footer={null}
      bodyStyle={{padding: 0}}
      width={700}
      visible={modalData}
      onOk={() => {
        setModalData(null);
      }}
      onCancel={() => {
        setModalData(null);
      }}
      destroyOnClose={true}>
      {user && modalData && <ModalContent modalData={modalData} user={user} />}
    </Modal>
  );
}

const Tab = ['內文', '附加檔案', '發送信件'];

function ModalContent(props) {
  const {modalData: currInstance, user} = props;
  const [instance, setInstance] = React.useState(null);
  const [content, setContent] = React.useState(null);
  const [selectedTab, setSelectedTab] = React.useState(Tab[0]);
  const [attachment, setAttachment] = React.useState(null);
  const [generatedUrl, setGeneratedUrl] = React.useState(null);
  const [recipients, setRecipients] = React.useState(null);
  const [product, setProduct] = React.useState(null);
  const [committee, setCommittee] = React.useState(null);
  const [templateType, setTemplateType] = React.useState(null);
  const [templateParams, setTemplateParams] = React.useState(null);
  const inputRef = React.useRef();
  const id = currInstance.id;

  React.useEffect(() => {
    async function fetchData() {
      AppActions.setLoading(true);
      try {
        const _instance = await JStorage.fetchOneDocument('internal_message', {
          id,
        });
        // NOTICE: this order will affect the result, ... kinda strange
        setContent(_instance.content);
        setAttachment(_instance.attachment);
        setTemplateType(_instance.template_type || '');
        setTemplateParams(_instance.template_params || {});
        setInstance(_instance);

        if (_instance.condition === 'manual') {
          if (_instance.recipients && _instance.recipients.length > 0) {
            let user_profiles = await JStorage.fetchAllDocuments(
              'user_profile',
              {
                owner: {
                  $in: _instance.recipients.map((recipient) => recipient),
                },
              },
              ['-created'],
              {
                owner: 1,
                username: 1,
                name: 1,
                email: 1,
                EMAIL2: 1,
              },
            );

            let nextProfiles = [];

            for (const user_profile of user_profiles) {
              nextProfiles.push({
                username: user_profile.username,
                email: user_profile.email,
                name: user_profile.name,
                EMAIL2: user_profile.EMAIL2 || '',
              });
            }

            setRecipients(nextProfiles);
          }
        } else if (_instance.condition === 'product_success') {
          setProduct(
            await JStorage.fetchOneDocument(
              'product',
              {
                id: _instance.product_id,
              },
              {name: 1},
            ),
          );
        } else if (_instance.condition === 'committee') {
          setCommittee(
            await JStorage.fetchOneDocument(
              'Committee',
              {
                id: _instance.committee_id,
              },
              {NAME: 1},
            ),
          );
        }
      } catch (ex) {
        console.warn('AdminMessageEditorModal initial fetch ex', ex);
      }
      AppActions.setLoading(false);
    }

    fetchData();
  }, [id]);

  React.useEffect(() => {
    if (attachment) {
      const {content, content_type} = attachment;
      const blob = b64toBlob(content, content_type);
      const blobUrl = URL.createObjectURL(blob);
      setGeneratedUrl(blobUrl);
    }
  }, [attachment]);

  if (!instance) {
    return null;
  }

  async function onSave() {
    const data = {
      content,
      html: await generateHtml(content),
    };

    AppActions.setLoading(true);
    try {
      await JStorage.updateDocument(
        'internal_message',
        {
          id,
        },
        data,
      );
    } catch (ex) {
      console.warn('AdminMessageEditorModal onSave ex', ex);
    }
    AppActions.setLoading(false);
  }

  async function onSaveTempate() {
    AppActions.setLoading(true);
    try {
      await JStorage.updateDocument(
        'internal_message',
        {
          id,
        },
        {
          template_type: templateType,
          template_params: templateParams,
          html: generateMsgHtml(templateType, templateParams),
        },
      );
    } catch (ex) {
      console.warn('AdminMessageEditorModal onSaveTemplate ex', ex);
    }
    AppActions.setLoading(false);
  }

  async function onSaveAttachment({clear = false}) {
    if (clear) {
      if (!window.confirm('確認要清除附加檔案嗎?')) {
        return;
      }
    } else {
      if (attachment !== instance.attachment) {
        if (!window.confirm('確認要修改附加檔案嗎?')) {
          return;
        }
      }
    }

    AppActions.setLoading(true);
    try {
      await JStorage.updateDocument(
        'internal_message',
        {
          id,
        },
        {attachment: clear ? null : attachment},
      );
      if (clear) {
        setGeneratedUrl(null);
        setAttachment(null);
      }
    } catch (ex) {
      console.warn('AdminMessageEditorModal onSave ex', ex);
    }
    AppActions.setLoading(false);
  }

  async function encodeAttachmentFile() {
    if (!inputRef.current.files || !inputRef.current.files[0]) {
      console.log('no file selected');
      return;
    }

    const file = inputRef.current.files[0];
    const out = await b64FromFile(file);
    const [prefix, data] = out.split(';base64,');

    setAttachment({
      name: file.name,
      content: data,
      content_type: prefix.split('data:')[1],
    });
  }

  return (
    <Wrapper>
      <h2>編輯</h2>

      <div style={{borderBottom: '1px solid #ccc', margin: '20px 0 10px'}}>
        {Tab.map((t) => {
          const selected = selectedTab === t;
          return (
            <Button
              key={t}
              type={selected ? 'primary' : 'link'}
              onClick={() => setSelectedTab(t)}>
              {t}
            </Button>
          );
        })}
      </div>

      {selectedTab === Tab[0] && (
        <>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginTop: 10,
            }}>
            <label>範本:</label>
            <Select
              style={{marginLeft: 10, maxWidth: 200, width: '100%'}}
              value={templateType}
              onChange={(nextValue) => setTemplateType(nextValue)}>
              {AllMsgTemplates.map((opt) => {
                return (
                  <Select.Option value={opt.name}>{opt.label}</Select.Option>
                );
              })}
            </Select>
          </div>

          {!templateType ? (
            <>
              <RichTextEditor
                options={{}}
                token={user.token}
                onChange={(value) => setContent(value)}
                content={content}
              />
              <div className="action-bar">
                <Button onClick={onSave}>儲存</Button>
              </div>
            </>
          ) : (
            <>
              <MsgTemplateParamEditor
                type={templateType}
                params={templateParams}
                setParams={setTemplateParams}
              />
              <div className="action-bar">
                <Button onClick={onSaveTempate}>儲存</Button>
              </div>
            </>
          )}
        </>
      )}

      {selectedTab === Tab[1] && (
        <>
          {generatedUrl && (
            <div style={{marginBottom: 10}}>
              <a
                href={generatedUrl}
                download={attachment?.name}
                target="_blank"
                rel="noreferrer">
                {attachment?.name || '未命名檔案'}
              </a>
            </div>
          )}
          <input type="file" ref={inputRef} onChange={encodeAttachmentFile} />
          <div className="action-bar">
            <Button
              onClick={() => onSaveAttachment({clear: false})}
              disabled={attachment === instance.attachment}>
              儲存
            </Button>
            <Button type="link" onClick={() => onSaveAttachment({clear: true})}>
              清除
            </Button>
          </div>
        </>
      )}

      {selectedTab === Tab[2] && (
        <div style={{marginTop: 10}}>
          <div style={{padding: 10}}>
            {instance && instance.condition === 'all' && (
              <div style={{padding: 5}}>寄送名單條件: 全體會員</div>
            )}

            {instance && instance.condition === 'manual' && recipients && (
              <>
                <div style={{padding: 5}}>寄送名單條件: 手動選取</div>
                {recipients.map((recipient, index) => {
                  return (
                    <div ket={`recipient-${index}`} style={{padding: 5}}>
                      <b>
                        {recipient.username} {recipient.name}
                      </b>
                      <div>信箱： {recipient.email}</div>
                      <div>備用信箱：{recipient.EMAIL2 || '無'}</div>
                    </div>
                  );
                })}
              </>
            )}

            {instance && instance.condition === 'product_success' && (
              <>
                <div style={{padding: 5}}>寄送名單條件: 已報名課程/活動</div>
                <div style={{padding: 5}}>
                  名稱：{product?.name || instance.product_id}
                </div>
              </>
            )}

            {instance && instance.condition === 'committee' && (
              <>
                <div style={{padding: 5}}>寄送名單條件: 團體</div>
                <div style={{padding: 5}}>
                  名稱：{committee?.NAME || instance.committee_id}
                </div>
              </>
            )}
          </div>
          <Button
            style={{width: '100%'}}
            size="large"
            onClick={async () => {
              AppActions.setLoading(true);
              try {
                await AppActionsEx.sendMessage(id);
                message.success('成功發送');
              } catch (err) {
                console.warn('sendMessage err', err);
                message.error(err);
              }
              AppActions.setLoading(false);
            }}>
            確認發送
          </Button>
        </div>
      )}
    </Wrapper>
  );
}

const Wrapper = styled(ModalCustomStyle)`
  padding: 20px;

  & .action-bar {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  }
`;
