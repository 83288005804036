import * as tw from 'rev.sdk.js/Locale/tw';

const labels = {
  ...tw.labels,
  intro: '介紹',
  spec: '詳情',
  remark: '備註',
  share_link: '複製分享連結',
  confirm: '確認',
  customer_support: '聯絡我們',
  customer_support_title: '問題類型',
  customer_support_content: '詢問內容',
  name: '姓名',
  phone: '聯絡電話',
  email: '信箱',
  updated: '更新時間',
  next_article: '上一篇',
  prev_article: '下一篇',
  cart: '報名清單',
  cart_add: '加入報名清單',
  cart_add_error_message_items_limit: '超過報名上限，請分批報名',
  cart_add_error_message: '發生錯誤，請重新嘗試，或與公會人員聯繫',
  cart_clear: '清除報名清單',
  cart_clear_success: '清除報名清單成功',
  cart_clear_failure: '清除報名清單失敗，請與客服人員聯繫',
  cart_empty_content: '歡迎參與各式課程及活動',
  buy_now: '立即報名',
  checkout_info: '報名資訊',
  shopping: '馬上報名',
  cart_empty_title: '尚未報名課程或活動',
  cart_empty: 'Empty',
  payment_logistics_section_title: '付款方式',
  receiver_info: '報名資訊',
  buyer_info: '購買人資訊',
  cart_items: '報名清單',
  order: '報名',
  order_title: '報名紀錄',
  product: '課程/活動',
  sale_start_time: '報名開始時間',
  sale_end_time: '報名結束時間',
  back_products: '返回全部課程/活動',
  return: '取消報名',
  check_return_status: '查看取消報名狀態',
  apply_return: '申請取消報名',
  return_form: '取消報名表單',
  return_reason:
    '取消報名理由及原始刷卡卡號前6碼 + 後4碼 (課程/活動當天不可取消)',
  return_status: '取消報名狀態',
  edit_return_info_forbidden: '未開放修改取消報名資訊',
  edit_return_info: '修改取消報名資訊',
  return_response_title: '工作人員回覆',
  order_id: '繳費編號',
  order_created: '繳費建立日期',
  order_content: '活動內容',
  order_info: '繳費資訊',
  order_status: '繳費狀態',
  order_note: '繳費備註',
  order_total: '繳費總額',
  duplicate_product: '您已報名本課程/活動',
  fee: '加價',
  about: 'About',
  member: 'Member',
  law: 'Law Service',
  public: 'Public',
  checkout_step1: '查看報名資訊',
  checkout_step2: '設定付款資料',
  checkout_step3: '最後確認，前往結帳',
};

export {labels};
