import React from 'react';
import styled from 'styled-components';
import {Button, Modal, Table, Input} from 'antd';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import {useOutlet, getOutlet, getNewOutlet} from 'reconnect.js';
import * as AppActions from '../../AppActions';
import {ModalCustomStyle} from '../../AppContext/customStyledCss';

getNewOutlet('AdminSelectReceiveModal', null, {autoDelete: false});

export default function AdminSelectReceiveWidget(props) {
  const [selectedReceive, setSelectedReceive] = React.useState(null);
  const [fetching, setFetching] = React.useState(false);

  //rjsf write "readOnly", but props get "readonly".
  const readonly = props.readonly === true || props.readOnly === true;

  React.useEffect(() => {
    const fetchRecordById = async (id) => {
      setFetching(true);

      try {
        setSelectedReceive(
          await JStorage.fetchOneDocument('form', {
            id,
          }),
        );
      } catch (err) {
        console.warn(err);
      }

      setFetching(false);
    };

    if (props.value) {
      fetchRecordById(props.value);
    }
  }, [props.value]);

  return (
    <Wrapper>
      <div className="info">
        {fetching ? (
          '設定中...'
        ) : !props.value ? (
          '尚未設定收文'
        ) : !selectedReceive ? (
          props.value
        ) : (
          <div>
            <Button
              type="link"
              title="點選開啟新分頁編輯"
              style={{padding: 0, marginRight: 5}}
              href={`/admin/receives/?action=detail&id=${selectedReceive.id}`}>
              {selectedReceive.paperwork_id}
            </Button>
            <span>{selectedReceive.title}</span>
          </div>
        )}
      </div>
      {!readonly && (
        <div className="actions">
          <Button
            onClick={() => {
              props.onChange('');
              setSelectedReceive(null);
            }}
            style={{margin: 5}}>
            清除
          </Button>
          <Button
            type="primary"
            style={{margin: 5}}
            onClick={() =>
              getOutlet('AdminSelectReceiveModal').update({
                onChange: (record) => {
                  props.onChange(record.id);
                },
              })
            }>
            搜尋
          </Button>
        </div>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 14px;
  border: 1px solid var(--adminBorderColor);
  border-radius: 4px;
  padding: 15px;

  & > .info {
    margin-right: 15px;
    flex: 1;
  }

  & > .actions {
  }
`;

const pageSize = 20;
const Columns = ({onChange, hideModal}) => [
  {
    title: '查詢結果',
    key: '#',
    render: (_, record) => {
      return (
        <div
          style={{cursor: 'pointer'}}
          onClick={() => {
            onChange(record);
            setTimeout(() => {
              hideModal();
            }, 500);
          }}>
          <div>
            <span style={{marginRight: 10}}>
              收文編號：{record.paperwork_id}
            </span>
            <span>發文機關：{record.organs}</span>
          </div>
          <div>{record.title}</div>
        </div>
      );
    },
  },
];

export function AdminSelectReceiveModal(props) {
  const [data, setData] = useOutlet('AdminSelectReceiveModal');
  return (
    <Modal
      title={null}
      footer={null}
      bodyStyle={{padding: 0}}
      width={600}
      visible={!!data}
      onOk={() => {
        setData(null);
      }}
      onCancel={() => {
        setData(null);
      }}
      destroyOnClose={true}>
      {data && (
        <ModalContent
          data={{
            onChange: data.onChange,
            hideModal: () => setData(null),
          }}
        />
      )}
    </Modal>
  );
}

function ModalContent(props) {
  const {
    data: {onChange, hideModal},
  } = props;
  const [paging, setPaging] = React.useState({offset: 0, limit: pageSize});
  const [keyword, setKeyword] = React.useState('');
  const [fetchResp, setFetchResp] = React.useState(null);

  async function fetchData(_keyword, _paging) {
    AppActions.setLoading(true);
    try {
      const resp = await JStorage.fetchDocuments(
        'form',
        {
          type: 'receive',
          $or: [
            {
              title: {
                $regex: _keyword,
              },
            },
            {
              paperwork_id: {$regex: _keyword},
            },
            {
              organs: {$regex: _keyword},
            },
          ],
        },
        ['-created'],
        _paging,
      );

      setFetchResp(resp);
    } catch (ex) {
      console.warn(ex);
    }
    AppActions.setLoading(false);
  }

  return (
    <ModalWrapper>
      <h1>收文</h1>
      <section className="query">
        <div style={{display: 'flex', alignItems: 'center'}}>
          <label>關鍵字</label>
          <Input
            style={{marginLeft: 10, maxWidth: 250, width: '100%'}}
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
            placeholder="收文編號、主旨、發文機關"
          />
        </div>
      </section>

      <section className="actions">
        <Button
          onClick={async () => {
            fetchData(keyword, paging);
          }}>
          搜尋
        </Button>
      </section>

      <Table
        columns={Columns({onChange, hideModal})}
        dataSource={fetchResp?.results || []}
        rowKey={'id'}
        pagination={{
          size: 'small',
          total: fetchResp?.total || 0,
          showSizeChanger: false,
          showTotal: (total) => `共 ${total} 筆, 每頁 ${pageSize} 筆`,
          current: paging.offset / pageSize + 1,
          pageSize: pageSize,
          position: ['topLeft'],
          onChange: (page, pageSize) => {
            if (pageSize) {
              const nextPaging = {
                offset: (page - 1) * pageSize,
                limit: pageSize,
              };
              setPaging(nextPaging);
              fetchData(keyword, nextPaging);
            }
          },
        }}
      />
    </ModalWrapper>
  );
}

const ModalWrapper = styled(ModalCustomStyle)`
  padding: 20px;

  h1 {
    font-size: 24px;
  }

  section.query {
    border: 1px dashed #ccc;
    border-radius: 8px;
    padding: 12px;
    cursor: pointer;
    margin-bottom: 10px;

    & > label {
      margin-right: 10px;
    }
  }

  section.actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 10px;
  }
`;
