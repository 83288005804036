import {getOutlet} from 'reconnect.js';
import {navigate as nav} from 'gatsby';
import * as User from 'rev.sdk.js/Actions/User';
import * as Cart from 'rev.sdk.js/Actions/Cart';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import * as Admin from 'rev.sdk.js/Actions/Admin';
import * as ApiUtil from 'rev.sdk.js/Utils/ApiUtil';
import * as PathUtil from 'rev.sdk.js/Utils/PathUtil';
import NavUrl from 'rev.sdk.js/Utils/NavUrl';
import Config from '../../data.json';
import * as jwt from 'rev.sdk.js/Utils/jwt';
import tracker from 'rev.sdk.js/Utils/Tracker';
import AppActionsOverride from './custom';
import * as Locale from '../Locale';
import * as ProductUitl from '../Utils/ProductUtil';

const req = ApiUtil.req;
const LoadingOutlet = getOutlet('loading');
const ApiHookOutlet = getOutlet('ApiUtil');
const UserOutlet = getOutlet('user');

ApiHookOutlet.update({
  ...ApiHookOutlet.getValue(),
  onJson: (url, payload, jsonResp) => {
    // a sample hook, you can do whatever you want here
    return jsonResp;
  },
  onError: async (url, payload, resp) => {
    if (url.indexOf('token=') > -1 && resp.status === 410) {
      console.log('onError try autoLogin');
      const user = UserOutlet.getValue();
      const isAdmin = user.grp.split(':').indexOf('admin') !== -1;
      const result = await User.autoLogin({admin: isAdmin});
      if (result) {
        console.log('onError autoLogin success, fetch resource again', result);
        return req(url, payload, {ignoreOnErrorHook: true});
      }
      console.log('onError autoLogin failure, throw original error', result);
      throw resp;
    }
  },
});

function delay(ms) {
  if (AppActionsOverride.delay) {
    return AppActionsOverride.delay(...arguments);
  }

  return new Promise((resolve) => setTimeout(resolve, ms));
}

function setLoading(loading, params) {
  if (AppActionsOverride.setLoading) {
    return AppActionsOverride.setLoading(...arguments);
  }

  const {message = ''} = params || {};
  setTimeout(() => {
    LoadingOutlet.update({loading: loading, message: message});
  }, 0);
}

function L(key, defaultValue) {
  return Locale.getLabel(key, defaultValue);
}

function F(obj, field) {
  return Locale.getField(obj, field);
}

function gtag(eventType, event, payload) {
  if (AppActionsOverride.gtag) {
    return AppActionsOverride.gtag(...arguments);
  }

  let shouldContinue = true;
  // when return true , would trigger the default fbq behavior in rev.sdk.js
  return shouldContinue;
}

function fbq(eventType, event, payload) {
  if (AppActionsOverride.fbq) {
    return AppActionsOverride.gtag(...arguments);
  }

  let shouldContinue = true;
  // when return true , would trigger the default fbq behavior in rev.sdk.js
  return shouldContinue;
}

async function navigate(nextRoute, options = {}) {
  if (AppActionsOverride.navigate) {
    return AppActionsOverride.navigate(...arguments);
  }

  const {message = '', loading = false, ...rest} = options;
  if (nextRoute instanceof NavUrl) {
    nextRoute = nextRoute.toString();
  }

  const currRoute = PathUtil.normalizedRoute();
  nextRoute = PathUtil.normalizedRoute(nextRoute);
  if (currRoute !== nextRoute) {
    if (options?.loading) {
      LoadingOutlet.update({
        message: options.message,
        loading: options.loading,
      });
      if (typeof options.loading === 'number') {
        setTimeout(() => {
          LoadingOutlet.update(false);
        }, options.loading);
      }
    }
    await nav(nextRoute, rest);
  } else {
    console.log('path not changed, ignore...');
  }
}

function adminCanAccess(user, options = {}) {
  if (AppActionsOverride.adminCanAccess) {
    return AppActionsOverride.adminCanAccess(...arguments);
  }

  return true;
}

/**
 * **************************************************
 * (client) JStorage powered product fetching APIs
 * **************************************************
 */

async function clientJStorageFetch(collection, {cat, sort, search, q, page}) {
  if (AppActionsOverride.clientJStorageFetch) {
    return AppActionsOverride.clientJStorageFetch(...arguments);
  }

  //"q" can defined custom query by project
  const catQuery = cat ? {labels: {$regex: cat}} : {};
  const sortValue = sort ? [sort] : ['-created'];
  const pagingValue = page;
  const extraQueries = {};
  let projection = null;

  if (collection === 'product') {
    extraQueries.public = true;
    extraQueries.archived = {$ne: true};
    extraQueries.type = {$ne: 'group_item'};

    //TODO extract to AppActions custom file.
    if (q) {
      extraQueries.expertises = {$regex: q};
    }

    extraQueries._id = {
      $nin: [...ProductUitl.FEE_PRODUCTS].map((id) => ({$oid: id})),
    };

    extraQueries['$or'] = [
      {
        onshelf_start_date: {$lte: new Date().toISOString().split('T')[0]},
        onshelf_end_date: {$gte: new Date().toISOString().split('T')[0]},
      },
      {
        onshelf_start_date: {$exists: false},
        onshelf_end_date: {$exists: false},
      },
      {
        onshelf_start_date: '',
        onshelf_end_date: '',
      },
      {
        onshelf_start_date: null,
        onshelf_end_date: null,
      },
    ];

    if (search) {
      extraQueries['$or'] = extraQueries['$or'].map((query) => {
        return {
          ...query,
          $or: [{name: {$regex: search}}, {searchText: {$regex: search}}],
        };
      });
    }
  } else if (collection === 'Article_Default') {
    delete catQuery.labels;

    if (cat) {
      catQuery.labels = {$regex: cat};
    } else {
      catQuery.labels = 'blog';
    }

    if (search) {
      extraQueries['$or'] = [
        {title: {$regex: search}},
        {searchText: {$regex: search}},
      ];
    }

    projection = {content: 0};
  }

  const resp = await JStorage.fetchDocuments(
    collection,
    {
      ...catQuery,
      ...extraQueries,
    },
    sortValue,
    pagingValue,
    projection, // if we're fetching Article, ignore the content
    {anonymous: true},
  );

  return resp;
}

function getDefaultCheckoutFormSpec() {
  if (AppActionsOverride.getDefaultCheckoutFormSpec) {
    return AppActionsOverride.getDefaultCheckoutFormSpec(...arguments);
  }
  // TODO: deprecate AppActionsOverride.getDefaultCheckoutFormSpec
  // customize from config/custom.js

  return {
    paymentSubTypes: Config.paymentSubTypes,
    logisticsTypes: Config.logisticsTypes,
    logisticsSubTypes: Config.logisticsSubTypes,
    invoiceCategories: Config.invoiceCategories,
    invoiceTypes: Config.invoiceTypes,
    invoiceCarrierTypes: Config.invoiceCarrierTypes,
  };
}

function onCartLoaded(cart) {
  if (AppActionsOverride.onCartLoaded) {
    return AppActionsOverride.onCartLoaded(...arguments);
  }

  const checkoutFormSpec = getDefaultCheckoutFormSpec();

  const defaultUser = {
    buyer_name: cart.buyer_name || UserOutlet.getValue().data.name || '',
    buyer_email: cart.buyer_email || UserOutlet.getValue().data.email || '',
    buyer_phone: cart.buyer_phone || UserOutlet.getValue().data.phone || '',
    buyer_zip: cart.buyer_zip || UserOutlet.getValue().data.zip || '',
    buyer_city: cart.buyer_city || UserOutlet.getValue().data.city || '',
    buyer_district:
      cart.buyer_district || UserOutlet.getValue().data.district || '',
    buyer_address:
      cart.buyer_address || UserOutlet.getValue().data.address || '',
  };

  const updateConfig = {
    ...cart,
    ...defaultUser,
  };

  //<---tba custom logic start--->

  if (updateConfig.buyer_name === '') {
    updateConfig.buyer_name = 'no name';
  }

  if (updateConfig.buyer_email === '') {
    updateConfig.buyer_email = 'noreply@revtel-api.com';
  }

  if (updateConfig.buyer_phone === '') {
    updateConfig.buyer_phone = '0900000000';
  }

  if (updateConfig.items[0].name.indexOf('會費') > -1) {
    checkoutFormSpec.paymentSubTypes = [
      ...checkoutFormSpec.paymentSubTypes,
      Cart.PAYMENT_SUBTYPE.barcode,
    ];
  }

  if (updateConfig.items[0].name.indexOf('會費') === -1) {
    checkoutFormSpec.paymentSubTypes = [Cart.PAYMENT_SUBTYPE.credit];
  }

  //<---tba custom logic end--->

  // check payment valid value
  if (
    checkoutFormSpec.paymentSubTypes.indexOf(updateConfig.payment_subtype) < 0
  ) {
    updateConfig.payment_subtype = checkoutFormSpec.paymentSubTypes[0] || '';
  }
  // check logistic valid value
  if (
    checkoutFormSpec.logisticsTypes.indexOf(updateConfig.logistics_type) < 0
  ) {
    updateConfig.logistics_type = checkoutFormSpec.logisticsTypes[0] || '';
    updateConfig.logistics_subtype =
      checkoutFormSpec.logisticsSubTypes[updateConfig.logistics_type][0];
  }
  if (
    checkoutFormSpec.logisticsSubTypes?.[updateConfig.logistics_type].indexOf(
      updateConfig.logistics_subtype,
    ) < 0
  ) {
    updateConfig.logistics_subtype =
      checkoutFormSpec.logisticsSubTypes[updateConfig.logistics_type][0];
  }
  // checkout invoice valid value
  if (checkoutFormSpec.invoiceTypes.indexOf(updateConfig.invoice_type) < 0) {
    updateConfig.invoice_type = checkoutFormSpec.invoiceTypes[0] || '';
  }

  return {
    updateConfig,
    checkoutFormSpec,
  };
}

async function rebuild() {
  if (AppActionsOverride.rebuild) {
    return AppActionsOverride.rebuild(...arguments);
  }

  await req('https://api.netlify.com/build_hooks/615418bee44904a94bd7b4ab', {
    method: 'POST',
    data: {},
  });
}

async function fetchCustomResources(
  resource,
  {sort, keyword, filter, paging, extraQueries},
) {
  if (AppActionsOverride.fetchCustomResources) {
    return AppActionsOverride.fetchCustomResources(...arguments);
  }

  return null;
}

async function onLoginResult(err, result) {
  if (AppActionsOverride.onLoginResult) {
    return AppActionsOverride.onLoginResult(...arguments);
  }

  if (!err) {
    try {
      setLoading(true);
      const isAdmin = result.grp.split(':').indexOf('admin') !== -1;
      if (!isAdmin) {
        const queryKey = Config.jstoreVersion !== 'v1' ? 'owner' : 'id';
        const profile = await JStorage.fetchOneDocument('user_profile', {
          [queryKey]: UserOutlet.getValue().username,
        });
        const privateProfile = await User.getPrivateProfile();

        UserOutlet.update({
          ...UserOutlet.getValue(),
          data: {
            ...profile,
            email: privateProfile.email,
            points: privateProfile.points,
            provider: privateProfile.provider,
            refer_by: privateProfile.refer_by,
          },
        });

        await jwt.decodeToken(UserOutlet.getValue().token);
        tracker.login({user: UserOutlet.getValue()});
        await Cart.fetchCart({initial_clear: true, initial_raise: true});
      }
    } catch (ex) {
      console.warn('onLoginResult ex', ex);
    } finally {
      setLoading(false);
    }
  }
}

async function onTempLoginResult(err, result) {
  console.log('onTempLoginResult', err, result);
  if (!err) {
    try {
      setLoading(true);
      await Cart.fetchCart({initial_clear: true, initial_raise: true});
    } catch (ex) {
      console.warn('onTempLoginResult ex', ex);
    } finally {
      setLoading(false);
    }
  }
}

async function onAdminFormSubmit({
  path,
  collection,
  instance,
  extValues,
  formData,
  primaryKey,
}) {
  if (AppActionsOverride.onAdminFormSubmit) {
    return AppActionsOverride.onAdminFormSubmit(...arguments);
  }

  if (collection === 'product' && formData.stock_type === 'period') {
    if (
      typeof formData.stock_start_date === 'string' &&
      typeof formData.stock_duration === 'number'
    ) {
      const theDate = new Date(formData.stock_start_date);
      const saleDuration = formData.stock_duration;
      theDate.setDate(theDate.getDate() + saleDuration - 1);
      formData.stock_end_date = theDate.toISOString().split('T')[0];
    }
  }

  if (collection === 'product' && formData.archived) {
    formData.public = false;
  }
  return false;
}

async function onAfterAdminFormSubmit(
  {path, collection, instance, extValues, formData, primaryKey},
  updatedInstance,
) {
  if (AppActionsOverride.onAfterAdminFormSubmit) {
    return AppActionsOverride.onAfterAdminFormSubmit(...arguments);
  }

  if (
    path.indexOf('/admin/landing') > -1 ||
    path.indexOf('/admin/product_category') > -1 ||
    path.indexOf('/admin/article_category') > -1 ||
    path.indexOf('/admin/sales_event_category') > -1 ||
    path.indexOf('/admin/config') > -1 ||
    path.indexOf('/admin/about') > -1 ||
    path.indexOf('/admin/navbar_config') > -1 ||
    path.indexOf('/admin/footer_config') > -1 ||
    path.indexOf('/admin/customer_support_config') > -1
  ) {
    await JStorage.cacheDocuments('site', {}, null, null, null, undefined, {
      key: 'rev-site-cache.json',
    });
  }

  if (collection === 'return_request') {
    if (instance.status !== formData.status) {
      if (formData.status === 'return_applied') {
        try {
          await Cart.returnConfirm(instance.id, 'return_applied');
        } catch (ex) {
          console.warn(ex);
        }
      }

      if (formData.status === 'return_completed') {
        try {
          await Cart.returnConfirm(instance.id, 'return_completed');
        } catch (ex) {
          console.warn(ex);
        }
      }
    }

    if (
      instance.status !== formData.status ||
      instance.data?.staff_note !== formData.data?.staff_note
    ) {
      try {
        let user_profile = await JStorage.fetchOneDocument('user_profile', {
          owner: formData.owner,
        });

        await Admin.sendReturnEmail(user_profile.email, formData);
      } catch (ex) {
        console.warn(ex);
      }
    }
  }

  return null;
}

function getReurl({title, description, image, redirectUrl}) {
  if (AppActionsOverride.getReurl) {
    return AppActionsOverride.getReurl(...arguments);
  }

  return `${Config.apiHost}/misc/reurl?title=${title}&image=${image}${
    description ? `&description=${description}` : ''
  }&redirect_url=${redirectUrl}`;
}

async function sendCustomerSupport(values) {
  if (AppActionsOverride.sendCustomerSupport) {
    return AppActionsOverride.sendCustomerSupport(...arguments);
  }

  return JStorage.createDocument('customer_support', values, {anonymous: true});
}

async function fetchSessions(timestrPrefix = '2023-03') {
  //FIXME: handle sort, page's offset and limit
  return JStorage.fetchDocuments(
    'product',
    {
      public: true,
      'session.date': {$regex: timestrPrefix},
      type: {$ne: 'group_root'},
    },
    ['session.date'],
    null,
    null,
    {
      anonymous: true,
    },
  );
}

export {
  delay,
  setLoading,
  L,
  F,
  navigate,
  adminCanAccess,
  getDefaultCheckoutFormSpec,
  clientJStorageFetch,
  fetchCustomResources,
  onLoginResult,
  onTempLoginResult,
  onAdminFormSubmit,
  onAfterAdminFormSubmit,
  onCartLoaded,
  rebuild,
  getReurl,
  gtag,
  fbq,
  sendCustomerSupport,
  fetchSessions,
};
