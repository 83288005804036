import React from 'react';
import styled from 'styled-components';
import {Button, Modal, Table} from 'antd';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import {useOutlet, getOutlet, getNewOutlet} from 'reconnect.js';
import * as AppActions from '../../AppActions';
import {ModalCustomStyle} from '../../AppContext/customStyledCss';

getNewOutlet('AdminSelectMeetingModal', null, {autoDelete: false});

export default function AdminSelectMeetingWidget(props) {
  const [selectedMeeting, setSelectedMeeting] = React.useState(null);
  const [fetching, setFetching] = React.useState(false);

  React.useEffect(() => {
    const fetchRecordById = async (id) => {
      setFetching(true);

      try {
        setSelectedMeeting(
          await JStorage.fetchOneDocument('Meeting', {
            id,
          }),
        );
      } catch (err) {
        console.warn(err);
      }

      setFetching(false);
    };

    if (props.value) {
      fetchRecordById(props.value);
    }
  }, [props.value]);

  return (
    <Wrapper>
      <div>
        {fetching
          ? '設定中...'
          : !props.value
          ? '尚未設定'
          : !selectedMeeting
          ? props.value
          : `【 ${selectedMeeting.MTNG_NAME} 】`}
      </div>
      <Button
        onClick={() => {
          props.onChange('');
          setSelectedMeeting(null);
        }}
        style={{marginRight: 10}}>
        清除
      </Button>

      <Button
        type="primary"
        onClick={() =>
          getOutlet('AdminSelectMeetingModal').update({
            onChange: (record) => {
              props.onChange(record.id);
            },
          })
        }>
        搜尋
      </Button>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 14px;
  border: 1px solid var(--adminBorderColor);
  border-radius: 4px;
  padding: 15px;

  & > div {
    margin-right: 15px;
    letter-space: 1.5;
  }
`;

const pageSize = 20;
const Columns = ({onChange, hideModal}) => [
  {
    title: '查詢結果',
    key: '#',
    render: (_, record) => {
      return (
        <div
          style={{cursor: 'pointer'}}
          onClick={() => {
            onChange(record);
            setTimeout(() => {
              hideModal();
            }, 500);
          }}>
          <div>{record.MTNG_NAME}</div>
        </div>
      );
    },
  },
];

export function AdminSelectMeetingModal(props) {
  const [data, setData] = useOutlet('AdminSelectMeetingModal');
  return (
    <Modal
      title={null}
      footer={null}
      bodyStyle={{padding: 0}}
      width={600}
      visible={!!data}
      onOk={() => {
        setData(null);
      }}
      onCancel={() => {
        setData(null);
      }}
      destroyOnClose={true}>
      {data && (
        <ModalContent
          data={{
            onChange: data.onChange,
            hideModal: () => setData(null),
          }}
        />
      )}
    </Modal>
  );
}

function ModalContent(props) {
  const {
    data: {onChange, hideModal},
  } = props;
  const [paging, setPaging] = React.useState({offset: 0, limit: pageSize});
  const [keyword, setKeyword] = React.useState('');
  const [fetchResp, setFetchResp] = React.useState(null);

  async function fetchData(_keyword, _paging) {
    AppActions.setLoading(true);
    try {
      const resp = await JStorage.fetchDocuments(
        'Meeting',
        {
          MTNG_NAME: {
            $regex: _keyword,
          },
        },
        ['-created'],
        _paging,
      );

      setFetchResp(resp);
    } catch (ex) {
      console.warn(ex);
    }
    AppActions.setLoading(false);
  }

  return (
    <ModalWrapper>
      <h1>會議紀錄</h1>
      <section className="query">
        <label>關鍵字</label>
        <input value={keyword} onChange={(e) => setKeyword(e.target.value)} />
      </section>

      <section className="actions">
        <Button
          onClick={async () => {
            fetchData(keyword, paging);
          }}>
          搜尋
        </Button>
      </section>

      <Table
        columns={Columns({onChange, hideModal})}
        dataSource={fetchResp?.results || []}
        rowKey={'id'}
        pagination={{
          size: 'small',
          total: fetchResp?.total || 0,
          showSizeChanger: false,
          showTotal: (total) => `共 ${total} 筆, 每頁 ${pageSize} 筆`,
          current: paging.offset / pageSize + 1,
          pageSize: pageSize,
          position: ['topLeft'],
          onChange: (page, pageSize) => {
            if (pageSize) {
              const nextPaging = {
                offset: (page - 1) * pageSize,
                limit: pageSize,
              };
              setPaging(nextPaging);
              fetchData(keyword, nextPaging);
            }
          },
        }}
      />
    </ModalWrapper>
  );
}

const ModalWrapper = styled(ModalCustomStyle)`
  padding: 20px;

  h1 {
    font-size: 24px;
  }

  section.query {
    border: 1px dashed #ccc;
    border-radius: 8px;
    padding: 12px;
    cursor: pointer;
    margin-bottom: 10px;

    & > label {
      margin-right: 10px;
    }
  }

  section.actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 10px;
  }
`;
