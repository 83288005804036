import React from 'react';
import {Button, Input, Modal, Table, message} from 'antd';
import {getNewOutlet, getOutlet, useOutlet} from 'reconnect.js';
import * as Jstorage from 'rev.sdk.js/Actions/JStorage';
import * as AppActions from '../../AppActions';

getNewOutlet('admin-search-user-modal', null, {autoDelete: false});

export function showAdminSearchUserModal(data) {
  getOutlet('admin-search-user-modal').update(data);
}

const fetchRecords = async (
  keyword,
  columns,
  paging = {offset: 0, limit: 4},
) => {
  try {
    AppActions.setLoading(true);
    let nextProfiles = [];

    const {results: _profiles, total} = await Jstorage.fetchDocuments(
      'user_profile',
      {
        $or: columns.map((column) => ({
          [column.key]: {$regex: keyword},
        })),
      },
      null,
      paging,
    );

    const _private_profiles = await Jstorage.fetchAllDocuments(
      'private_profile',
      {
        owner: {$in: _profiles.map((_profile) => _profile.owner)},
      },
    );

    for (let record of _profiles) {
      const targetPrivateProfile = _private_profiles.find(
        (ppr) => ppr.owner === record.owner,
      );
      record = {
        ...record,
        points: targetPrivateProfile?.points || 0,
        provider: targetPrivateProfile?.provider || record?.provider || '',
      };

      nextProfiles = [...nextProfiles, record];
    }

    return {total, results: nextProfiles};
  } catch (err) {
    message.error('發生錯誤。');
  } finally {
    AppActions.setLoading(false);
  }
};

export default function AdminSearchUsersModal(props) {
  const [data, setData] = useOutlet('admin-search-user-modal');
  const [keyword, setKeyword] = React.useState(null);
  const [records, setRecords] = React.useState(null);
  const [_selectedRows, _setSelectedRows] = React.useState([]);
  const [pagination, setPagination] = React.useState({
    total: 0,
    pageSize: 4,
  });

  const columns = [
    {
      key: 'name',
      title: '姓名',
      dataIndex: 'name',
      fixed: 'left',
    },
    {
      key: 'phone',
      title: '手機',
      dataIndex: 'phone',
    },
    {
      key: 'email',
      title: '信箱',
      dataIndex: 'email',
    },
    {
      key: 'provider',
      title: '登入方式',
      dataIndex: 'provider',
    },
    {
      key: 'owner',
      title: '',
      dataIndex: 'owner',
      render: () => null,
    },
  ];

  function onClose() {
    setKeyword(null);
    setRecords(null);
    _setSelectedRows([]);
    setData(null);
  }

  return (
    <Modal
      visible={!!data}
      onCancel={onClose}
      footer={null}
      width={1000}
      closable={false}
      maskClosable={true}
      destroyOnClose={true}>
      <div>
        <Input.Search
          style={{marginBottom: 10}}
          placeholder="請輸入關鍵字"
          allowClear
          value={keyword}
          onChange={(e) => setKeyword(e.target.value)}
          onSearch={async () => {
            let {total, results} = await fetchRecords(keyword, columns, {
              offset: 0,
              limit: pagination.pageSize,
            });
            setRecords(results);
            setPagination({...pagination, total});
          }}
        />

        <Table
          style={{marginBottom: 10}}
          scroll={{x: 500, y: 300}}
          pagination={{
            ...pagination,
            onChange: async (page, pageSize) => {
              let {total, results} = await fetchRecords(keyword, columns, {
                offset: (page - 1) * pageSize,
                limit: pageSize,
              });
              setRecords(results);
              setPagination({...pagination, total});
            },
          }}
          rowKey={(record) => record.owner}
          rowSelection={{
            type: 'radio',
            onChange: (selectedRowKeys, selectedRows) => {
              _setSelectedRows(selectedRows);
            },
          }}
          columns={columns}
          dataSource={records}
        />

        <Button
          style={{width: '100%'}}
          onClick={() => {
            if (_selectedRows.length <= 0) {
              message.warning('請選擇會員。');
              return;
            }

            data.onChange(_selectedRows[0].owner);
            onClose();
          }}>
          確定
        </Button>
      </div>
    </Modal>
  );
}
