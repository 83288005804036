export function prependTwoZero(value) {
  return ('00' + value.toString()).slice(-2);
}

export function formatDateTimeStr(d = new Date()) {
  // '2023-02-23 11:45:02'
  return d.toLocaleString('sv');
}

export function formatYearMonthStr(d = new Date()) {
  // '2023-02'
  return d.toLocaleString('sv').slice(0, 7);
}

export function formatDateStr(d = new Date()) {
  // '2023-02-23'
  return d.toLocaleDateString('sv');
}

// '2024-01-01'
export const dtStrExp = /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/;
