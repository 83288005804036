import {css} from 'styled-components';
import styled from 'styled-components';
const Config = require('../../data.json');

export const ModalCustomStyle = styled.div`
  --adminBorderColor: #8e8e8e;
  --adminSelectColor: #eac100;

  input,
  .ant-input,
  .ant-input-number,
  .ant-input-number-group-addon,
  .ant-input-group-addon,
  .ant-input-affix-wrapper,
  .ant-input-number-affix-wrapper-has-feedback,
  .ant-select-selector,
  .ant-radio-inner,
  .ant-checkbox-inner,
  .ant-picker,
  .ant-input-search-button,
  .ant-upload {
    border-color: var(--adminBorderColor) !important;
    border-radius: 4px;

    &:hover,
    &:focus {
      border-color: var(--adminSelectColor) !important;
    }
  }

  .ant-select-arrow,
  .ant-input-group-addon {
    color: #4e4e4e !important;
  }

  .ant-btn-default {
    border-color: var(--adminBorderColor);

    &:hover {
      border-color: ${Config.themeColor};
    }
  }

  .ant-btn-dangerous {
    border-color: #ff4d4f;

    &[disabled] {
      border-color: var(--adminBorderColor);
    }
  }

  .ant-table-cell {
    border-bottom-color: var(--adminBorderColor);
    border-bottom-style: dashed;
  }

  .ant-input[disabled] {
    color: #4e4e4e;
  }

  .ant-select-disabled.ant-select: not(.ant-select-customize-input)
    .ant-select-selector {
    color: #4e4e4e;
  }

  .ant-checkbox-disabled + span {
    color: #4e4e4e;
  }

  .ant-picker-input > input[disabled] {
    color: #4e4e4e;
  }

  .ant-input-number-disabled {
    color: #4e4e4e;
  }

  .ant-form-item-control-input-content > div {
    & > .line {
      height: 1px;
      background-color: #343434;
    }

    & > .text {
      margin: 0 10px;
      font-weight: bold;
      font-size: 1rem;
    }
  }
`;

export default css`
  --topNavBarHeight: 100px;
  --topRwdNavBarHeight: 83px;
  --contentMaxWidth: 1440px;
  --containerMaxWidth: 1200px;
  --containerMaxWidth900px: 900px;
  --containerMaxWidth990px: 990px;
  --seoncdColor: ${Config.themeColorSecond};
  --thirdColor: ${Config.themeColorThird};
  --pageBackgroundColor: #fcfafc;
  font-family: 'Noto Serif TC', 'Noto Sans TC', sans-serif, serif;

  #rev-GlobalSpinnerBackdrop {
    background-color: transparent;
    backdrop-filter: none;
  }

  #rev-GlobalSpinner {
    display: none;
  }

  #rev-profile-layout {
    .ant-input[disabled] {
      color: #4e4e4e;
    }

    .ant-select-disabled.ant-select:not(.ant-select-customize-input)
      .ant-select-selector {
      color: #4e4e4e;
    }
  }

  #rev-admin-layout {
    --adminBorderColor: #8e8e8e;
    --adminSelectColor: #eac100;

    input,
    .ant-input,
    .ant-input-number,
    .ant-input-number-group-addon,
    .ant-input-group-addon,
    .ant-input-affix-wrapper,
    .ant-input-number-affix-wrapper-has-feedback,
    .ant-select-selector,
    .ant-radio-inner,
    .ant-checkbox-inner,
    .ant-picker,
    .ant-input-search-button,
    .ant-upload {
      border-color: var(--adminBorderColor) !important;
      border-radius: 4px;

      &:hover,
      &:focus {
        border-color: var(--adminSelectColor) !important;
      }
    }

    .ant-select-arrow,
    .ant-input-group-addon {
      color: #4e4e4e !important;
    }

    .ant-btn-default {
      border-color: var(--adminBorderColor);

      &:hover {
        border-color: ${Config.themeColor};
      }
    }

    .ant-btn-dangerous {
      border-color: #ff4d4f;

      &[disabled] {
        border-color: var(--adminBorderColor);
      }
    }

    .ant-table-cell {
      border-bottom-color: var(--adminBorderColor);
      border-bottom-style: dashed;
    }

    .ant-input[disabled] {
      color: #4e4e4e;
    }

    .ant-select-disabled.ant-select: not(.ant-select-customize-input)
      .ant-select-selector {
      color: #4e4e4e;
    }

    .ant-checkbox-disabled + span {
      color: #4e4e4e;
    }

    .ant-picker-input > input[disabled] {
      color: #4e4e4e;
    }

    .ant-input-number-disabled {
      color: #4e4e4e;
    }

    .ant-form-item-control-input-content > div {
      & > .line {
        height: 1px;
        background-color: #343434;
      }

      & > .text {
        margin: 0 10px;
        font-weight: bold;
        font-size: 1rem;
      }
    }
  }

  #rev-profile-layout {
    @media screen and (max-width: 768px) {
      padding-top: var(--topRwdNavBarHeight);
    }

    .ant-menu {
      width: 170px !important;
    }
  }

  & #rev-ProductListPage {
    margin: var(--topNavBarHeight) auto 0px;
    max-width: var(--contentMaxWidth);
    width: 100%;
    min-height: 100vh;
    padding: 40px 20px;

    display: flex;
    justify-content: center;

    & > .content {
      max-width: var(--contentMaxWidth);
      width: 100%;
      padding: 10px;
    }

    & > .content > div > div:nth-child(1) > .ant-menu {
      width: 200px !important;
      top: 100px !important;
    }

    & > div:nth-child(4) > div {
      padding-top: 120px;
    }

    .ant-breadcrumb {
      font-size: 1rem;
    }

    .rev-mobile-menu-btn {
      top: 100px;

      &::before {
        content: '課程/活動列表';
      }
    }
  }

  #rev-ProductDetailPage {
    margin: var(--topNavBarHeight) auto 0px;

    min-height: 100vh;
    padding: 40px 20px;

    & > .content {
    }

    .ant-breadcrumb {
      font-size: 1rem;
    }

    .session {
      font-weight: 600;
      font-size: 1rem;
    }

    .tabs {
      font-size: 0.95rem;
    }

    .product-quantity {
      display: none;
    }

    .share-link {
      display: none;
    }
  }

  #rev-CartPage {
    .window-container > .content > .cart-empty {
      display: none;
    }

    .product-variants .product-quantity {
      display: none;
    }
  }

  #rev-CartPage,
  #rev-CheckoutInfoPage,
  #rev-CheckoutReviewPage {
    .prev-step,
    .next-step > div {
      font-size: 16px;
    }

    .ant-select-selector,
    .ant-input[disabled] {
      color: #343434;
    }

    & > .container > .side > div > .detail-info > .price-table > .item > h4 {
      color: #343434;
      font-size: 0.95rem;
    }

    &
      > .container
      > .side
      > div
      > .detail-info
      > .price-table
      > .item
      > h2.label {
      color: #343434;
    }

    &
      > .container
      > .side
      > div
      > .detail-info
      > .price-table
      > .item
      > h2.value {
      color: var(--primaryColor);
      font-weight: 600;
    }
  }

  #rev-CheckoutReviewPage {
    #checkout-btn > div {
      font-size: 18px;
    }

    .product-variants .product-quantity {
      display: none;
    }

    & > .container > .side > div > .detail-info > div:nth-child(3) {
      display: none;
    }

    &
      > .container
      > .side
      > div
      > .detail-info
      > .discounts-info
      > div:nth-child(1) {
      display: none;
    }

    &
      > .container
      > .side
      > div
      > .detail-info
      > .discounts-info
      > div:nth-child(2) {
      margin-top: 20px;

      .point > label {
        display: none;
      }

      .point::before {
        content: '免費時數';
      }

      ::after {
        content: '※ 提醒您：免費時數折抵，若已結帳或自行取消，將不予歸還，敬請見諒。';
        color: var(--secondColor);
        font-size: 0.9rem;
        font-weight: 500;
      }
    }

    &
      > .container
      > .side
      > div
      > .detail-info
      > .discounts-info
      > div:nth-child(3) {
      display: none;
    }

    & > .container > .side {
      max-width: 300px;
      width: 100%;

      .point > label {
        display: none;
      }

      .point::before {
        content: '免費時數';
      }
    }

    &
      > .container
      > .side
      > div
      > .detail-info
      > .discounts-info
      > div:nth-child(2)
      > .ant-ribbon-wrapper
      > div
      > .ant-card-head
      > .ant-card-head-wrapper {
      padding-top: 30px;
    }
  }

  #rev-CheckoutInfoPage,
  #rev-CheckoutReviewPage {
    & > .container > .main > div > form > div:nth-child(1) {
      & > .content > div:nth-child(2) {
        display: none !important;
      }

      & > .content > div:nth-child(3) {
        display: none !important;
      }
    }

    & > .container > .main > div > form > div:nth-child(2) {
      display: none;
    }

    & > .container > .main > div > form > div:nth-child(3) {
      display: none;
    }
  }

  #rev-OrderPage {
    .resource-back-button {
      display: none;
    }

    .resource-back-button + h1 {
      margin-left: 0px !important;
    }

    .ant-table-cell {
      padding: 16px 10px;
    }

    .first-panel {
      .price-summary > .content > .fee {
        display: none;
      }
    }

    .order-info-panel {
      .content > div:nth-child(1) {
        display: none;
      }
    }

    .logistic-panel {
      display: none;
    }

    .invoice-panel {
      display: none;
    }

    .buyer-panel {
      display: none;
    }

    .receiver-panel {
      display: none;
    }
  }

  #rev-AdminOrderDetailForm {
    .action-buttons {
      .invoice-create-btn {
        display: none;
      }

      .logistics-create-sf-btn {
        display: none;
      }

      .logistics-create-btn {
        display: none;
      }

      .shipping-note-download-btn {
        display: none;
      }

      .order-pdf-download-btn {
        display: none;
      }
    }

    .logistics-info {
      display: none;
    }

    .invoice-info {
      display: none;
    }
  }

  .bulk-write-buttons {
    .multi-logistics-status-btn {
      display: none;
    }

    .multi-logistics-create-btn {
      display: none;
    }

    .multi-shipping-note-download-btn {
      display: none;
    }
  }

  .price-summary {
    .header > label {
      font-size: 1rem;
    }

    .content > .subtotal > label {
      display: none;
    }

    .content > .subtotal > p {
      text-align: right;
      width: 100%;
    }

    .content > .subtotal > p:before {
      content: '小計';
      padding-right: 10px;
      font-size: 0.95rem;
      font-weight: 500;
    }
  }

  /* $$__CODEGEN_EXTRA_STYLEDCSS__$$ */
`;
